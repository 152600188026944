import { AddressModel } from './../address-model';
import { ApiService } from './../api.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-selectaddress',
  templateUrl: './selectaddress.component.html',
  styleUrls: ['./selectaddress.component.css']
})
export class SelectaddressComponent implements OnInit {
  @ViewChild('modalData') modalData: TemplateRef<any>;
  @ViewChild('contactData') contactData: TemplateRef<any>;
  custaddress: any = [];
  customeravailable = false;
  addid: string;
  editcustaddress: any;
  addresstype1: boolean = true;
  addresstype2: boolean = false;
  addresstype3: boolean = false;
  objLocation : any=[];
  objState : any=[];
  registerForm = new FormGroup({ phonenumber: new FormControl('') });
  contactForm = new FormGroup({ customername: new FormControl(''), customeremailid: new FormControl('') });
  otpForm = new FormGroup({ first: new FormControl(''), second: new FormControl(''), third: new FormControl(''), fourth: new FormControl('') })
  addressForm = new FormGroup({ country:new FormControl(''),pincode:new FormControl(''), houseno:new FormControl(''),street:new FormControl(''),area:new FormControl(''),landmark:new FormControl(''),city:new FormControl(''),state:new FormControl('')})
  editaddressForm = new FormGroup({country:new FormControl(''), pincode:new FormControl(''), houseno:new FormControl(''),street:new FormControl(''),area:new FormControl(''),landmark:new FormControl(''),city:new FormControl(''),state:new FormControl('')})
  selectaddressForm: FormGroup = new FormGroup({ selectaddress: new FormControl('') });



  submitted = false;
  submitted1 = false;
  submitted2 = false;
  submitted3 = false;
  submitted4=false;
  loggedin = false;

  step1=false;
  step2=false;
  step3=false;
  step4=false;
  step5=false;

  cityNameAdd: any;
  stateNameAdd: any;
  cityNameEdit: any;
  stateNameEdit: any;

  addresstype = "Home";
  radioSelected: any;
  selectedaddress: any;

  private modalRef: NgbModalRef;
  private otpmodalRef: NgbModalRef;
  private contactmodalRef: NgbModalRef;
  private addressmodalRef: NgbModalRef;
  private editaddressmodalRef: NgbModalRef;


  unamePattern = "^[A-Za-z0-9_-\w ]{4,50}$";

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  constructor(private titleService:Title,private router: Router, private modalService: NgbModal, private apiService: ApiService, private formBuilder: FormBuilder) { 
    this.titleService.setTitle("Parryware Bano | Select Address");
  }

  ngOnInit(): void {
this.step1=JSON.parse(localStorage.getItem("step1"));
this.step2=JSON.parse(localStorage.getItem("step2"));
this.step3=JSON.parse(localStorage.getItem("step3"));

    if (window.sessionStorage.getItem("addselected") != null) {
      if (window.sessionStorage.getItem("addselected").length > 0) {
        this.selectedaddress = JSON.parse(window.sessionStorage.getItem("addselected").toString());
      }
    }
    this.custaddress = [];
    this.getAddressDetails();
    if (this.getCustomerName() !== "" && this.getCustomerName() !== null) {
      this.apiService.getAddressDetails().subscribe((data: any) => {
        console.log(data);
        this.custaddress = data.address;
        console.log(this.custaddress);
      })
      this.customeravailable = true;
    }
    this.registerForm = this.formBuilder.group({
      phonenumber: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(10), Validators.maxLength(10)]]
    });

    this.otpForm = this.formBuilder.group({
      first: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]],
      second: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]],
      third: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]],
      fourth: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]]
    });

    this.contactForm = this.formBuilder.group({
      customername: ['', [Validators.required,
      Validators.pattern(this.unamePattern)]],
      customeremailid: ['', [Validators.required,
      Validators.pattern(this.emailPattern)]]
    });

    this.addressForm = this.formBuilder.group({
      country:['', [Validators.required]],
      pincode: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(6), Validators.maxLength(6)]],
      houseno: ['', [Validators.required]],
      street: ['', [Validators.required]],
      area:[''],
      city: ['', [Validators.required]],
      landmark: [''],
      state:['', [Validators.required]]
    })

    this.editaddressForm = this.formBuilder.group({
      country:['', [Validators.required]],
      pincode: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(6), Validators.maxLength(6)]],
      houseno: ['', [Validators.required]],
      street: ['', [Validators.required]],
      area:[''],
      city: ['', [Validators.required]],
      landmark: [''],
      state:['', [Validators.required]]
    })
    if (this.selectedaddress != null && this.selectedaddress.toString() != "") {
      this.addid = this.selectedaddress.address_id.toString();
      this.selectaddressForm = this.formBuilder.group({ selectaddress: [this.addid, [Validators.required]] })
      console.log(this.getCustomerName());
    }

    this.apiService.getLocation().subscribe((data: any) => {
      console.log(data);
      console.log(data.Location);
      this.objLocation = data.Location;
      console.log(this.objLocation);
    })

    this.apiService.getState().subscribe((data: any) => {
      console.log(data);
      console.log(data.State);
      this.objState = data.State;
      console.log(this.objState);
    })
  }
  ngAfterViewInit() {
    if (!this.customeravailable)
      this.modalRef = this.modalService.open(this.modalData, { windowClass: 'parrymodal' });
  }
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  getotp(content: any) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.apiService.getOTP(this.registerForm.get('phonenumber').value).subscribe((data: any) => {
      var setsession = window.sessionStorage.setItem("otp", data.otp);
      console.log(data.otp);
      this.modalRef.close();
      this.otpmodalRef = this.modalService.open(content, { windowClass: 'parrymodal' });
    });


  }

  getCustomerName() {
    return (localStorage.getItem("CustomerName"));
  }



  get f() { return this.registerForm.controls; }

  get f1() { return this.otpForm.controls; }

  get f2() { return this.contactForm.controls; }

  get f3() { return this.addressForm.controls; }

  get f4() { return this.selectaddressForm.controls; }

  get f5() { return this.editaddressForm.controls; }

  validateOtp() {
this.submitted1=true;
    // stop here if form is invalid
    if (this.otpForm.invalid) {
      return;
    }

    var otpentered = this.otpForm.get('first').value.toString() + this.otpForm.get('second').value.toString() + this.otpForm.get('third').value.toString() + this.otpForm.get('fourth').value.toString();
    console.log(otpentered);
    var getsession = window.sessionStorage.getItem("otp");
   // if (otpentered === getsession) {

      this.apiService.validateOTP(this.registerForm.get('phonenumber').value, parseInt(getsession)).subscribe((data: any) => {
        console.log(data);
        localStorage.setItem("CustomerID", data.customer_id);
        localStorage.setItem("mobile", this.registerForm.get('phonenumber').value);
        if (data.cust_name != "" && data.cust_name != null) {
          localStorage.setItem("CustomerName", data.cust_name);
          localStorage.setItem("CustomerEmail", data.email);

          this.loggedin = true;
        }
        else {
          this.contactmodalRef = this.modalService.open(this.contactData, { windowClass: 'parrymodal' });
        }
        this.registerForm.setValue({ phonenumber: '' })
        this.submitted = true;
        this.otpmodalRef.close();
        this.getAddressDetails();
      })
    //}

  }

  storeContact() {
    this.submitted2 = true;
    if(this.contactForm.invalid)
    {return;}
    localStorage.setItem("CustomerName", this.contactForm.get('customername').value.toString());
    localStorage.setItem("CustomerEmail", this.contactForm.get('customeremailid').value.toString());
    this.customeravailable = true;
    this.contactmodalRef.close();
  }

  openaddressModal(content: any) {
    this.addressmodalRef = this.modalService.open(content, { windowClass: 'parrymodal addrpopup' });
  }

  submitAddress() {
    this.submitted3 = true;
    console.log(this.submitted3);
console.log(this.addressForm.invalid);

    //if(this.addressForm.invalid)
    //{
    //  return;
    //}

    let custid = parseInt(localStorage.getItem("CustomerID").toString());
    let addressmodel: AddressModel = {
      customer_id: custid,
      cust_name: localStorage.getItem("CustomerName"),
      housename_no: this.addressForm.get("houseno").  value.toString(),
      street_name: this.addressForm.get("street").value.toString(),
      area: this.addressForm.get("area").value.toString(),
      landmark: this.addressForm.get("landmark").value.toString(),
      city_id: this.cityNameAdd,
      state_id: this.stateNameAdd,
      pincode: this.addressForm.get("pincode").value.toString(),
      tel_no: localStorage.getItem("mobile"),
      email: localStorage.getItem("CustomerEmail"),
      address_type: this.addresstype.toLowerCase()
    }
    this.apiService.postAddressDetails(addressmodel).subscribe((data: any) => {
      console.log(data);
      this.addressmodalRef.close();
      this.getAddressDetails();
    })
  }

  onChangeCityAdd($event: any) {
    let text = $event.target.options[$event.target.options.selectedIndex].text;
    console.log(text);

    this.apiService.validCity(text).subscribe((data: any) => {
      console.log(data);
      console.log(data.message);
      this.cityNameAdd = data.message;
    console.log(this.cityNameAdd);
    })

    
  }

  onChangeCityEdit($event: any) {
    let text = $event.target.options[$event.target.options.selectedIndex].text;
    console.log(text);
    
    this.apiService.validCity(text).subscribe((data: any) => {
      console.log(data);
      console.log(data.message);
      this.cityNameEdit = data.message;
    console.log(this.cityNameEdit);
    })
  }

  onChangeStateAdd($event: any) {
    let text = $event.target.options[$event.target.options.selectedIndex].text;
    console.log(text);
    
    this.apiService.validState(text).subscribe((data: any) => {
      console.log(data);
      console.log(data.message);
      this.stateNameAdd = data.message;
    console.log(this.stateNameAdd);
    })
  }

  onChangeStateEdit($event: any) {
    let text = $event.target.options[$event.target.options.selectedIndex].text;
    console.log(text);
    
    this.apiService.validState(text).subscribe((data: any) => {
      console.log(data);
      console.log(data.message);
      this.stateNameEdit = data.message;
    console.log(this.stateNameEdit);
    })
  }

  getAddressDetails() {
    this.apiService.getAddressDetails().subscribe((data: any) => {
      console.log(data);
      this.custaddress = data.address;
    })
  }

  checkaddress(addid: number) {
    let addid1 = this.selectedaddress.address_id;
    console.log(addid1);
    console.log(addid);
    if (this.selectedaddress.address_id === addid)
      return true;
    else
      return false;
  }

  navigateTonext() {
    this.submitted3 = true;
    this.step3=true;
    localStorage.setItem("step3","true");
    let addressid = parseInt(this.selectaddressForm.get('selectaddress').value);
    console.log(addressid);
    let addselected = this.custaddress.findIndex((ob: any) => ob.address_id === addressid);
    console.log(addselected);
    window.sessionStorage.setItem("addselected", JSON.stringify(this.custaddress[addselected]));

    this.router.navigate(['/selectservicedate'])
  }

  logout() {
    window.sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(["home"])
  }

  editaddress(addid: number, content: any) {
    
    let index = this.custaddress.findIndex((item: { address_id: number; }) => item.address_id === addid);
    this.editcustaddress = this.custaddress[index];
    console.log(this.editcustaddress);
    this.addresstype1 = false;
    this.addresstype2 = false;
    this.addresstype3 = false;
   let addtype = this.editcustaddress.address_type;
    if (addtype === "home")
      this.addresstype1 = true;
    else if (addtype === "work")
      this.addresstype2 = true;
    else
      this.addresstype3 = true;
    this.editaddressForm.setValue({country:'India',street: this.editcustaddress.street_name, houseno: this.editcustaddress.housename_no, pincode: this.editcustaddress.pincode,landmark:this.editcustaddress.landmark,area:this.editcustaddress.area,city:'Chennai',state:'TamilNadu' })
    this.editaddressmodalRef = this.modalService.open(content, { windowClass: 'parrymodal addrpopup' });
  }

  updateAddress(addressid:number) {
    console.log(addressid);
    this.submitted4=true;
    
    //if(this.editaddressForm.invalid)
    //{
    //  return;
    //}

    let custid = parseInt(localStorage.getItem("CustomerID").toString());
    let addressmodel = {
      address_id : addressid,
      customer_id: custid,
      cust_name: localStorage.getItem("CustomerName"),
      housename_no: this.editaddressForm.get("houseno").value.toString(),
      street_name: this.editaddressForm.get("street").value.toString(),
      area: this.editaddressForm.get("area").value.toString(),
      landmark: this.editaddressForm.get("landmark").value.toString(),
      city_id: this.cityNameEdit,
      state_id: this.stateNameEdit,
      pincode: this.editaddressForm.get("pincode").value.toString(),
      tel_no: localStorage.getItem("mobile"),
      email: localStorage.getItem("CustomerEmail"),
      address_type: this.addresstype.toLowerCase()
    }
    console.log(addressmodel);
    this.apiService.postupdateAddressDetails(addressmodel).subscribe((data: any) => {
      console.log(data);
      this.editaddressmodalRef.close();
      this.getAddressDetails();
    })
  }

  deleteAddress(addressid:number) {
    let custid = parseInt(localStorage.getItem("CustomerID").toString());
    let addressmodel = {
      address_id : addressid,
      customer_id: custid
    }
    this.apiService.postdeleteAddressDetails(addressmodel).subscribe((data: any) => {
      console.log(data);
      this.editaddressmodalRef.close();
      this.getAddressDetails();
    })
  }
  gotopage(){
    window.scrollTo(0, 0);
  }
  storeaddresstype(addtype: string) {
    this.addresstype1 = false;
    this.addresstype2 = false;
    this.addresstype3 = false;
    this.addresstype = addtype;
    if (addtype === "home")
      this.addresstype1 = true;
    else if (addtype === "work")
      this.addresstype2 = true;
    else
      this.addresstype3 = true;
  }

  validatePincode(e :any)
  {
    console.log(e.target.value);
    this.apiService.validPin(e.target.value).subscribe((data: any) => {
      console.log(data);
     if(data.message.includes("not"))
     {
      alert(data.message)
      e.target.value='';
      e.target.focus();
      this.addressForm.setValue({pincode:''});
      this.editaddressForm.setValue({pincode:''});
     }
    })
    
  }
}
