import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-cancel-booking',
  templateUrl: './cancel-booking.component.html',
  styleUrls: ['./cancel-booking.component.css']
})
export class CancelBookingComponent implements OnInit {

  constructor(private titleService:Title,private router:Router) { 
    this.titleService.setTitle("Parryware Bano | Cancel Booking");
  }

  gotopage(){
    window.scrollTo(0, 0);
  }
  ngOnInit(): void {
    
  }
  getCustomerName() {
    return (localStorage.getItem("CustomerName"));
  }
  NavigateToServices()
  {
    this.router.navigate(["services"])
  }
  logout()
  {
    window.sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(["home"])
  }
}
