import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

  constructor(private titleService:Title,private _location: Location) {
    this.titleService.setTitle("Parryware Bano | About Us");
   }
  gotopage(){
    window.scrollTo(0, 0);
  }
  ngOnInit(): void {
  }

  NavigateBack()
  {
    this._location.back();
  }

}
