import { Product,AddOn } from './product';
export class productdetail{
    public product_id:number;
    public quantity:number;
    constructor(pdtid:number,qty:number)
    {
        this.product_id=pdtid;
        this.quantity=qty;
    }
}

export class addondetail{
    public addon_id:number;
    public quantity:number;
    constructor(addonid:number,qty:number)
    {
        this.addon_id=addonid;
        this.quantity=qty;
    }
}

export class OrderModel {
    public customer_id:number;
    public cust_name:string;
    public housename_no:string;
    public street_name:string;
    public area:string;
    public landmark:string;
    public city_id:number;
    public state_id:number;
    public pincode:string;
    public tel_no:string;
    public email:string;
    public appointment_date:string;
    public appointment_slot:string;
    public product_details:productdetail[];
    public addon_details:addondetail[];
    public product_type:ProductType;
    public supporting_document:string;
    public coupon:couponcodes;
    public in_warranty:boolean;
    public brand:string;
}

export class OrderStatusModel
{
    public customer_id:number;
    public cust_name:string;
    public housename_no:string;
    public street_name:string;
    public area:string;
    public landmark:string;
    public city_id:number;
    public state_id:number;
    public pincode:string;
    public tel_no:string;
    public email:string;
    public appointment_date:string;
    public appointment_slot:string;
    public product_details:any[];
    public addon_details:any[];
    public addon_quantity:number;
    public created_date:string;
    public customer_remarks:string;
    public order_id: number;
    public order_number: string;
    public order_status:string;
    public product_quantity:number;
    public reschedule_count: number;
    public sub_total: number;
    public tax: number;
    public total: number;
    public total_quantity: number;
    public discount_amount:number;
    public plumber_name:string;
    public plumber_id:number;
    public plumber_review:plumberreviewModel;
    public in_warranty:boolean;
    public CCExpensesAmt:number;
    public CCExpensesBillNo:string;
    public OthersBillAmt:number;
    public OthersBillNo:string;
    public SparesBillAmt:number;
    public SparesBillNo:string;
}

export class rescheduleModel{
    public order_id: number;
    public appointment_date:string;
    public appointment_slot:string;
}

export class cancelModel{
    public order_id: number;
    public customer_remarks:string;
}

export class plumberreviewModel
{
    public plumber_id:number;
    public order_id: number;
    public on_time_arrival:number;
    public plumber_behaviour:number;
    public service_rating:number;
    public need_improvement:string;
    public comments:string;
}

export class VASModel
{
    public Name:string;
    public EmailId: string;
    public MobileNumber:number;
    public Pincode:number;
    public Remarks:string;
    public VASType:string;
}

export class ProductType{
    date_of_purchase:string;
    parryware_product:boolean;
    multibrand_product:boolean;
}

export class couponcodes{
    coupon_code:string
}