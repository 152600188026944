import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-combothankyou',
  templateUrl: './combothankyou.component.html',
  styleUrls: ['./combothankyou.component.css']
})
export class CombothankyouComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  gotopage(){
    window.scrollTo(0, 0);
  }
}
