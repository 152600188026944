import { Component, OnInit }from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  location: Location;
  title = 'parrywareplusapp';
  ngOnInit() {
      if (location.protocol === 'http:' && location.href.indexOf('localhost')<0) {
        window.location.href = location.href.replace('http', 'https'); 
    }
}
}
