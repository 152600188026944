import { Injectable } from '@angular/core';
import { Product,AddOn } from './product';


@Injectable({
  providedIn: 'root'
})
export class CartService {
  items: Product[] = [];
  addonitems: AddOn[]=[];
  addToCart(product: Product) {
    this.items.push(product);
  }

  getItems() {
    return this.items;
  }

  clearCart() {
    this.items = [];
    this.addonitems=[];
  }

  

  getAddOnItems()
  {
    return this.addonitems;
  }

}
