import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {Router} from "@angular/router";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PageScrollService } from 'ngx-page-scroll-core';
import { NgxCaptchaModule } from 'ngx-captcha';
import {Title} from "@angular/platform-browser";
import { ApiService } from './../api.service';
import {Location} from '@angular/common';

export type customerDetail={customer_id:string,cust_name:string,email:string}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  @ViewChild('contactData') contactData : TemplateRef<any>;

loggedin:boolean=false;
private modalRef: NgbModalRef;
private otpmodalRef: NgbModalRef;
private contactmodalRef:NgbModalRef;
//siteKey="6LcIs7IbAAAAAOHWgEHxYfNd39ZEP2DFF9XAPmNj";
siteKey="6Lfb6YEhAAAAAKm0GvM1eQ1DHmZY9sk4GC_JXTEY";
secretkey="6LcIs7IbAAAAAJKJ9c_n80bmjOGAQPakAEuvxWtu";
type="image";

objcustomer:customerDetail;



registerForm = new FormGroup({ phonenumber: new FormControl('') });
  otpForm = new FormGroup({ first: new FormControl(''), second: new FormControl(''), third: new FormControl(''), fourth: new FormControl('') })
  contactForm = new FormGroup({ customername: new FormControl(''), customeremailid: new FormControl('')});
  contactUsForm=new FormGroup({contactName: new FormControl(''),contactmobilenumber:new FormControl(''),contactEmail:new FormControl(''),contactMessage:new FormControl(''),recaptcha:new FormControl('')})

  unamePattern = "^[A-Za-z0-9_-\w ]{4,50}$";
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  
  submitted = false;
  submitted1=false;
  submitted3=false;

  constructor(private titleService:Title,private pageScrollService: PageScrollService,private modalService: NgbModal, private apiService: ApiService, private formBuilder: FormBuilder,private router:Router,private _location:Location) {
    this.titleService.setTitle("Parryware Bano | Contact Us");
   }

  ngOnInit(): void {

    this.registerForm = this.formBuilder.group({
      phonenumber: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(10), Validators.maxLength(10)]]
    });

    this.otpForm = this.formBuilder.group({
      first: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]],
      second: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]],
      third: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]],
      fourth: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]]
    });

    this.contactForm = this.formBuilder.group({
      customername: ['', [Validators.required,
      Validators.pattern(this.unamePattern)]],
      customeremailid: ['', [Validators.required,
        Validators.pattern(this.emailPattern)]]
    });

    this.contactUsForm=this.formBuilder.group({
      contactName:['', [Validators.required,
        Validators.pattern(this.unamePattern)]],
      contactmobilenumber:['', [Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10), Validators.maxLength(10)]],
      contactEmail:['', [Validators.required,
        Validators.pattern(this.emailPattern)]],
      contactMessage:[''],
      recaptcha: ['', Validators.required]
    })
    this.checkloggedin();
  }

  triggerModal(content: any) {

    this.modalRef = this.modalService.open(content,{ windowClass: 'parrymodal ' });
  }

  NavigateBack()
  {
    this._location.back();
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  getCustomerName()
  {
      return(localStorage.getItem("CustomerName"));
  }

  checkloggedin()
  {
    if(window.localStorage.getItem("CustomerName")!=null)
    {
      if(window.localStorage.getItem("CustomerName")!="")
       this.loggedin=true
    }
  }

  logout()
  {
    window.sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(["home"])
  }

  get f() { return this.registerForm.controls; }

  get f1() { return this.otpForm.controls; }

  get f2() { return this.contactForm.controls; }

  get f3() { return this.contactUsForm.controls}


  getotp(content: any) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.apiService.getOTP(this.registerForm.get('phonenumber').value).subscribe((data: any) => {
      var setsession = window.sessionStorage.setItem("otp", data.otp);
      console.log(data.otp);
      this.modalRef.close();
      this.otpmodalRef = this.modalService.open(content,{ windowClass: 'parrymodal ' });
    });


  }

  validateOtp() {

    // stop here if form is invalid
    if (this.otpForm.invalid) {
      return;
    }

    var otpentered = this.otpForm.get('first').value.toString() + this.otpForm.get('second').value.toString() + this.otpForm.get('third').value.toString() + this.otpForm.get('fourth').value.toString();
    console.log(otpentered);
    var getsession = window.sessionStorage.getItem("otp");
    //if (otpentered === getsession) {

      this.apiService.validateOTP(this.registerForm.get('phonenumber').value, parseInt(getsession)).subscribe((data: any) => {
        console.log(data);
        localStorage.setItem("CustomerID",data.customer_id);
        localStorage.setItem("mobile",this.registerForm.get('phonenumber').value);
        if(data.cust_name!="" && data.cust_name!=null)
        {
          localStorage.setItem("CustomerName",data.cust_name);
          localStorage.setItem("CustomerEmail",data.email);      
          this.loggedin=true;
        }
        else
        {
          this.contactmodalRef = this.modalService.open(this.contactData,{ windowClass: 'parrymodal' });
        }
        this.registerForm.setValue({ phonenumber: '' })
        this.otpForm.setValue({first:'',second:'',third:'',fourth:''})
        this.submitted = true;
        this.checkloggedin();
        this.otpmodalRef.close();
      })
    //}
  }

  storeContact()
  {
    this.submitted1=true;
    localStorage.setItem("CustomerName",this.contactForm.get('customername').value.toString());
    localStorage.setItem("CustomerEmail",this.contactForm.get('customeremailid').value.toString());
    
    this.objcustomer={
      customer_id:localStorage.getItem("CustomerID"),
      cust_name:this.contactForm.get('customername').value.toString(),
      email:this.contactForm.get('customeremailid').value.toString()
    }
   
    this.apiService.postCustomerDetail(this.objcustomer).subscribe((data:any)=>{
      this.checkloggedin();
      this.contactmodalRef.close();
    })
  }
  gotopage(){
    window.scrollTo(0, 0);
  }
  storeMessage()
  {
    let contactMsg={
      name:this.contactUsForm.get("contactName").value,
      phone_number:this.contactUsForm.get("contactmobilenumber").value,
      email:this.contactUsForm.get("contactEmail").value,
      message:this.contactUsForm.get("contactMessage").value
    }
    this.apiService.postMessage(contactMsg).subscribe((data:any)=>{
      alert("Thank you for contacting Us.  Our Customer Service Executive will be contacting you soon...");
      this.contactUsForm.setValue({contactName:'',contactmobilenumber:'',contactEmail:'',contactMessage:'',recaptcha:''})
      this.contactUsForm.reset();
    })
  }
}
