import { ApiService } from './../api.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { OrderStatusModel, VASModel } from './../order-model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Product } from './../product';
import { CartService } from './../cart.service'
import {ActivatedRoute,Router} from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-renovation',
  templateUrl: './renovation.component.html',
  styleUrls: ['./renovation.component.css']
})
export class RenovationComponent implements OnInit {

  @ViewChild('contactData') contactData : TemplateRef<any>;
  
  cattabactive = 1;
  subcattabactive = 1;
  cartHasItems = false;
  cartHasNoItems = true
  vas: VASModel;
  loggedin=false;
  
  cartitems: any;
  totalitems = 0;
  totalprice = 0;
  submitted = false;
  submitted1=false;
  submitted2=false;

  step1=false;
  step2=false;
  step3=false;
  step4=false;
  step5=false;
    
  private modalRef: NgbModalRef;
  private otpmodalRef: NgbModalRef;
  private contactmodalRef:NgbModalRef;

  defaultimage="assets/images/dashboard/wm-repairs.png";

  unamePattern = "^[A-Za-z0-9_-\w ]{3,50}$";
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";

  renovationForm = new FormGroup({ Name: new FormControl(''),EmailId: new FormControl(''),
  MobileNumber: new FormControl(''),Pincode: new FormControl(''),Remarks: new FormControl('') });

  inwarrantyflag=false;
  outofwarrantyflag=true;

  constructor(private titleService:Title,private router:Router, private route: ActivatedRoute, private formBuilder: FormBuilder,
    private modalService: NgbModal, private apiService: ApiService, private cart: CartService) {
    this.titleService.setTitle("Parryware Bano | Services");
   }
   hiwslides = [
    { count: "01", maintext: "Talk to our experts over a call" },
    { count: "02", maintext: "Personalize the material" },
    { count: "03", maintext: "Finalize the design" },
    { count: "04", maintext: "Make Payment" },

  ];
  hiwslideConfig = { "slidesToShow": 1, "slidesToScroll": 1, "infinite": false, "dots": true, "arrows": false };
  ngOnInit(): void {
    this.renovationForm = this.formBuilder.group({
      name: ['', [Validators.required,
        Validators.pattern(this.unamePattern)],
        Validators.minLength(3)],

        emailid: ['', [Validators.required,
          Validators.pattern(this.emailPattern)]],

      mobilenumber: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(10), Validators.maxLength(10)]],

      pincode: ['', [Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(6), Validators.maxLength(6)]],

        remarks: ['', [Validators.pattern(this.unamePattern),
          Validators.minLength(3)]]

    });
    
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  get f() { return this.renovationForm.controls; }


  RenovationSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    this.vas={
      Name:this.renovationForm.get("name").value,
      EmailId:this.renovationForm.get("emailid").value,
      MobileNumber:this.renovationForm.get("mobilenumber").value,
      Pincode:this.renovationForm.get("pincode").value,
      Remarks:this.renovationForm.get("remarks").value,
      VASType:"Renovation"
    }

    console.log(this.vas);

    this.apiService.VASRenovation(this.vas).subscribe((data:any)=>{    
      console.log(data);
      console.log(data.status);

      if(data.status === "success")
      {
        this.router.navigate(["renovationthankyou"])
      }
      })


  }

  triggerModal(content: any) {
    this.modalRef = this.modalService.open(content,{ windowClass: 'parrymodal' });
  }
  openModal(content: any) {
    this.modalRef = this.modalService.open(content,{ windowClass: 'descModal' });
  }
  closeModal(content: any) {
    this.modalRef.close();
  }



  getCustomerName() {
    return (localStorage.getItem("CustomerName"));
  }
  gotopage(){
    window.scrollTo(0, 0);
  }

  logout()
  {
    window.sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(["home"])
  }

  
}
