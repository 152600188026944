import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent implements OnInit {

  constructor(private titleService:Title,private _location: Location) {
    this.titleService.setTitle("Parryware Bano | Privacy Policy");
   }

  ngOnInit(): void {
  }

  gotopage(){
    window.scrollTo(0, 0);
  }
  NavigateBack()
  {
    this._location.back();
  }

}
