import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from './../api.service';
import { Product,AddOn } from './../product';
import { CartService} from './../cart.service'
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {Title} from "@angular/platform-browser";

export type customerDetail={customer_id:string,cust_name:string,email:string}

@Component({
  selector: 'app-addon',
  templateUrl: './addon.component.html',
  styleUrls: ['./addon.component.css']
})
export class AddonComponent implements OnInit {
  @ViewChild('contactData') contactData : TemplateRef<any>;
  catids:string="";
  objaddon : any=[];
 loggedin=false;
 submitted = false;
 submitted1=false;
 submitted2=false;
   
 private modalRef: NgbModalRef;
 private otpmodalRef: NgbModalRef;
 private contactmodalRef:NgbModalRef;
 objcustomer:customerDetail;

 step1=false;
 step2=false;
 step3=false;
 step4=false;
 step5=false;

 unamePattern = "^[A-Za-z0-9_-\w ]{4,50}$";
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  registerForm = new FormGroup({ phonenumber: new FormControl('') });
  otpForm = new FormGroup({ first: new FormControl(''), second: new FormControl(''), third: new FormControl(''), fourth: new FormControl('') })
  contactForm = new FormGroup({ customername: new FormControl(''), customeremailid: new FormControl('')});

  constructor(private titleService:Title,private apiService: ApiService, private formBuilder: FormBuilder,private cart :CartService,private router:Router,private modalService: NgbModal) { 
    this.titleService.setTitle("Parryware Bano | Add Ons");
  }

  ngOnInit(): void {
    
    this.populateaddons();
    this.cart=JSON.parse(window.sessionStorage.getItem("cart"));
    this.checkloggedin();

    this.step1=JSON.parse(localStorage.getItem("step1"))
    this.step2=JSON.parse(localStorage.getItem("step2"))
    
    this.registerForm = this.formBuilder.group({
      phonenumber: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(10), Validators.maxLength(10)]]
    });

    this.otpForm = this.formBuilder.group({
      first: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]],
      second: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]],
      third: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]],
      fourth: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]]
    });

    this.contactForm = this.formBuilder.group({
      customername: ['', [Validators.required,
      Validators.pattern(this.unamePattern)]],
      customeremailid: ['', [Validators.required,
        Validators.pattern(this.emailPattern)]]
    });
    
  }

  triggerModal(content: any) {

    this.modalRef = this.modalService.open(content,{ windowClass: 'parrymodal ' });
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  storeContact()
  {
    this.submitted2=true;
    localStorage.setItem("CustomerName",this.contactForm.get('customername').value.toString());
    localStorage.setItem("CustomerEmail",this.contactForm.get('customeremailid').value.toString());
    
    this.objcustomer={
      customer_id:localStorage.getItem("CustomerID"),
      cust_name:this.contactForm.get('customername').value.toString(),
      email:this.contactForm.get('customeremailid').value.toString()
    }
   
    this.apiService.postCustomerDetail(this.objcustomer).subscribe((data:any)=>{
      this.checkloggedin();
      this.contactmodalRef.close();
    })
    
  }

  get f() { return this.registerForm.controls; }

  get f1() { return this.otpForm.controls; }

  get f2() { return this.contactForm.controls; }


  getotp(content: any) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.apiService.getOTP(this.registerForm.get('phonenumber').value).subscribe((data: any) => {
      var setsession = window.sessionStorage.setItem("otp", data.otp);
      console.log(data.otp);
      this.modalRef.close();
      this.otpmodalRef = this.modalService.open(content,{ windowClass: 'parrymodal ' });
    });


  }

  validateOtp() {
    this.submitted1=true;
    // stop here if form is invalid
    if (this.otpForm.invalid) {
      return;
    }

    var otpentered = this.otpForm.get('first').value.toString() + this.otpForm.get('second').value.toString() + this.otpForm.get('third').value.toString() + this.otpForm.get('fourth').value.toString();
    console.log(otpentered);
    var getsession = window.sessionStorage.getItem("otp");
    //if (otpentered === getsession) {

      this.apiService.validateOTP(this.registerForm.get('phonenumber').value, parseInt(getsession)).subscribe((data: any) => {
        console.log(data);
        localStorage.setItem("CustomerID",data.customer_id);
        localStorage.setItem("mobile",this.registerForm.get('phonenumber').value);
        if(data.cust_name!="" && data.cust_name!=null)
        {
          localStorage.setItem("CustomerName",data.cust_name);
          localStorage.setItem("CustomerEmail",data.email);      
          this.loggedin=true;
        }
        else
        {
          this.contactmodalRef = this.modalService.open(this.contactData,{ windowClass: 'parrymodal' });
        }
        this.registerForm.setValue({ phonenumber: '' })
        this.otpForm.setValue({first:'',second:'',third:'',fourth:''})
        this.submitted = true;
        this.checkloggedin();
        this.otpmodalRef.close();
      })
   // }

  }

  checkloggedin()
  {
    if(window.localStorage.getItem("CustomerName")!=null)
    {
      if(window.localStorage.getItem("CustomerName")!="")
       this.loggedin=true
    }
  }

  populateaddons()
  {
    this.cart=JSON.parse(window.sessionStorage.getItem("cart"));
    console.log(this.cart);
    this.cart.addonitems=[];
    for(var pdt of this.cart.items)
    {
      if(this.catids.indexOf(pdt.category_id.toString())===-1)
      {
        this.catids+=pdt.category_id.toString() + ",";
      }
    }
    this.catids=this.catids.slice(0,-1);
    console.log(this.catids);
    this.apiService.getAddons(this.catids).subscribe((data: any) => {
      console.log(data);
      this.objaddon = data.add_on;
      console.log(this.objaddon);
    });
  }

  addToCartAddOns(e:any,addonid:number,catid:number,costprice:number,addoncatname:string,addonname:string)
  {
    localStorage.setItem("step2","true");
   
    if(e.target.checked)
    {
      let objaddon1:AddOn = {
        addon_id:addonid,
        category_id:catid,
        addon_category:addoncatname,
        addon_name:addonname,
        price:costprice,
        quantity:1
      };
  
        this.cart.addonitems.push(objaddon1);
    }
    else{

      let index = this.cart.addonitems.findIndex(ob => ob.addon_id === addonid);
      if(index>=0)
      {
        this.cart.addonitems.splice(index,1);
      }

    }
    console.log(this.cart);
window.sessionStorage.setItem("cart",JSON.stringify(this.cart));
  }

  checkcart(addonid: number) {
    let index = this.cart.addonitems.findIndex(ob => ob.addon_id === addonid);
    if (index < 0)
      return false;
    else
      return true;
  }

  getCustomerName() {
    return (localStorage.getItem("CustomerName"));
  }

  gotopage(){
    window.scrollTo(0, 0);
  }
  logout()
  {
    window.sessionStorage.clear();
    localStorage.clear();
    this.loggedin=false;
    this.router.navigate(["home"])
   
  }
}
