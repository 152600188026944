import { ApiService } from './../api.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Product } from './../product';
import { CartService } from './../cart.service'
import {ActivatedRoute,Router} from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {Meta, Title} from "@angular/platform-browser";

export type catpdttype = Array<{ product_id: number, category_id: number, category: string, name: string, image: string, price: number,best_selling: boolean,highlight:boolean }>;
export type customerDetail={customer_id:string,cust_name:string,email:string}

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})


export class ServicesComponent implements OnInit {

  @ViewChild('contactData') contactData : TemplateRef<any>;

  cattabactive = 1;
  subcattabactive = 1;
  cartHasItems = false;
  cartHasNoItems = true
  catallpdts:catpdttype=[];
  cat1pdts: catpdttype = [];
  cat2pdts: catpdttype = [];
  cat3pdts: catpdttype = [];
  cat4pdts: catpdttype = [];
  cat5pdts: catpdttype = [];
  cat6pdts: catpdttype = [];
  cat7pdts: catpdttype = [];
  cat8pdts: catpdttype = [];
  cat9pdts: catpdttype = [];
  cat10pdts:catpdttype=[];
  cat11pdts:catpdttype=[];
  loggedin=false;
  
  cartitems: any;
  totalitems = 0;
  totalprice = 0;
  submitted = false;
  submitted1=false;
  submitted2=false;

  step1=false;
  step2=false;
  step3=false;
  step4=false;
  step5=false;
    
  private modalRef: NgbModalRef;
  private otpmodalRef: NgbModalRef;
  private contactmodalRef:NgbModalRef;
  objcustomer:customerDetail;

  defaultimage="assets/images/dashboard/wm-repairs.png";

  unamePattern = "^[A-Za-z0-9_-\w ]{4,50}$";
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  registerForm = new FormGroup({ phonenumber: new FormControl('') });
  otpForm = new FormGroup({ first: new FormControl(''), second: new FormControl(''), third: new FormControl(''), fourth: new FormControl('') })
  contactForm = new FormGroup({ customername: new FormControl(''), customeremailid: new FormControl('')});
  inwarrantyflag=false;
  outofwarrantyflag=true;

  constructor(private titleService:Title,private meta: Meta,private router:Router, private route: ActivatedRoute, private formBuilder: FormBuilder,private modalService: NgbModal, private apiService: ApiService, private cart: CartService) {
    this.titleService.setTitle("Parryware Bano | Services");
   }
 
  ngOnInit(): void {
    console.log(window.sessionStorage.getItem("warrantytype"));
    if(window.sessionStorage.getItem("warrantytype")===undefined || window.sessionStorage.getItem("warrantytype")===null)
    {
    this.route.queryParams.subscribe(params => {
      if(params.type==='inwarranty')
      {
        this.inwarrantyflag=true;
      }
    })
  }
  else
  {
    if(window.sessionStorage.getItem("warrantytype")==="IN")
    this.inwarrantyflag=true;
  }
    
    if(localStorage.getItem("step1")===undefined)
      localStorage.setItem("step1","false");
      else
      this.step1=JSON.parse((localStorage.getItem("step1")));
      if(localStorage.getItem("step2")===undefined)
    localStorage.setItem("step2","false");
    else
    this.step2=JSON.parse((localStorage.getItem("step2")));
    if(localStorage.getItem("step3")===undefined)
    localStorage.setItem("step3","false");
    else
    this.step3=JSON.parse((localStorage.getItem("step3")));
    if(localStorage.getItem("step4")===undefined)
    localStorage.setItem("step4","false");
    else
    this.step4=JSON.parse((localStorage.getItem("step4")));
    if(localStorage.getItem("step5")===undefined)
    localStorage.setItem("step5","false");
    else
    this.step5=JSON.parse((localStorage.getItem("step5")));

    this.checkloggedin();

    this.registerForm = this.formBuilder.group({
      phonenumber: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(10), Validators.maxLength(10)]]
    });

    this.otpForm = this.formBuilder.group({
      first: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]],
      second: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]],
      third: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]],
      fourth: ['', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(1)]]
    });

    this.contactForm = this.formBuilder.group({
      customername: ['', [Validators.required,
      Validators.pattern(this.unamePattern)]],
      customeremailid: ['', [Validators.required,
        Validators.pattern(this.emailPattern)]]
    });

    this.apiService.getProducts(0).subscribe((data: any) => {
      this.catallpdts = data.product;
  
    this.cat1pdts=this.catallpdts.filter(item => item.category_id==1)
    this.cat2pdts=this.catallpdts.filter(item => item.category_id==2)
    this.cat3pdts=this.catallpdts.filter(item => item.category_id==3)
    this.cat4pdts=this.catallpdts.filter(item => item.category_id==4)
    this.cat5pdts=this.catallpdts.filter(item => item.category_id==5)
    this.cat6pdts=this.catallpdts.filter(item => item.category_id==6)
    this.cat7pdts=this.catallpdts.filter(item => item.category_id==7)
    this.cat8pdts=this.catallpdts.filter(item => item.category_id==8)
    this.cat9pdts=this.catallpdts.filter(item => item.category_id==9)
    this.cat10pdts=this.catallpdts.filter(item => item.category_id==11)
    this.cat11pdts=this.catallpdts.filter(item => item.category_id==12)
    });
    
 console.log(window.sessionStorage.getItem("cart"));

    if(window.sessionStorage.getItem("cart")!=null)
    {
    this.cart=JSON.parse(window.sessionStorage.getItem("cart"));
    console.log(this.cart);
    if(this.cart.items.length>0)
    {
      this.checkCartForProducts();
     
      for(var item of this.cart.items)
      {
        this.totalitems+=item.quantity;
        this.totalprice+=(item.price* item.quantity)
      }
    }
  }
  else
  {
    this.cart.items=[];
    this.cart.addonitems=[];
    this.cartHasItems=false;
    this.cartHasNoItems=true;
  }
    
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  get f() { return this.registerForm.controls; }

  get f1() { return this.otpForm.controls; }

  get f2() { return this.contactForm.controls; }


  getotp(content: any) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.apiService.getOTP(this.registerForm.get('phonenumber').value).subscribe((data: any) => {
      var setsession = window.sessionStorage.setItem("otp", data.otp);
      console.log(data.otp);
      this.modalRef.close();
      this.otpmodalRef = this.modalService.open(content,{ windowClass: 'parrymodal ' });
    });


  }

  validateOtp() {
    this.submitted1=true;
    // stop here if form is invalid
    if (this.otpForm.invalid) {
      return;
    }

    var otpentered = this.otpForm.get('first').value.toString() + this.otpForm.get('second').value.toString() + this.otpForm.get('third').value.toString() + this.otpForm.get('fourth').value.toString();
    console.log(otpentered);
    var getsession = window.sessionStorage.getItem("otp");
    //if (otpentered === getsession) {

      this.apiService.validateOTP(this.registerForm.get('phonenumber').value, parseInt(getsession)).subscribe((data: any) => {
        console.log(data);
        localStorage.setItem("CustomerID",data.customer_id);
        localStorage.setItem("mobile",this.registerForm.get('phonenumber').value);
        if(data.cust_name!="" && data.cust_name!=null)
        {
          localStorage.setItem("CustomerName",data.cust_name);
          localStorage.setItem("CustomerEmail",data.email);      
          this.loggedin=true;
        }
        else
        {
          this.contactmodalRef = this.modalService.open(this.contactData,{ windowClass: 'parrymodal' });
        }
        this.registerForm.setValue({ phonenumber: '' })
        this.otpForm.setValue({first:'',second:'',third:'',fourth:''})
        this.submitted = true;
        this.checkloggedin();
        this.otpmodalRef.close();
      })
   // }

  }
  
  storeContact()
  {
    this.submitted2=true;
    localStorage.setItem("CustomerName",this.contactForm.get('customername').value.toString());
    localStorage.setItem("CustomerEmail",this.contactForm.get('customeremailid').value.toString());
    
    this.objcustomer={
      customer_id:localStorage.getItem("CustomerID"),
      cust_name:this.contactForm.get('customername').value.toString(),
      email:this.contactForm.get('customeremailid').value.toString()
    }
   
    this.apiService.postCustomerDetail(this.objcustomer).subscribe((data:any)=>{
      this.checkloggedin();
      this.contactmodalRef.close();
    })
    
  }

  checkloggedin()
  {
    if(window.localStorage.getItem("CustomerName")!=null)
    {
      if(window.localStorage.getItem("CustomerName")!="")
       this.loggedin=true
    }
  }

  checkCartForProducts() {
    if (this.cart.items.length >0 ) {
      this.cartHasNoItems = false;
      this.cartHasItems = true;
    }
    else {
      this.cartHasNoItems = true;
      this.cartHasItems = false;
    }
  }

  triggerModal(content: any) {
    this.modalRef = this.modalService.open(content,{ windowClass: 'parrymodal' });
  }
  openModal(content: any) {
    this.modalRef = this.modalService.open(content,{ windowClass: 'descModal' });
  }
  closeModal(content: any) {
    this.modalRef.close();
  }

  addItemsToCart(pdtid: number, catid: number, price1: number,pdtname:string,pdtcatname:string) {
    localStorage.setItem("step1","true");
    let type="outofwarranty";
    if(this.inwarrantyflag)
      type="inwarranty";
    let objpdt: Product = {
      product_id: pdtid,
      product_name:pdtname,
      category_name:pdtcatname,
      category_id: catid,
      price: price1, quantity: 1,type:type
    }
    this.totalitems += 1;
    this.totalprice += price1;

    let index = this.cart.items.findIndex(ob => ob.product_id === pdtid && ob.type===type);
    console.log(index);
    if (index < 0) {
      this.cart.items.push(objpdt);;
    }
    else
      this.cart.items[index].quantity += 1;
    console.log(this.cart.items);
    this.cartHasNoItems = false;
    this.cartHasItems = true;
    window.sessionStorage.setItem("cart", JSON.stringify(this.cart));
    if(this.inwarrantyflag)
    window.sessionStorage.setItem("warrantytype","IN");
    else
    window.sessionStorage.setItem("warrantytype","OUT");
  }

  removeItemsFromCart(pdtid: number) {
    let type="outofwarranty";
    if(this.inwarrantyflag)
      type="inwarranty";
    let index = this.cart.items.findIndex(ob => ob.product_id === pdtid && ob.type===type);
    if (index >= 0) {
      let qty = this.cart.items[index].quantity;
      let price = this.cart.items[index].price;
      if (qty > 1) {
        this.cart.items[index].quantity -= 1;
      }
      else {
        this.cart.items.splice(index, 1);
      }
      this.totalitems -= 1;
      this.totalprice -= price;
      if (this.totalitems === 0) {
        this.cartHasItems = false;
        window.sessionStorage.setItem("cart", JSON.stringify(this.cart));
      }
      else {
        window.sessionStorage.setItem("cart", JSON.stringify(this.cart));
      }
    }
  }

  checkcart(prdid: number) {
    let type="outofwarranty";
    if(this.inwarrantyflag)
      type="inwarranty";
    let index = this.cart.items.findIndex(ob => ob.product_id === prdid && ob.type===type);
    if (index < 0)
      return true;
    else
      return false;
  }
  getItemQuantity(prdid: number) {
    let type="outofwarranty";
    if(this.inwarrantyflag)
      type="inwarranty";
    let index = this.cart.items.findIndex(ob => ob.product_id === prdid && ob.type===type);
    console.log(this.cart.items);
    console.log(index);
    if (index < 0) {
    return 0;
    }
    else
      return this.cart.items[index].quantity;
  }

  getCustomerName() {
    return (localStorage.getItem("CustomerName"));
  }
  gotopage(){
    window.scrollTo(0, 0);
  }

  logout()
  {
    window.sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(["home"])
  }

}
