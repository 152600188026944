import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-failurepayment',
  templateUrl: './failurepayment.component.html',
  styleUrls: ['./failurepayment.component.css']
})
export class FailurepaymentComponent implements OnInit {
    [x: string]: any;
    @ViewChild('paymentForm') payform: any;

    orderid: string = "";
    trackingid: string = "";
    refnumber: string = "";
    amount: string = "";

    constructor(private titleService:Title,private route: ActivatedRoute) { 
        this.titleService.setTitle("Parryware Bano | Payment Failure");
      }

      ngOnInit(): void {
        console.log("Success");
        console.log(history.state);
        console.log(history.state.order_id);

        this.orderid = history.state.order_id;
        this.trackingid = history.state.tracking_id;
        this.refnumber = history.state.bank_ref_no;
        this.amount = history.state.amount +' '+history.state.currency;
    }
    
    getCustomerName() {
        return (localStorage.getItem("CustomerName"));
      }
    
      logout() {
        window.sessionStorage.clear();
        localStorage.clear();
        this.router.navigate(["home"])
      }

}
