<div class="DashboardInner" data-ng-init="checkloggedin()">
    <div class="container">
       <div class="innercontainer">
     <!-------------------------------Header------------------>
     <header class="dashboardHeader">
       <div class="row dsRow no-gutters">
          <div class="col-lg-4 col-sm-4 dsgrid dsleftpanel">
             <p class="paraxl fontrobotoblack clrwhite d-flex">
                <a routerLink="/services" routerLinkActive="active"><img src="assets/images/dashboard/back.png" class="img-fluid"/>
                <span class="d-none d-lg-block d-sm-block">Book Your Service</span></a>
                <span class="d-block d-lg-none d-sm-none">Select Add-on</span>
             </p>
          </div>
          <div class="col-lg-8 col-sm-8  dsgrid dsrightpanel">
          <div class="dsheaderlist">
            <div class="mlist dropdown">
               <div class="dropdown-toggle" ngbDropdown #menu="ngbDropdown">
                    <p *ngIf="!loggedin">Login/Sign up</p>
                   <a (click)="triggerModal(modalData)" *ngIf="!loggedin" class="loginClass">
                  
                  
                       <img src="assets/images/home/login.svg" class="img-fluid" />
                       <span>My Activity</span>
                   </a>
                   <button class="btn btn-outline-primary mr-2" id="dropdownManual" ngbDropdownAnchor (focus)="menu.open()" *ngIf="loggedin"> 
                       <p *ngIf="loggedin">Welcome {{getCustomerName()}}</p>
                       <img src="assets/images/home/Bano_logo_Final.svg" class="img-fluid" />
                       
                       <span>My Activity</span></button>
<div ngbDropdownMenu aria-labelledby="dropdownManual">
<button ngbDropdownItem><span><img src="assets/images/home/drp-icon-2.png"
   class="img-fluid" /></span><a routerLink="/mydashboard" routerLinkActive="active">My Booking</a></button>
<button ngbDropdownItem (click)="logout()"><span><img src="assets/images/home/drp-icon-3.png"
   class="img-fluid" /></span>Logout</button>

</div>
               </div>

               <div  class="dropdown-menu" >
                   <a class="dropdown-item" href="#"></a>
                   <a class="dropdown-item" href="#"></a>
                   </div>
           </div>
            <div class="dslogo">
             <a class="nav-brand" routerLink="/home" routerLinkActive="active">
               <img src="assets/images/home/Bano_logo_white.svg" class="img-fluid" />
             </a>
         </div>
         </div>
          </div>
       </div>
     </header>
     <!---------------------------------steps-------------------->
     <section class="dsSteps">
      <div class="Stlist">
         <ul class="nav">
             <li class="st-active"><a routerLink="/services" routerLinkActive="active" [ngClass]="{'adisabled':!step1}">01</a>
                 <span>Select Services</span>
             </li>
             <div class="stline"></div>
             <li  class="st-active"><a routerLink="/addon" routerLinkActive="active" [ngClass]="{'adisabled':!step2}">02</a>
                 <span>Select Add-on</span>
             </li>
             <div class="stline"></div>
             <li><a routerLink="/selectaddress" routerLinkActive="active" [ngClass]="{'adisabled':!step3}">03</a>
                 <span>Address Information</span>
             </li>
             <div class="stline"></div>
             <li><a routerLink="/selectservicedate" routerLinkActive="active" [ngClass]="{'adisabled':!step4}">04</a>
                 <span>Select Date / Time</span>
             </li>
             <div class="stline"></div>
             <li><a routerLink="/confirmbooking" routerLinkActive="active" [ngClass]="{'adisabled':!step5}">05</a>
                 <span>Confirmation</span>
             </li>
         </ul>
     </div>
        <!------------------Inner section--------->
        <div class="dsInnerpanel">
        <div class="dsbx">
           <div class="dsInnerTitle">
              <a routerLink="/services" routerLinkActive="active"> < Back</a>
              <p class="paraxl fontrobotobold clrblack mb30">Add-On 
                 <span class="text-center">(Only Service Charges included and Product Cost is Extra)</span>
              </p>
           </div>
           <div class="add_list_panel addonPanel">
           <div class="addonlist" *ngFor="let obj of objaddon">
              
               <label >
              <div class="row no-gutters add_li ">
                <div class="col-lg-8 col-sm-8 addgrid  d-flex align-items-center">
                   <div class="addonpattern"> <img src="{{obj.image}}"
                     class="img-fluid" /></div>
                   <p>{{obj.name}}</p>
                </div>
                <div class="col-lg-4 col-sm-4 addgrid  d-flex align-items-center justify-content-end">
                 <span>&#8377; {{obj.price}}</span>
                 <input type="checkbox" class="parry_checkbox" (change)="addToCartAddOns($event,obj.addon_id,obj.category_id,obj.price,obj.category,obj.name)" [checked]="checkcart(obj.addon_id)"/>
                </div>
              </div>
              </label>
           </div>
         
          </div>
          <div class="gonextpanel">
            <a (click)="gotopage()" routerLink="/selectaddress" routerLinkActive="active"  class="gonextbtn btn booknow popbtn fullwidth">Next 
               <img src="assets/images/dashboard/continue.png" class="img-fluid"/>
            </a>
         </div>
        </div>
       </div>
     </section>
    </div>
     </div>
    </div>
    <!-------------------------------Footer Panel------------------>
    <section class="Footerpanel">
       <div class="footerTop">
          <div class="container">
             <div class="fTopInner text-center">
                <a routerLink="/home" routerLinkActive="active"><img src="assets/images/home/logo.png" class="img-fluid"/></a>
                <p class="para">
                   Parryware India is a leading manufacturer of bathroom products originated from EID Parry, one of the country's oldest and most reputed business entities. In 2006, it entered into a joint venture with Roca Corporation, Spain, to become a Roca Group subsidiary in 2008, and turned into a wholly-owned subsidiary of the Roca Group in 2011.
                </p>
             </div>
          </div>
       </div>
       <div class="footerBtm bggrey">
          <div class="container">
             <div class="row fbtmRow no-gutters">
                <div class="col-lg-4  fbtcopyrights">
                   <p class="parasmall">© 2021 Parryware | All Rights Reserved</p>
                </div>
                <div class="col-lg-8  fbtmenus">
                  <ul class="nav menu_footer">
                      <li><a routerLink="/aboutus" routerLinkActive="active" (click)="gotopage()">About us</a></li>
                      <li class="line"></li>
                      <li><a routerLink="/contact" routerLinkActive="active" (click)="gotopage()">Contact us</a></li>
                      <li class="line"></li>
                      <li><a pageScroll routerLink="/home" href="#faq" (click)="gotopage()">FAQ</a></li>
                      <li class="line"></li>
                      <li><a routerLink="/warrantypolicy" routerLinkActive="active"  (click)="gotopage()">Warranty Policy</a></li>
                      <li class="line"></li>
                      <li><a routerLink="/privacypolicy" routerLinkActive="active" (click)="gotopage()">Privacy Policy</a></li>
                      <li class="line"></li>
                      <li><a routerLink="/terms" routerLinkActive="active" (click)="gotopage()">Terms of Use</a></li>
                  </ul>
              </div>
             </div>
          </div>
       </div>
    </section>

 <!-----------------------------Description Popup------------------------>
 <!-- Modal -->
 <div class="modal fade descModal" id="descriptionModal" tabindex="-1" aria-labelledby="descriptionModalLabel" aria-hidden="true">
 <div class="modal-dialog">
    <div class="modal-content">
       <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
             <span aria-hidden="true">&times;</span>
          </button>
       </div>
       <div class="modal-body">
          <div class="mdTitle">
                <div class="prdesc">
                   <span>Water Closet (Floor Mount)</span>
                   <p class="para fontrobotomedium">
                      Repairs &amp; Leakages
                   </p>
                </div>
                <div class="primg">
                   <img src="assets/images/dashboard/Repairs.png" class="img-fluid">
                   <div class="bestseller">Best Seller</div>

                </div>
          </div>
          <div class="mdlist">
             <p class="parasmall fontrobotomedium">
                Inclusions and Exclusions
             </p>
             <ul class="mdcontxt">
                <li>Repair & refixing of the defective unit</li>
                <li>Defective material to be given to customer
                   (for Out of warranty customers)
                </li>
                <li>
                   Explanation of Parts used & invoice
                </li>
                <li>
                   Line blockage (related to building) is beyond the scope of repair
                </li>
                <li>
                   Additional masanory / civil / electrical work is not included
                </li>
                <li>
                   Products, Spare Parts & other consumbales would be charged extra
                </li>
                <li>
                   Multiple visits may be required for installation
                </li>
                <li>
                   Alternation of Products is not included
                </li>
                <li>
                   In case of Parryware products, if the product is dropped / obsolete, equivalent product may be
                   recommended
                </li>
                <li>
                   The estimate is given  based on premlimary inspection. The actual amount could be higher based on actual
                   work involved
                </li>
             </ul>
          </div>
       </div>
    </div>
 </div>
 </div>
<!-----------------------------Login Popup------------------------>
<ng-template #modalData let-modal>
   <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="popupInner">
         <h2 class="popuptitle clrblack fontrobotobold mb30">Login</h2>
         <img src="assets/images/home/Login-popup.svg" class="mb30 img-fluid" />
         <form [formGroup]="registerForm" (ngSubmit)="getotp(modalOtp)">
            <p class="popupsubtitle clrblack fontrobotobold mb30">Enter your mobile number</p>
            <div class="loginpanel">
               <input class="txtbx pop_txtbx mb30" (keypress)="keyPress($event)" required type="text"
                  placeholder="Enter your mobile number" formControlName="phonenumber"
                  [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }">
               <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                  <div *ngIf="f.phonenumber.errors.required">Phone number is required</div>
                  <div
                     *ngIf="f.phonenumber.errors.pattern || f.phonenumber.errors.maxlength || f.phonenumber.errors.minlength">
                     Phone number must be at least 10 numbers</div>
               </div>
               <input type="submit" class="btn booknow popbtn fullwidth" text="Continue" value="Continue" />
            </div>
         </form>
      </div>
   </div>
</ng-template>
<!--------------------------OTP Screen------------------->
<ng-template #modalOtp let-modal>
   <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="popupInner">
         <h2 class="popuptitle clrblack fontrobotobold mb50">Enter verification code</h2>
         <form [formGroup]="otpForm" (ngSubmit)="validateOtp()">
            <div class="otppanel">
               <p class="parasmall clrblack mb30">We have sent a 4 digit OTP to your mobile number</p>
               <div class="otpformgroup mb30">
                  <input id="otp1" type="text" class="txtbx pop_txtbx otptxt" appMoveNextByMaxLength required (keypress)="keyPress($event)" minlength="1" maxlength="1" 
                  formControlName="first" [ngClass]="{ 'is-invalid': submitted1 && f1.first.errors }" />
              <input id="otp2" type="text" class="txtbx pop_txtbx otptxt" appMoveNextByMaxLength (keypress)="keyPress($event)" required minlength="1" maxlength="1" 
                  formControlName="second" [ngClass]="{ 'is-invalid': submitted1 && f1.second.errors }" />
              <input id="opt3" type="text" class="txtbx pop_txtbx otptxt" appMoveNextByMaxLength (keypress)="keyPress($event)" required minlength="1" maxlength="1" 
                  formControlName="third" [ngClass]="{ 'is-invalid': submitted1 && f1.third.errors }" />
              <input id="otp4"  type="text" class="txtbx pop_txtbx otptxt"  (keypress)="keyPress($event)" required minlength="1" maxlength="1" 
                  formControlName="fourth" [ngClass]="{ 'is-invalid': submitted1 && f1.fourth.errors }" />
              
               <div *ngIf="submitted1 && f1.first.errors" class="invalid-feedback">
                  <div *ngIf="f1.first.errors.required">
                     Otp is required</div>
                  <div *ngIf="f1.first.errors.pattern || f1.first.errors.maxlength || f1.first.errors.minlength">
                     Otp is not valid</div>
                  <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->

               </div>
               <div *ngIf="submitted1 && f1.second.errors" class="invalid-feedback">
                  <div *ngIf="f1.second.errors.required">
                     Otp is required</div>
                  <div *ngIf="f1.second.errors.pattern || f1.second.errors.maxlength || f1.second.errors.minlength">
                     Otp is not valid</div>
                  <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->

               </div>
               <div *ngIf="submitted1 && f1.third.errors" class="invalid-feedback">
                  <div *ngIf="f1.third.errors.required">
                     Otp is required</div>
                  <div *ngIf="f1.third.errors.pattern || f1.third.errors.maxlength || f1.third.errors.minlength">
                     Otp is not valid</div>
                  <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->

               </div>
               <div *ngIf="submitted1 && f1.fourth.errors" class="invalid-feedback">
                  <div *ngIf="f1.fourth.errors.required">
                     Otp is required</div>
                  <div *ngIf="f1.fourth.errors.pattern || f1.fourth.errors.maxlength || f1.fourth.errors.minlength">
                     Otp is not valid</div>
                  <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->

               </div>
            </div>
               <input type="submit" class="btn booknow popbtn fullwidth" text="Continue" value="Continue" />
            </div>
         </form>
      </div>
   </div>
</ng-template>


<!--------------------------Name and Email ID------------------->
<ng-template #contactData let-modal>
   <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="popupInner">
         <h2 class="popuptitle clrblack fontrobotobold mb30">Login</h2>
         <img src="assets/images/home/Login-popup.svg" class="mb30 img-fluid" />
         <form [formGroup]="contactForm" (ngSubmit)="storeContact()">
            <p class="popupsubtitle clrblack fontrobotobold mb30">Enter your Name</p>
            <div class="loginpanel">
                <div class="form-group mb30">
               <input class="txtbx pop_txtbx " required type="text" placeholder="Enter Your Name"
                  formControlName="customername" [ngClass]="{ 'is-invalid': submitted1 && f2.customername.errors }">
               <div *ngIf="submitted2 && f2.customername.errors" class="invalid-feedback">
                  <div *ngIf="f2.customername.errors.required">Customer Name is required</div>
                  <div *ngIf="f2.customername.errors.pattern ">
                     Enter valid name</div>
               </div>
                </div>
                
                <div class="form-group mb30">
               <input class="txtbx pop_txtbx" required type="text" placeholder="Enter Your Email"
                  formControlName="customeremailid"
                  [ngClass]="{ 'is-invalid': submitted1 && f2.customeremailid.errors }">
               <div *ngIf="submitted2 && f2.customeremailid.errors" class="invalid-feedback">
                  <div *ngIf="f2.customeremailid.errors.required">Customer Email ID is required</div>
                  <div *ngIf="f2.customeremailid.errors.pattern ">
                     Enter valid mail id</div>
               </div>
                </div>
               <input type="submit" class="btn booknow popbtn fullwidth" text="Continue" value="Continue" />
            </div>
         </form>
      </div>
   </div>
</ng-template>
<!-----------------------------End of contact details------------------------>