<div class="DashboardInner">
   <div class="container">
      <div class="innercontainer">
         <!-------------------------------Header------------------>
         <header class="dashboardHeader">
            <div class="row dsRow no-gutters">
               <div class="col-lg-4 col-sm-4 dsgrid dsleftpanel ">
                  <p class="paraxl fontrobotoblack clrwhite">
                     <img src="assets/images/dashboard/confirmed.svg" class="d-block d-lg-none d-sm-none bookingImg" />
                     <span class="d-block d-lg-none d-sm-none">Booking Confirmed</span>
                  </p>
               </div>
               <div class="col-lg-8 col-sm-8 dsgrid dsrightpanel">
                  <div class="dsheaderlist">
                     <div class="mlist dropdown">
                        <div class="dropdown-toggle" ngbDropdown #menu="ngbDropdown">
                           <p>{{getCustomerName()}}</p>

                           <button class="btn btn-outline-primary mr-2" id="dropdownManual" ngbDropdownAnchor
                              (focus)="menu.open()"><img src="assets/images/home/login.svg"
                              class="img-fluid" />
                              <span>My Activity</span></button>
                           <div ngbDropdownMenu aria-labelledby="dropdownManual">
                              <button ngbDropdownItem><span><img src="assets/images/home/drp-icon-2.png"
                                       class="img-fluid" /></span><a routerLink="/mydashboard"
                                    routerLinkActive="active">My Booking</a></button>
                              <button ngbDropdownItem (click)="logout()"><span><img
                                       src="assets/images/home/drp-icon-3.png"
                                       class="img-fluid" /></span>Logout</button>

                           </div>
                        </div>
                     </div>
                     <div class="dslogo">
                        <a class="nav-brand" routerLink="/home" routerLinkActive="active">
                           <img src="assets/images/home/Bano_logo_white.svg" class="img-fluid" />
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </header>

         <!---Innersection-->
<div class="dsInnerpanel ">
   <div class="dsbx dsbookingpanel">
      <div class="dsInnerTitle justify-content-start">
         <p class="paraxl fontrobotobold clrblack">
            <img src="assets/images/dashboard/confirmed.svg" class="d-none d-lg-block d-sm-block img-fluid confirmimg" />
            <span class="d-none d-lg-block d-sm-block">Booking Confirmed</span>
         </p>
      </div>
      <div class="row no-gutters bookingrow">
         <div class="col-lg-7 bookinggrid bkleft">
            <p class="paramedium fontrobotobold clrblack borderbtm">
               A Plumber will be assigned <span style="color:#E66499;">one hour</span> before the scheduled time
            </p>
            <div class="bk-details border-top-bottom">
               <div class="row addrconfirmrow no-gutters">
                  <div class="col-lg-6 col-sm-6 confirgrid">
                     <p class="parasmall fontrobotobold clrblack">
                        Service Location
                     </p>
                     <address>
                        {{addselected.housename_no}},{{addselected.street_name}} <br>
                        {{addselected.area}}, Chennai, <br>
                        Tamil Nadu {{addselected.pincode}}, India
                     </address>
                  </div>
                  <div class="col-lg-6 col-sm-6 confirgrid">
                     <p class="parasmall fontrobotobold clrblack">
                        Timings
                     </p>
                     <address>
                        {{selectday}} {{datestring}} <br>
                        {{selectedslot}}
                     </address>
                  </div>
               </div>
            </div>
            <div class="btnrequestpanel borderbtm">
               <button class="btn btnrequest rescedulebtn"
                  (click)="Reschedule()"><span>Re-Schedule</span></button>
               <button class="btn btnrequest cancelrequest"
                  (click)="CancelSchedule(cancelData)"><span>Cancel Request</span></button>
            </div>
            <div class="nhpanel">
               <p class="paralarge fontrobotobold">
                  Need Help
               </p>
               <div class="nhcontact">
                  <a href="tel:+1800 274 6060">
                     <img src="assets/images/dashboard/call-1.svg"/>
                     <span>1800 274 6060</span>
                  </a>
                  <a href="tel:+7301274274 ">
                    <img src="assets/images/dashboard/whatsapp.png" style="max-width:20px;"/>
                    <span>7301 274 274</span>
                 </a>
               </div>
            </div>

            <p class="parasmall">Get in touch with us if you face any issue in our service</p>
         </div>
         <div class="col-lg-5 bookinggrid bkpayment">
            <p class="paramedium text-center fontrobotobold clrblack">
               Order Summary
            </p>
            <div class="costrow">
               <div class="cs_inn cs_inn_confirm" *ngFor="let objpdt of product">
                  <p>{{objpdt.category_name}}-{{objpdt.product_name}}</p>
                  <p class="priceamt"><span>&#8377;</span> {{objpdt.price}}</p>
               </div>
               <div class="cs_inn cs_inn_confirm" *ngFor="let objaddon of addon">
                  <p>{{objaddon.addon_name}}</p>
                  <p class="priceamt"><span>&#8377;</span> {{objaddon.price}}</p>
               </div>
            </div>
            <div class="costrow" *ngIf="couponapply">
               <div class="cs_inn cs_inn_confirm" >
                  <p>Coupon Discount</p>
                  <p class="priceamt"><span>&#8377;</span>{{DiscountAmt}}</p>
               </div>
            </div>

            <div class="costrow">
                <div class="cs_inn cs_inn_confirm" >
                   <p>Tax Amount (18%)</p>
                   <p class="priceamt"><span>&#8377;</span>{{TaxAmt}}</p>
                </div>
             </div>

            <div class="total">
               <div class="cs_inn cs_inn_confirm">
                  <p class="paramedium fontrobotobold clrblack">Total</p>
                  <p class="paramedium priceamt fontrobotobold clrblack">&#8377; {{totalamt }}</p>
               </div>
            </div>
            
         </div>
      </div>
      

   </div>
</div>
      </div>
   </div>
</div>



<!-------------------------------Footer Panel------------------>
<section class="Footerpanel">
   <div class="footerTop">
      <div class="container">
         <div class="fTopInner text-center">
            <a routerLink="/home" routerLinkActive="active"><img src="assets/images/home/logo.png"
                  class="img-fluid" /></a>
            <p class="para">
               Parryware India is a leading manufacturer of bathroom products originated from EID Parry, one of the
               country's oldest and most reputed business entities. In 2006, it entered into a joint venture with Roca
               Corporation, Spain, to become a Roca Group subsidiary in 2008, and turned into a wholly-owned subsidiary
               of the Roca Group in 2011.
            </p>
         </div>
      </div>
   </div>
   <div class="footerBtm bggrey">
      <div class="container">
         <div class="row fbtmRow no-gutters">
            <div class="col-lg-4  fbtcopyrights">
               <p class="parasmall">© 2021 Parryware | All Rights Reserved</p>
            </div>
            <div class="col-lg-8  fbtmenus">
               <ul class="nav menu_footer">
                  <li><a routerLink="/aboutus" routerLinkActive="active" (click)="gotopage()">About us</a></li>
                  <li class="line"></li>
                  <li><a routerLink="/contact" routerLinkActive="active" (click)="gotopage()">Contact us</a></li>
                  <li class="line"></li>
                  <li><a pageScroll routerLink="/home" href="#faq" (click)="gotopage()">FAQ</a></li>
                  <li class="line"></li>
                  <li><a routerLink="/warrantypolicy" routerLinkActive="active"  (click)="gotopage()">Warranty Policy</a></li>
                  <li class="line"></li>
                  <li><a routerLink="/privacypolicy" routerLinkActive="active" (click)="gotopage()">Privacy Policy</a></li>
                  <li class="line"></li>
                  <li><a routerLink="/terms" routerLinkActive="active" (click)="gotopage()">Terms of Use</a></li>
              </ul>
           </div>
         </div>
      </div>
   </div>
</section>
<!-------------------------------Cancel Confirmation------------------>
<ng-template #cancelData let-modal>
   <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      size="medium"
      color="#fff"
      type="ball-scale-multiple"
    >
      <p style="font-size: 20px; color: white">Updating the Details....</p>
    </ngx-spinner>
      <div class="popupInner">
         <form [formGroup]="cancelForm" (ngSubmit)="storeCancel()">
         <h2 class="popuptitle clrblack fontrobotobold mb30">Reason For Cancelling</h2>
         <select class="addrinput mb30" formControlName="cancelreason" value="Select">
            <option value="Select" [defaultSelected]=true>Select</option>
            <option>Out of Station</option>
            <option>Will Reschedule later</option>
            <option>Work Completed by others</option>
            <option>Issue in website / App</option>
            <option>Others</option>
         </select>
      
            <div class="loginpanel" *ngIf="f.cancelreason.value==='Others'">
               <textarea class="txtbx pop_txtbx mb30" required placeholder="Enter Reason for Cancellation"
                  formControlName="txtReason" [ngClass]="{ 'is-invalid': submitted && f.txtReason.errors }" maxlength="100"></textarea>
               <div *ngIf="submitted && f.txtReason.errors" class="invalid-feedback">
                  <div *ngIf="f.txtReason.errors.required">Reason for cancellation is required</div>

               </div>

              
            </div>
            <input type="submit" class="btn booknow popbtn fullwidth" text="Confirm" value="Confirm" />
         </form>
      </div>
   </div>
</ng-template>

