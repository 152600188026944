import { ApiService } from './../api.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-selectservicedate',
  templateUrl: './selectservicedate.component.html',
  styleUrls: ['./selectservicedate.component.css']
})
export class SelectservicedateComponent implements OnInit {
  availabledates: any = [];
  availabletimes: any[];
  selecteddate = 1;
  selectedtime = 1;
  pincode1="";

  step1=false;
  step2=false;
  step3=false;
  step4=false;
  step5=false;

  constructor(private titleService:Title,private router: Router, private apiService: ApiService, private formBuilder: FormBuilder) { 
    this.titleService.setTitle("Parryware Bano | Select Service Date and Time");
  }

  ngOnInit(): void {
localStorage.setItem("step4","true");
    this.step1=JSON.parse(localStorage.getItem("step1"));
this.step2=JSON.parse(localStorage.getItem("step2"));
this.step3=JSON.parse(localStorage.getItem("step3"));
this.step4=JSON.parse(localStorage.getItem("step4"));

    let selectedaddress=JSON.parse(window.sessionStorage.getItem("addselected"));
    this.pincode1=selectedaddress.pincode;
    this.apiService.getDates(this.pincode1).subscribe((data: any) => {
      this.availabledates = data.available_dates;
      if(window.sessionStorage.getItem("selecteddate")!=null && window.sessionStorage.getItem("selecteddate")!="")
      {
        let objseldate=JSON.parse(window.sessionStorage.getItem("selecteddate"));
        console.log(objseldate);
        this.selecteddate=this.availabledates.findIndex((ob: any)=> ob.date===objseldate.date);
        console.log(this.selecteddate);
        
      }
      else
      {
      for(var i=1; i<this.availabledates.length; i++)
      {
        if(this.availabledates[i].status=="true")
        {
          this.selecteddate=i;
          break;
        }
            
      }
      window.sessionStorage.setItem("selecteddate",JSON.stringify(this.availabledates[this.selecteddate]));
    }
    if (this.availabledates.length > 0) {
      console.log("populating time slots");
      this.changeselectedDate(this.selecteddate, this.availabledates[this.selecteddate].date);
    }   
      
    })
    
  }

  getCustomerName() {
    return (localStorage.getItem("CustomerName"));
  }
  changeselectedDate(dateno: number, seldate: string) {
    this.selecteddate = dateno;
    window.sessionStorage.setItem("selecteddate",JSON.stringify(this.availabledates[this.selecteddate]));
    
    this.apiService.getTimeSlots(seldate,this.pincode1).subscribe((data: any) => {
      console.log(data);
      this.availabletimes = data.available_slot;
      if(window.sessionStorage.getItem("selectedtime")!=null && window.sessionStorage.getItem("selectedtime")!="")
      {
        let objseltime=JSON.parse(window.sessionStorage.getItem("selectedtime"));
        console.log(objseltime);
        this.selectedtime=this.availabletimes.findIndex((ob: any)=> ob.slot===objseltime.slot);
        console.log(this.selectedtime);
      }
      else
      {
      for(var i=1; i<this.availabletimes.length; i++)
      {
        if(this.availabletimes[i].status=="true")
        {
          this.selectedtime=i;
          break;
        }
            
      }
      window.sessionStorage.setItem("selectedtime",JSON.stringify(this.availabletimes[this.selectedtime]));
    }
      
    })
    
  }

  changeselectedTime(timeno: number) {
    this.selectedtime = timeno;
    window.sessionStorage.setItem("selectedtime",JSON.stringify(this.availabletimes[timeno]));
  }
  gotopage(){
    window.scrollTo(0, 0);
  }
  logout()
  {
    window.sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(["home"])
  }
}
