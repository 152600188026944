
     <div class="DashboardInner" data-ng-init="checkloggedin()">
        <div class="container">
                <header class="dashboardHeader">
                    <div class="row dsRow no-gutters">
                    <div class="col-lg-4 col-sm-4 dsgrid dsleftpanel">
                        <p class="paraxl fontrobotoblack clrwhite">
                        <a routerLink="/home" routerLinkActive="active"
                            ><img
                            src="assets/images/dashboard/back.png"
                            class="img-fluid"
                            />
                            <span>Back</span></a
                        >
                        </p>
                    </div>
                    <div class="col-lg-8 col-sm-8 dsgrid dsrightpanel">
                        <div class="dsheaderlist">
                        <div class="mlist dropdown">
                            <div class="dropdown-toggle" ngbDropdown #menu="ngbDropdown">
                            <p >Login/Sign up</p>
                            <a
                            
                                class="loginClass"
                            >
                                <img src="assets/images/home/login.svg" class="img-fluid" />
                                <span>My Activity</span>
                            </a>
                            <button
                                class="btn btn-outline-primary mr-2"
                                id="dropdownManual"
                                ngbDropdownAnchor
                                (focus)="menu.open()"
                                *ngIf="loggedin"
                            >
                                <p *ngIf="loggedin">Welcome {{ getCustomerName() }}</p>
                                <img src="assets/images/home/login.svg" class="img-fluid" />

                                <span>My Activity</span>
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownManual">
                                <button ngbDropdownItem>
                                <span
                                    ><img
                                    src="assets/images/home/drp-icon-2.png"
                                    class="img-fluid" /></span
                                ><a routerLink="/mydashboard" routerLinkActive="active"
                                    >My Booking</a
                                >
                                </button>
                                <button ngbDropdownItem (click)="logout()">
                                <span
                                    ><img
                                    src="assets/images/home/drp-icon-3.png"
                                    class="img-fluid" /></span
                                >Logout
                                </button>
                            </div>
                            </div>

                            <div class="dropdown-menu">
                            <a class="dropdown-item" href="#"></a>
                            <a class="dropdown-item" href="#"></a>
                            </div>
                        </div>
                        <div class="dslogo">
                            <a
                            class="nav-brand"
                            routerLink="/home"
                            routerLinkActive="active"
                            >
                            <img src="assets/images/home/Bano_logo_white.svg" class="img-fluid" />
                            </a>
                        </div>
                        </div>
                    </div>
                    </div>
                </header>
      </div>
     </div>


        <!-------------------------------Banner------------------>
        <section class="homebannerpanel ContactPanel position-relative">
            <div class="bgs amcbg"></div>
    
        </section>
        <!-------------------Banner Text----------->
        <div class="banText remove-pos">
            <div class="container">
                <div class="banTxtInner"> 
                    <h1 class="h2title fontrobotoblack clrlack ">
                        AMC (Annual Maintenance Contracts)
                    </h1>
                </div>
                <div>
                    <ul  class="breadcrumb-theme">
                        <li><a routerLink="/home" routerLinkActive="active">Home  </a></li>
                        <li> /</li>
                        <li><a>  AMC (Annual Maintenance Contracts)</a></li>
                    </ul>
                </div>
            </div>
        </div>
    <!-----------------------------------Contact form-------------->
    <section class="contactFormPanel">
    <div class="container">
        <div class="row no-gutters cfp_row">
            <div class="col-lg-6 cfp_grid cfp_info">
                <p  class="paramedium mb50">
                    All electronic products we offer are covered under AMC.
                </p>
                <h3 class="clrblue mb30 fontrobotobold">What it includes
                </h3>
                <p class="paramedium mb15 fontrobotobold">Site visit
                </p>
                <p class="paramedium mb30">
                    Technicians will be visiting your place for 4 preventive visits, and 4 breakdown visits once in 3 months on priority.
                </p>
                <p class="paramedium mb15 fontrobotobold">Replacements
                </p>
                <p class="paramedium mb30">
                    Any part replacement identified during visits will be done Free of charge.
                </p>
                <p class="paramedium mb15 fontrobotobold">Free Cleaning kit
                </p>
                <p class="paramedium mb50">
                    Customers will receive a one- year cleaning kit complementary to the service
                </p>
                <div class="valueformsec">
                    <h3 class="clrblue mb30 fontrobotobold text-center">Schedule Your Free Consultation
                    </h3>
                   <div class="valu-form">
                       <div class="form-group mb15">
                            <input class="ctformstyle" placeholder="Name"/>
                       </div>
                       <div class="form-group mb15">
                            <input class="ctformstyle" placeholder="Email ID"/>
                       </div>
                        <div class="form-group mb15">
                            <input class="ctformstyle" placeholder="Mobile Number"/>
                       </div>
                        <div class="form-group mb30">
                            <input class="ctformstyle" placeholder="Briefly describe your project - ideas, goals, timeframe etc"/>
                       </div>  
                       <div class="form-group d-flex  justify-content-center">
                        <a class="gonextbtn btn booknow ctsubmitbtn" >Submit 
                            <img src="assets/images/dashboard/continue.png" class="img-fluid"/>
                        </a>
                       </div>                                                                             
                   </div>
                </div>
            </div>
            <div class="col-lg-6 cfp_grid cfp_form">
                <div class="cfpformInner">
                    <img src="assets/images/home/newimages/deep-cleaning-right.jpg" class="img-fluid" />
                   
                </div>
            </div>
        </div>
    </div>
    </section>
    <section class="valueform">
        <div class="container">
            <div class="row no-gutters cfp_row">

                <div class="col-lg-6 cfp_grid cfp_form">

                </div>
            </div>
        </div>
        </section>
    <!-------------------------------Footer Panel------------------>
    <section class="Footerpanel">
        <div class="footerTop">
           <div class="container">
              <div class="fTopInner text-center">
                 <a routerLink="/home" routerLinkActive="active"><img src="assets/images/home/logo.png" class="img-fluid"/></a>
                 <p class="para">
                    Parryware India is a leading manufacturer of bathroom products originated from EID Parry, one of the country's oldest and most reputed business entities. In 2006, it entered into a joint venture with Roca Corporation, Spain, to become a Roca Group subsidiary in 2008, and turned into a wholly-owned subsidiary of the Roca Group in 2011.
                 </p>
              </div>
           </div>
        </div>
        <div class="footerBtm ">
            <div class="container">
               <div class="row fbtmRow no-gutters">
                  <div class="col-lg-8    fbtmenus">
                    <ul class="nav menu_footer">
                       <li><a routerLink="/aboutus" routerLinkActive="active" (click)="gotopage()">About us</a></li>
                       <li class="line"></li>
                       <li><a routerLink="/contact" routerLinkActive="active" (click)="gotopage()">Contact us</a></li>
                       <li class="line"></li>
                       <li><a pageScroll href="#faq" (click)="gotopage()">FAQ</a></li>
                       <li class="line"></li>
                       <li><a routerLink="/warrantypolicy" routerLinkActive="active"  (click)="gotopage()">Warranty Policy</a></li>
                       <li class="line"></li>
                       <li><a routerLink="/privacypolicy" routerLinkActive="active" (click)="gotopage()">Privacy Policy</a></li>
                       <li class="line"></li>
                       <li><a routerLink="/terms" routerLinkActive="active" (click)="gotopage()">Terms of Use</a></li>
                    </ul>
                  </div>
                  <div class="col-lg-4  social-grid ">
                    <p class="parasmall">Follow us on:</p>
                    <ul class="social-menu">
                       <li><a href="https://www.facebook.com/parrywarebano/" target="_blank"><img src="assets/images/facebook.svg" class="img-fluid" /></a></li>
                       <li><a><img src="assets/images/instagram.svg" class="img-fluid" /></a></li>
                    </ul>
                  </div>
               </div>
               <!--coprights-->
               <div class="copyright">
                  <p class="parasmall clrwhite text-center">© 2021 Parryware | All Rights Reserved</p>
              </div>
            </div>
         </div>
     </section>
