<!-------------------------------Header------------------>
<div class="DashboardInner" data-ng-init="checkloggedin()">
    <div class="container">
        <header class="dashboardHeader">
            <div class="row dsRow no-gutters">
                <div class="col-lg-4 col-sm-4 dsgrid dsleftpanel">
                    <p class="paraxl fontrobotoblack clrwhite">
                        <a routerLink="/home" routerLinkActive="active"><img src="assets/images/dashboard/back.png"
                                class="img-fluid" />
                            <span>Back</span></a>
                    </p>
                </div>
                <div class="col-lg-8 col-sm-8 dsgrid dsrightpanel">
                    <div class="dsheaderlist">
                        <div class="mlist dropdown">
                            <div class="dropdown-toggle" ngbDropdown #menu="ngbDropdown">
                                <p>Login/Sign up</p>
                                <a class="loginClass">
                                    <img src="assets/images/home/login.svg" class="img-fluid" />
                                    <span>My Activity</span>
                                </a>
                                <button class="btn btn-outline-primary mr-2" id="dropdownManual" ngbDropdownAnchor
                                    (focus)="menu.open()" *ngIf="loggedin">
                                    <p *ngIf="loggedin">Welcome {{ getCustomerName() }}</p>
                                    <img src="assets/images/home/login.svg" class="img-fluid" />

                                    <span>My Activity</span>
                                </button>
                                <div ngbDropdownMenu aria-labelledby="dropdownManual">
                                    <button ngbDropdownItem>
                                        <span><img src="assets/images/home/drp-icon-2.png" class="img-fluid" /></span><a
                                            routerLink="/mydashboard" routerLinkActive="active">My Booking</a>
                                    </button>
                                    <button ngbDropdownItem (click)="logout()">
                                        <span><img src="assets/images/home/drp-icon-3.png"
                                                class="img-fluid" /></span>Logout
                                    </button>
                                </div>
                            </div>

                            <div class="dropdown-menu">
                                <a class="dropdown-item" href="#"></a>
                                <a class="dropdown-item" href="#"></a>
                            </div>
                        </div>
                        <div class="dslogo">
                            <a class="nav-brand" routerLink="/home" routerLinkActive="active">
                                <img src="assets/images/home/Bano_logo_white.svg" class="img-fluid" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</div>

<!-------------------------------Banner------------------>
<section class=" homebannerpanel ContactPanel position-relative">
    <div class="bgs combodealbg"></div>
    <div class="combodealbgmobile">

    </div>
</section>
<!-------------------Banner text------------->
<div class="banText remove-pos">
    <div class="container">
        <div class="banTxtInner">
            <h1 class="h2title fontrobotoblack clrlack ">
                UPGRADE YOUR BATHROOM
            </h1>
        </div>
        <div>
            <ul class="breadcrumb-theme">
                <li><a routerLink="/home" routerLinkActive="active">Home </a></li>
                <li> /</li>
                <li><a> Upgrade your bathroom</a></li>
            </ul>
        </div>

    </div>
</div>
<!-----------------------------------Contact form-------------->
<section class="contactFormPanel">
    <div class="container">
        <div class="row no-gutters cfp_row cfprow-new">
            <div class="col-lg-6 cfp_grid cfp_info">
                <!--<p  class="paramedium mb50">We have put together an array of services at the best prices for you. These combos are a blend of quintessential services that you need to transform the bathroom space. We are also open to curate and customize services according to your needs!</p>-->
                <p class="paramedium mb20">
                    Do you want to give your bathroom a makeover but do not know what to pick or where to begin? No
                    worries! We have done extensive research so that you don’t have to!
                </p>
                <p class="paramedium mb20">
                    To make life easier, we have carefully chosen and curated amazing bundles for you. Pick the one
                    which suits your taste, needs and budget and let us do the rest while you relax! These bundles are
                    available at best prices for you, and include every quintessential thing that is required to
                    transform the space to your dream bathroom!
                </p>
                <div class="bundle-text">
                    <p class="paramedium mb20">
                        Pick the bundle you need and get the below add-on services
                        <span class="d-block">FOR FREE!</span>
                    </p>
                    <ol class="olcombolist">
                        <li>Free Inspection</li>
                        <li>Free Delivery</li>
                        <li>Deep Cleaning</li>
                        <li>100 days Workmanship warranty</li>
                        <li>Free Cleaning kit for maintenance</li>

                    </ol>
                    <p class="paramedium mb20">
                        Scroll down to have a look at the exciting Bundles!
                    </p>
                </div>
                <p class="paramedium mb20">
                    <b>BATHROOM BUNDLES</b>
                </p>
                <ul class="bundlelist mb20">
                    <li>
                        <p>Elite Elegance</p>
                        <span class="strikethrough">Rs.18000</span>
                        <span> Rs.13,000/-</span>
                    </li>
                    <li>
                        <p>Niagara Novelty </p>
                        <span class="strikethrough">Rs.24000 </span>
                        <span>Rs.17,500/-
                        </span>
                    </li>
                    <li>
                        <p>Camel Charisma
                        </p>
                        <span class="strikethrough">Rs.33000 </span>
                        <span> Rs.25,000/-</span>
                    </li>
                    <li>
                        <p>Marvel Magic</p>
                        <span class="strikethrough">Rs.36000</span>
                        <span>Rs.28,000/-
                        </span>
                    </li>
                </ul>

                <p>
                    Please download the PDF for more details
                </p>
                <a href="assets/pdf/ParrywareBanoComboDealsCatalog.pdf" download class="download-btn">Download Pdf</a>
            </div>
            <div class="col-lg-6 cfp_grid cfp_form">
                <div class="cfpformInner">
                    <img src="assets/images/home/newimages/combo-deals-banner.jpg" class="img-fluid" />

                </div>
                <!---form-->

                <div class="valueformsec">
                    <h3 class="clrblue mb30 fontrobotobold">Schedule Your Free Consultation
                    </h3>
                    <form [formGroup]="combodealsForm" (ngSubmit)="ComboDealsSubmit()">
                        <div class="valu-form">
                            <div class="form-group mb15">
                                <input class="ctformstyle" required type="text" placeholder="Name*"
                                    formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                    <div *ngIf="f.name.errors.required">Name is required</div>
                                    <div *ngIf="f.name.errors.pattern || f.name.errors.minlength">
                                        Name must be at least 3 Chars</div>
                                </div>
                            </div>
                            <div class="form-group mb15">
                                <input class="ctformstyle" required type="text" placeholder="Email ID*"
                                    formControlName="emailid"
                                    [ngClass]="{ 'is-invalid': submitted && f.emailid.errors }">
                                <div *ngIf="submitted && f.emailid.errors" class="invalid-feedback">
                                    <div *ngIf="f.emailid.errors.required">Email Id is required</div>
                                    <div *ngIf="f.emailid.errors.pattern">
                                        Email Id is Invalid</div>
                                </div>
                            </div>
                            <div class="form-group mb15">
                                <input class="ctformstyle" (keypress)="keyPress($event)" required type="text"
                                    placeholder="Mobile Number*" formControlName="mobilenumber"
                                    [ngClass]="{ 'is-invalid': submitted && f.mobilenumber.errors }">
                                <div *ngIf="submitted && f.mobilenumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.mobilenumber.errors.required">Phone number is required</div>
                                    <div
                                        *ngIf="f.mobilenumber.errors.pattern || f.mobilenumber.errors.maxlength || f.mobilenumber.errors.minlength">
                                        Phone number must be at least 10 numbers</div>
                                </div>
                            </div>
                            <div class="form-group mb15">
                                <input class="ctformstyle" (keypress)="keyPress($event)" required type="text"
                                    placeholder="Pincode*" formControlName="pincode"
                                    [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }">
                                <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                                    <div *ngIf="f.pincode.errors.required">Pincode is required</div>
                                    <div
                                        *ngIf="f.pincode.errors.pattern || f.pincode.errors.maxlength || f.pincode.errors.minlength">
                                        Pincode must be at least 6 numbers</div>
                                </div>
                            </div>
                            <div class="form-group mb30">
                                <input class="ctformstyle" required type="text" placeholder="Remarks"
                                    formControlName="remarks"
                                    [ngClass]="{ 'is-invalid': submitted && f.remarks.errors }">
                                <div *ngIf="submitted && f.remarks.errors" class="invalid-feedback">
                                    <!-- <div *ngIf="f.remarks.errors.required">Remarks is required</div> -->
                                    <div *ngIf="f.remarks.errors.pattern || f.remarks.errors.minlength">
                                        Remarks must be at least 3 Chars</div>
                                </div>

                            </div>
                            <div class="form-group d-flex justify-content-center">
                                <input type="submit" class="gonextbtn btn booknow ctsubmitbtn" text="Submit"
                                    value="Submit" />
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>

    </div>
</section>

<!-------------------------------Footer Panel------------------>
<section class="Footerpanel">
    <div class="footerTop">
        <div class="container">
            <div class="fTopInner text-center">
                <a routerLink="/home" routerLinkActive="active"><img src="assets/images/home/logo.png"
                        class="img-fluid" /></a>
                <p class="para">
                    Parryware India is a leading manufacturer of bathroom products originated from EID Parry, one of the
                    country's oldest and most reputed business entities. In 2006, it entered into a joint venture with
                    Roca Corporation, Spain, to become a Roca Group subsidiary in 2008, and turned into a wholly-owned
                    subsidiary of the Roca Group in 2011.
                </p>
            </div>
        </div>
    </div>
    <div class="footerBtm ">
        <div class="container">
            <div class="row fbtmRow no-gutters">
                <div class="col-lg-8    fbtmenus">
                    <ul class="nav menu_footer">
                        <li><a routerLink="/aboutus" routerLinkActive="active" (click)="gotopage()">About us</a></li>
                        <li class="line"></li>
                        <li><a routerLink="/contact" routerLinkActive="active" (click)="gotopage()">Contact us</a></li>
                        <li class="line"></li>
                        <li><a pageScroll href="#faq" (click)="gotopage()">FAQ</a></li>
                        <li class="line"></li>
                        <li><a routerLink="/warrantypolicy" routerLinkActive="active" (click)="gotopage()">Warranty
                                Policy</a></li>
                        <li class="line"></li>
                        <li><a routerLink="/privacypolicy" routerLinkActive="active" (click)="gotopage()">Privacy
                                Policy</a></li>
                        <li class="line"></li>
                        <li><a routerLink="/terms" routerLinkActive="active" (click)="gotopage()">Terms of Use</a></li>
                    </ul>
                </div>
                <div class="col-lg-4  social-grid ">
                    <p class="parasmall">Follow us on:</p>
                    <ul class="social-menu">
                        <li><a href="https://www.facebook.com/parrywarebano/" target="_blank"><img
                                    src="assets/images/facebook.svg" class="img-fluid" /></a></li>
                        <li><a><img src="assets/images/instagram.svg" class="img-fluid" /></a></li>
                    </ul>
                </div>
            </div>
            <!--coprights-->
            <div class="copyright">
                <p class="parasmall clrwhite text-center">© 2021 Parryware | All Rights Reserved</p>
            </div>
        </div>
    </div>
</section>