import { Component, OnInit } from '@angular/core';
import { OrderStatusModel, plumberreviewModel } from './../order-model';
import { AddressModel } from './../address-model';
import { ActivatedRoute,Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../api.service';
import {Title} from "@angular/platform-browser";

import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { ToWords } from 'to-words';

const toWords = new ToWords({
  localeCode: 'en-IN',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
  }
});

@Component({
  selector: 'app-servicecompleted',
  templateUrl: './servicecompleted.component.html',
  styleUrls: ['./servicecompleted.component.css'],

})
export class ServicecompletedComponent implements OnInit {
  submitted:boolean=false;
  currentRate: number = 0;
  product: any = [];
  addon: any = [];
  totalamt: number = 0;
  selecteddate: any = {};
  selectedslot: any = {};
  selectday: string = "";
  dateselected: Date = new Date();
  datestring: string = "";
  addselected: AddressModel;
  orderDetail: OrderStatusModel;
  withqueryparams = false;
  starttime: string;
  plumberrating: plumberreviewModel;
  plumbername: string;
  plumberid: number;
  DiscountAmt:number=0;
  amountstring:string="";
  servicerating: number;
  ratemodal: NgbModalRef;
  ctrl = new FormControl(null, Validators.required);
  ctrl1 = new FormControl(null, Validators.required);
  ctrl2 = new FormControl(null, Validators.required);
  ddlImprove=new FormControl(null, Validators.required);
  txtComments=new FormControl(null, Validators.required);
  improveSelection: string = "";
  rateForm = new FormGroup({ ddlImprove:new FormControl(''),txtComments:new FormControl(''),ctrl: new FormControl(''),ctrl1: new FormControl(''),ctrl2:new FormControl('')})

  constructor(private titleService:Title,private router:Router,private apiservice:ApiService, private formBuilder:FormBuilder,private modal: NgbModal, private datepipe: DatePipe, private route: ActivatedRoute) { 
    this.titleService.setTitle("Parryware Bano | Service Completed");
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.orderDetail = JSON.parse(params.orderDetail);
      this.withqueryparams = true;
    });
    if (this.withqueryparams) {
      console.log(this.orderDetail);
      this.product = this.orderDetail.product_details;
      console.log(this.product);
      this.addon = this.orderDetail.addon_details;
      this.dateselected = new Date(this.orderDetail.appointment_date);
      this.selectedslot = this.orderDetail.appointment_slot;
      this.addselected = {
        customer_id: this.orderDetail.customer_id,
        cust_name: this.orderDetail.cust_name,
        housename_no: this.orderDetail.housename_no,
        street_name: this.orderDetail.street_name,
        area: this.orderDetail.area,
        landmark: this.orderDetail.landmark,
        city_id: this.orderDetail.city_id,
        state_id: this.orderDetail.state_id,
        pincode: this.orderDetail.pincode,
        tel_no: this.orderDetail.tel_no,
        email: this.orderDetail.email,
        address_type: ""
      }
      let time1 = this.selectedslot;
      console.log(time1);
      let timeslot = time1.split("-");
      this.starttime = timeslot[0];
      this.datestring = this.datepipe.transform(this.dateselected, 'MMMM dd, yyyy')
      if(this.product!==null)
      {
      for (var i = 0; i < this.product.length; i++) {

        this.totalamt += this.product[i].price;
      }
    }
    if(this.addon!==null)
    {
      for (var i = 0; i < this.addon.length; i++) {

        this.totalamt += this.addon[i].price;
      }
    }
      this.totalamt+=this.orderDetail.CCExpensesAmt;
      this.totalamt+=this.orderDetail.OthersBillAmt;
      this.totalamt+=this.orderDetail.SparesBillAmt;
      this.plumberrating = this.orderDetail.plumber_review;
    
      if (this.plumberrating !== undefined  && this.plumberrating!==null) {
        this.servicerating = this.plumberrating.service_rating;
        this.ctrl.setValue(this.plumberrating.on_time_arrival);
        this.ctrl1.setValue(this.plumberrating.plumber_behaviour);
        this.ctrl2.setValue(this.plumberrating.service_rating);
        this.ddlImprove.setValue(this.plumberrating.need_improvement)
        this.txtComments.setValue(this.plumberrating.comments)
      }
      this.plumberid = this.orderDetail.plumber_id;
      this.plumbername = this.orderDetail.plumber_name;
    }

    this.rateForm = this.formBuilder.group({
      ctrl: [null, [Validators.required]],
      ctrl1: [null, [Validators.required]],
      ctrl2: [null, [Validators.required]],
      txtimprove: [null, [Validators.required]],
      txtComments: [null, [Validators.required]]
    });
    this.amountstring=toWords.convert(this.totalamt);

  }
  gotopage(){
    window.scrollTo(0, 0);
  }
  getButtonText()
  {
    
    if(this.plumberrating=== undefined)
      return "Rate Plumber"
    else
      return "Edit Review"
  }

  get f() { return this.rateForm.controls; }

  getCustomerName() {
    return (localStorage.getItem("CustomerName"));
  }

  triggerRateModel(content: any) {
    this.ratemodal = this.modal.open(content, { windowClass: 'parrymodal ratepopup ' });
  }
  
  storeRating()
  {
    this.submitted=true;
    this.plumberrating={
      plumber_id:this.plumberid,
      order_id:this.orderDetail.order_id,
      on_time_arrival:this.ctrl.value,
      plumber_behaviour:this.ctrl1.value,
      service_rating:this.ctrl2.value,
      need_improvement:this.ddlImprove.value,
      comments:this.txtComments.value
    }
    this.apiservice.postplumberreview(this.plumberrating).subscribe((data:any)=>{
        this.ratemodal.close();
    })
  }

  logout()
  {
    window.sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(["home"])
  }

  public openPDF():void {
    const doc = new jsPDF();

  autoTable(doc,{theme: 'grid',columnStyles: {2:{halign:'right'},3:{halign:'right'},4:{halign:'right'}},html: '#invoice'});
        let filename='invoice_'+this.orderDetail.order_number.toString() + ".pdf";
        doc.save(filename);
   
    }
}
