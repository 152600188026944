<div class="DashboardInner">
    <div class="container">
       <div class="innercontainer">
     <!-------------------------------Header------------------>
     <header class="dashboardHeader">
       <div class="row dsRow no-gutters">
          <div class="col-lg-4 col-sm-4 dsgrid dsleftpanel">
             <p class="paraxl fontrobotoblack clrwhite">
                <a (click)="NavigateBack()" style="cursor: hand;"><img src="assets/images/dashboard/back.png" class="img-fluid"/>
                <span >Back</span></a>
             </p>
          </div>
          <div class="col-lg-8 col-sm-8 dsgrid dsrightpanel">
          <div class="dsheaderlist">

            <div class="dslogo">
             <a class="nav-brand" routerLink="/home" routerLinkActive="active">
                <img src="assets/images/home/Bano_logo_white.svg" class="img-fluid" />
             </a>
         </div>
         </div>
          </div>
       </div>
     </header>
     <!--Privacy policy-->
     <div class="Contentpanel clrwhite">
        <h2 class="h3title  clrwhite mb30 robotofontbold">WARRANTY TERMS
        </h2>
        <p class="para mb15">
            *Parryware Products are Manufactured using the latest technologies and confirm to International standarads, All of the Products go through a stringent quality check protocol,thus meeting the highest standards and offer a long working life. Our robust products backed by an equally strong Parryware warranty policy thus displaying the highest levels of quality assurance of customers * 
        </p>
        <div class="table-responsive">
            <table class="parrywareTable">
                <thead>
                    <td>S.No</td>
                    <td>Product Category</td>
                    <td>Parryware (No of Years from date of invoice)</td>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>VC Sanitary ware</td>
                        <td>10</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Internal fittings for Sanitaryware</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Seat Covers</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Faucets - For Brass Body</td>
                        <td>10</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>Internal fittings for Faucets</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>Angle valves + CSC (Non range)</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>Showers(O/H Showers and hand shower)</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>Health faucets</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>Push Taps Internal fitting</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>Electronic Urinal - Electronic/Electircal assembly</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>Electronic-Taps(For Brass Body)</td>
                        <td>10</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>Electronic/Electrical assembly for E taps</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>Battery for E Taps</td>
                        <td>Nil</td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>Concealed Cistern Tank </td>
                        <td>10</td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>Concealed Cistern Push Plate</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>Concealed Cistern internal fittings</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>Plastic Cistern Internal Fittings</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>Shower Panels</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>19</td>
                        <td>Shower Enclosures</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>Shower Rail</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>21</td>
                        <td>Plain Bath Tubs & Whirpool (Tub)</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <td>22</td>
                        <td>Steel Enamelled Bath tub</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>23</td>
                        <td>Whirlpool Handles</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>24</td>
                        <td>Whirlpool Jets</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>25</td>
                        <td>Kitchen Sinks</td>
                        <td>25</td>
                    </tr>
                    <tr>
                        <td>26</td>
                        <td>Vanity Cabinets</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>27</td>
                        <td>Mounting brackets</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>28</td>
                        <td>Water Heater - Storage (Electircal & Electronic Parts)</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>29</td>
                        <td>Water Heater - Storage - Internal Tank leakage</td>
                        <td>7</td>
                    </tr>
                    <tr>
                        <td>30</td>
                        <td>Water Heater - Instant (All the Parts)</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>31</td>
                        <td>Accessaries (Tumbler holder, Towel rack, Towel rail, Tower ring, Soap dispenser,
                            Soap dish, Paper holder, Robe hook, Ageis Products)
                        </td>
                        <td>6 months</td>
                    </tr>
                    <tr>
                        <td>32</td>
                        <td>Bottle Trap</td>
                        <td>6 months</td>
                    </tr>
                    <tr>
                        <td>33</td>
                        <td>Connection hoses</td>
                        <td>6 months</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p class="para mb15">
            Note : Battery / Rubber / wear & tear parts has no warranty 
        </p>
     </div>
    </div>
     </div>
    </div>
    <!-------------------------------Footer Panel------------------>
    <section class="Footerpanel">
       <div class="footerTop">
          <div class="container">
             <div class="fTopInner text-center">
                <a ><img src="assets/images/home/logo.png" class="img-fluid"/></a>
                <p class="para">
                   Parryware India is a leading manufacturer of bathroom products originated from EID Parry, one of the country's oldest and most reputed business entities. In 2006, it entered into a joint venture with Roca Corporation, Spain, to become a Roca Group subsidiary in 2008, and turned into a wholly-owned subsidiary of the Roca Group in 2011.
                </p>
             </div>
          </div>
       </div>
       <div class="footerBtm bggrey">
          <div class="container">
             <div class="row fbtmRow no-gutters">
                <div class="col-lg-4  fbtcopyrights">
                   <p class="parasmall">© 2021 Parryware | All Rights Reserved</p>
                </div>
                <div class="col-lg-8  fbtmenus">
                    <ul class="nav menu_footer">
                        <li><a routerLink="/aboutus" routerLinkActive="active" (click)="gotopage()">About us</a></li>
                        <li class="line"></li>
                        <li><a routerLink="/contact" routerLinkActive="active" (click)="gotopage()">Contact us</a></li>
                        <li class="line"></li>
                        <li><a pageScroll routerLink="/home" href="#faq" (click)="gotopage()">FAQ</a></li>
                        <li class="line"></li>
                        <li><a routerLink="/warrantypolicy" routerLinkActive="active"  (click)="gotopage()">Warranty Policy</a></li>
                        <li class="line"></li>
                        <li><a routerLink="/privacypolicy" routerLinkActive="active" (click)="gotopage()">Privacy Policy</a></li>
                        <li class="line"></li>
                        <li><a routerLink="/terms" routerLinkActive="active" (click)="gotopage()">Terms of Use</a></li>
                    </ul>
                 </div>
             </div>
          </div>
       </div>
    </section>
