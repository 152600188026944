<div class="DashboardInner">
   <div class="container">
      <div class="innercontainer">
         <!-------------------------------Header------------------>
         <header class="dashboardHeader">
            <div class="row dsRow no-gutters">
               <div class="col-lg-4 col-sm-4 dsgrid dsleftpanel">
                  <p class="paraxl fontrobotoblack clrwhite">
                     <a routerLink="/addon" routerLinkActive="active"><img src="assets/images/dashboard/back.png"
                           class="img-fluid" /></a>
                     <span class="d-none d-lg-block d-sm-block">Book Your Service</span>
                     <span class="d-block d-lg-none d-sm-none">Select Address</span>
                  </p>
               </div>
               <div class="col-lg-8 col-sm-8 dsgrid dsrightpanel">
                  <div class="dsheaderlist">
                     <div class="mlist dropdown">
                        <div class="dropdown-toggle" ngbDropdown #menu="ngbDropdown">
                           <p>{{getCustomerName()}}</p>
         
                           <button class="btn btn-outline-primary mr-2" id="dropdownManual" ngbDropdownAnchor
                              (focus)="menu.open()"> <img src="assets/images/home/login.svg"
                                 class="img-fluid" />
                              <span>My Activity</span></button>
                           <div ngbDropdownMenu aria-labelledby="dropdownManual">
                              <button ngbDropdownItem><span><img src="assets/images/home/drp-icon-2.png"
                                       class="img-fluid" /></span><a routerLink="/mydashboard"
                                    routerLinkActive="active">My Booking</a></button>
                              <button ngbDropdownItem (click)="logout()"><span><img src="assets/images/home/drp-icon-3.png"
                                       class="img-fluid" /></span>Logout</button>
         
                           </div>
                        </div>
                     </div>
                     <div class="dslogo">
                        <a class="nav-brand" routerLink="/home" routerLinkActive="active">
                           <img src="assets/images/home/Bano_logo_white.svg" class="img-fluid" />
                       </a>
                     </div>
                  </div>
               </div>
            </div>
         </header>
         <!---------------------------------steps-------------------->
         <section class="dsSteps">
            <div class="Stlist">
               <ul class="nav">
                   <li class="st-active"><a routerLink="/services" routerLinkActive="active" [ngClass]="{'adisabled':!step1}">01</a>
                       <span>Select Services</span>
                   </li>
                   <div class="stline"></div>
                   <li class="st-active"><a routerLink="/addon" routerLinkActive="active" [ngClass]="{'adisabled':!step2}">02</a>
                       <span>Select Add-on</span>
                   </li>
                   <div class="stline"></div>
                   <li class="st-active"><a routerLink="/selectaddress" routerLinkActive="active" [ngClass]="{'adisabled':!step3}">03</a>
                       <span>Address Information</span>
                   </li>
                   <div class="stline"></div>
                   <li><a routerLink="/selectservicedate" routerLinkActive="active" [ngClass]="{'adisabled':!step4}">04</a>
                       <span>Select Date / Time</span>
                   </li>
                   <div class="stline"></div>
                   <li><a routerLink="/confirmbooking" routerLinkActive="active" [ngClass]="{'adisabled':!step5}">05</a>
                       <span>Confirmation</span>
                   </li>
               </ul>
           </div>
            <!------------------Inner section--------->
            <div class="dsInnerpanel ">
               <div class="dsbx">
                  <div class="dsInnerTitle">
                     <a routerLink="/addon" routerLinkActive="active">
                        < Back</a>
                           <p class="paraxl fontrobotobold clrblack">Select Address</p>
                  </div>
                  <div class="add_list_panel">
                     <div class="newaddr_add">
                        <form [formGroup]="selectaddressForm" (ngSubmit)="navigateTonext()">
                           <div class="selected_addr" *ngFor="let objadd of custaddress">
                              <label>
                                 <input type="radio" class="parry_checkbox" formControlName="selectaddress"
                                    value={{objadd.address_id}}    required/>
                                 <p class="hme">{{objadd.address_type==="work"?"Office":objadd.address_type | titlecase}}</p>

                                 <p>
                                    {{objadd.housename_no}},{{objadd.street_name}},{{objadd.area}},{{objadd.pincode}}
                                 </p>
                                 <a (click)="editaddress(objadd.address_id,editaddressModal)" ><span>...</span></a>
                              </label>
                             
                           </div>
                           <div *ngIf="f4.selectaddress.touched && f4.selectaddress.invalid" class="invalid-feedback">
                              <div *ngIf="f4.selectaddress.errors.required">Select any one address</div>
                           </div>
                           <a class="btn fullwidth newaddressbtn" (click)="openaddressModal(addressModal)">
                              <img src="assets/images/dashboard/plus-blue.png" class="img-fluid " />
                              Add a new address
                           </a>
                           <button type="submit" (click)="gotopage()" class="gonextbtn btn booknow popbtn fullwidth" [disabled]="!selectaddressForm.valid"><span>Continue with
                              this address </span>
                              <img src="assets/images/dashboard/continue.png" class="img-fluid" />
                           </button>
                        </form>
                     </div>

                  </div>


               </div>
            </div>
         </section>
      </div>
   </div>
</div>
<!-------------------------------Footer Panel------------------>
<section class="Footerpanel">
   <div class="footerTop">
      <div class="container">
         <div class="fTopInner text-center">
            <a routerLink="/home" routerLinkActive="active"><img src="assets/images/home/logo.png"
                  class="img-fluid" /></a>
            <p class="para">
               Parryware India is a leading manufacturer of bathroom products originated from EID Parry, one of the
               country's oldest and most reputed business entities. In 2006, it entered into a joint venture with Roca
               Corporation, Spain, to become a Roca Group subsidiary in 2008, and turned into a wholly-owned subsidiary
               of the Roca Group in 2011.
            </p>
         </div>
      </div>
   </div>
   <div class="footerBtm bggrey">
      <div class="container">
         <div class="row fbtmRow no-gutters">
            <div class="col-lg-4  fbtcopyrights">
               <p class="parasmall">© 2021 Parryware | All Rights Reserved</p>
            </div>
            <div class="col-lg-8  fbtmenus">
               <ul class="nav menu_footer">
                  <li><a routerLink="/aboutus" routerLinkActive="active" (click)="gotopage()">About us</a></li>
                  <li class="line"></li>
                  <li><a routerLink="/contact" routerLinkActive="active" (click)="gotopage()">Contact us</a></li>
                  <li class="line"></li>
                  <li><a pageScroll routerLink="/home" href="#faq" (click)="gotopage()">FAQ</a></li>
                  <li class="line"></li>
                  <li><a routerLink="/warrantypolicy" routerLinkActive="active"  (click)="gotopage()">Warranty Policy</a></li>
                  <li class="line"></li>
                  <li><a routerLink="/privacypolicy" routerLinkActive="active" (click)="gotopage()">Privacy Policy</a></li>
                  <li class="line"></li>
                  <li><a routerLink="/terms" routerLinkActive="active" (click)="gotopage()">Terms of Use</a></li>
              </ul>
           </div>
         </div>
      </div>
   </div>
</section>

<!-----------------------------Login Popup------------------------>
<ng-template #modalData let-modal>
   <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="popupInner">
         <h2 class="popuptitle clrblack fontrobotobold mb30">Login</h2>
         <img src="assets/images/home/Login-popup.svg" class="mb30 img-fluid" />
         <form [formGroup]="registerForm" (ngSubmit)="getotp(modalOtp)">
            <p class="popupsubtitle clrblack fontrobotobold mb30">Enter your mobile number</p>
            <div class="loginpanel">
               <div class="form-group mb30">
               <input class="txtbx pop_txtbx " (keypress)="keyPress($event)" required type="text"
                  placeholder="Enter your mobile number" formControlName="phonenumber"
                  [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }">
               <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                  <div *ngIf="f.phonenumber.errors.required">Phone number is required</div>
                  <div
                     *ngIf="f.phonenumber.errors.pattern || f.phonenumber.errors.maxlength || f.phonenumber.errors.minlength">
                     Phone number must be at least 10 numbers</div>
               </div>
            </div>
               <input type="submit" class="btn booknow popbtn fullwidth" text="Continue" value="Continue" />
            </div>
         </form>
      </div>
   </div>
</ng-template>
<!--------------------------OTP Screen------------------->
<ng-template #modalOtp let-modal>
   <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="popupInner">
         <h2 class="popuptitle clrblack fontrobotobold mb50">Enter verification code</h2>
         <form [formGroup]="otpForm" (ngSubmit)="validateOtp()">
            <div class="otppanel">
               <p class="parasmall clrblack mb30">We have sent a 4 digit OTP to your mobile number</p>
               <div class="otpformgroup mb30">
                  <div class="otpformInner">
                  <input id="otp1" type="text" class="txtbx pop_txtbx otptxt" appMoveNextByMaxLength required (keypress)="keyPress($event)" minlength="1" maxlength="1" 
                  formControlName="first" [ngClass]="{ 'is-invalid': submitted1 && f1.first.errors }" />
              <input id="otp2" type="text" class="txtbx pop_txtbx otptxt" appMoveNextByMaxLength (keypress)="keyPress($event)" required minlength="1" maxlength="1" 
                  formControlName="second" [ngClass]="{ 'is-invalid': submitted1 && f1.second.errors }" />
              <input id="opt3" type="text" class="txtbx pop_txtbx otptxt" appMoveNextByMaxLength (keypress)="keyPress($event)" required minlength="1" maxlength="1" 
                  formControlName="third" [ngClass]="{ 'is-invalid': submitted1 && f1.third.errors }" />
              <input id="otp4"  type="text" class="txtbx pop_txtbx otptxt"  (keypress)="keyPress($event)" required minlength="1" maxlength="1" 
                  formControlName="fourth" [ngClass]="{ 'is-invalid': submitted1 && f1.fourth.errors }" />
               </div>
               <div *ngIf="submitted1 && f1.first.errors" class="invalid-feedback">
                  <div *ngIf="f1.first.errors.required">
                     Otp is required</div>
                  <div *ngIf="f1.first.errors.pattern || f1.first.errors.maxlength || f1.first.errors.minlength">
                     Otp is not valid</div>
                  <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->

               </div>
               <div *ngIf="submitted1 && f1.second.errors" class="invalid-feedback">
                  <div *ngIf="f1.second.errors.required">
                     Otp is required</div>
                  <div *ngIf="f1.second.errors.pattern || f1.second.errors.maxlength || f1.second.errors.minlength">
                     Otp is not valid</div>
                  <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->

               </div>
               <div *ngIf="submitted1 && f1.third.errors" class="invalid-feedback">
                  <div *ngIf="f1.third.errors.required">
                     Otp is required</div>
                  <div *ngIf="f1.third.errors.pattern || f1.third.errors.maxlength || f1.third.errors.minlength">
                     Otp is not valid</div>
                  <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->

               </div>
               <div *ngIf="submitted1 && f1.fourth.errors" class="invalid-feedback">
                  <div *ngIf="f1.fourth.errors.required">
                     Otp is required</div>
                  <div *ngIf="f1.fourth.errors.pattern || f1.fourth.errors.maxlength || f1.fourth.errors.minlength">
                     Otp is not valid</div>
                  <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->

               </div>
            </div>
               <input type="submit" class="btn booknow popbtn fullwidth" text="Continue" value="Continue" />
            </div>
         </form>
      </div>
   </div>
</ng-template>
<!--------------------------End of OTP Screen------------------->

<!--------------------------Name and Email ID------------------->
<ng-template #contactData let-modal>
   <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="popupInner">
         <h2 class="popuptitle clrblack fontrobotobold mb30">Login</h2>
         <img src="assets/images/home/Login-popup.svg" class="mb30 img-fluid" />
         <form [formGroup]="contactForm" (ngSubmit)="storeContact()">
            <p class="popupsubtitle clrblack fontrobotobold mb30">Enter your Name</p>
            <div class="loginpanel">
               <div class="form-group  mb30">
               <input class="txtbx pop_txtbx " required type="text" placeholder="Enter Your Name"
                  formControlName="customername" [ngClass]="{ 'is-invalid': submitted1 && f2.customername.errors }">
               <div *ngIf="submitted2 && f2.customername.errors" class="invalid-feedback">
                  <div *ngIf="f2.customername.errors.required">Customer Name is required</div>
                  <div *ngIf="f2.customername.errors.pattern ">
                     Enter valid name</div>
                  </div>
               </div>
               <div class="form-group  mb30">
               <input class="txtbx pop_txtbx mb30" required type="text" placeholder="Enter Your Email"
                  formControlName="customeremailid"
                  [ngClass]="{ 'is-invalid': submitted1 && f2.customeremailid.errors }">
               <div *ngIf="submitted2 && f2.customeremailid.errors" class="invalid-feedback">
                  <div *ngIf="f2.customeremailid.errors.required">Customer Email ID is required</div>
                  <div *ngIf="f2.customeremailid.errors.pattern ">
                     Enter valid mail id</div>
               </div>
               </div>
               <input type="submit" class="btn booknow popbtn fullwidth" text="Continue" value="Continue" />
            </div>
         </form>
      </div>
   </div>
</ng-template>
<!-----------------------------End of contact details------------------------>

<!-----------------------------Address Popup------------------------>
<ng-template #addressModal let-modal>
   <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="row no-gutters addrpopInner">
         <div class="col-lg-12 addrgrid addrinfo">
            <form [formGroup]="addressForm" (ngSubmit)="submitAddress()">
               <div class="row  addrForm">
                  <div class="col-lg-6 col-sm-6 form-group">
                     <p class="para">Select Country</p>
                     <select class="addrinput" formControlName="country">
                        <option value="" selected>India</option>
                   </select>
                  </div>
                  <div class="col-lg-6 form-group">
                     <p class="para">PIN Code</p>
                     <input type="text" class="addrinput" required formControlName="pincode" maxlength="6" minlength="6" [ngClass]="{ 'is-invalid': submitted3 && f3.pincode.errors }" (blur)="validatePincode($event)" />
                     <div *ngIf="submitted3 && f3.pincode.errors" class="invalid-feedback" >
                        <div *ngIf="f3.pincode.errors.required">Pincode is required</div>
                        <div
                           *ngIf="f3.pincode.errors.pattern || f3.pincode.errors.maxlength || f3.pincode.errors.minlength">
                           Pincode must be atleast 6 numbers</div>
                     </div>
                  </div>
                  <div class="col-lg-6 form-group">
                     <p class="para">Address Line 1</p>
                     <input type="text" class="addrinput" required formControlName="houseno" placeholder="Flat,House no,Building,Company,Apartment" [ngClass]="{ 'is-invalid': submitted3 && f3.houseno.errors }"/>
                     <div *ngIf="submitted3 && f3.houseno.errors" class="invalid-feedback">
                        <div *ngIf="f3.houseno.errors.required">Flat,House no,Building,Company,Apartment is required</div>
                       
                     </div>
                  </div>
                  <div class="col-lg-6 form-group">
                     <p class="para">Address Line 2</p>
                     <input type="text" class="addrinput" required formControlName="street"  placeholder="Area, Colony, Street, Sector, Village" [ngClass]="{ 'is-invalid': submitted3 && f3.street.errors }"/>
                     <div *ngIf="submitted3 && f3.street.errors" class="invalid-feedback">
                        <div *ngIf="f3.street.errors.required">Colony, Street, Sector is required</div>
                       
                     </div>
                  </div>
                  <div class="col-lg-6 form-group">
                     <p class="para">Area, Village</p>
                     <input type="text" class="addrinput" formControlName="area" />
                  </div>
                  <div class="col-lg-6 form-group">
                     <p class="para">Landmark e.g. near Apollo Hospital</p>
                     <input type="text" class="addrinput" formControlName="landmark"/>
                     
                  </div>  
                  <div class="col-lg-6 form-group">
                     <p class="para">Town/City</p>
                     <!-- <input type="text" class="addrinput" required formControlName="city" [ngClass]="{ 'is-invalid': submitted3 && f3.city.errors }" value="Chennai"/>
                     <div *ngIf="submitted3 && f3.city.errors" class="invalid-feedback">
                        <div *ngIf="f3.city.errors.required">Town/City is required</div>
                     </div> -->
                     <select class="addrinput" formControlName="city" (change)="onChangeCityAdd($event)">
                        <option value="" selected>Select City</option>
                        <option *ngFor="let s of objLocation" [value]="s">{{s.location}}</option>
                   </select>
               <div
                   *ngIf="addressForm.controls['city'].invalid && (submitted3 || addressForm.controls['city'].dirty || addressForm.controls['city'].touched)"
                   class="invalid-feedback">
                   <div *ngIf="addressForm.controls['city'].errors.required">
                     City is required.
                   </div>
                 </div>
              
                  </div>   
  
                  <div class="col-lg-6 col-sm-6 form-group">
                     <p class="para">Select State</p>
                     <select class="addrinput" formControlName="state" (change)="onChangeStateAdd($event)">
                        <option value="" selected>Select City</option>
                        <option *ngFor="let s of objState" [value]="s.state_id">{{s.state}}</option>
                   </select>
                <div
                   *ngIf="addressForm.controls['state'].invalid && (submitted3 || addressForm.controls['state'].dirty || addressForm.controls['state'].touched)"
                   class="invalid-feedback">
                   <div *ngIf="addressForm.controls['state'].errors.required">
                     State is required.
                   </div>
                 </div>
                  </div>                                                        
               </div>
               <div class="save-as-panel">
                  <p class="para mb10">Save as</p>
                  <div class="save_url">
                     <a [ngClass]="{'activeSave':addresstype1}" (click)="storeaddresstype('home')">Home</a>
                     <a  [ngClass]="{'activeSave':addresstype2}" (click)="storeaddresstype('work')">Office</a>
                     <a  [ngClass]="{'activeSave':addresstype3}" (click)="storeaddresstype('others')">Others</a>
                  </div>
                  <div class="gonextpanel">
                     <button class="gonextbtn btn booknow popbtn fullwidth"><span>Next</span>
                        <img src="assets/images/dashboard/continue.png" class="img-fluid" />
                     </button>
                  </div>
               </div>
            </form>
         </div>

      </div>
   </div>
</ng-template>

<!--Address Edit Popup-->
<ng-template #editaddressModal let-modal>
   <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="row no-gutters addrpopInner">
                  <div class=" col-lg-12  addrgrid addrinfo">
            <form [formGroup]="editaddressForm">
              

            

               <div class="row  addrForm">
                  <div class="col-lg-6 form-group">
                     <p class="para">Select Country</p>
                     <select class="addrinput"  formControlName="country" value='India'>
                        <option  selected>India</option>
                     </select>
                     </div>
                  <div class="col-lg-6 form-group">
                     <p class="para">PIN Code</p>
                     <input type="text" class="addrinput" required formControlName="pincode" maxlength="6" minlength="6" [ngClass]="{ 'is-invalid': submitted4 && f5.pincode.errors }" />
                     <div *ngIf="submitted4 && f5.pincode.errors" class="invalid-feedback">
                        <div *ngIf="f5.pincode.errors.required">Pincode is required</div>
                        <div *ngIf="f3.pincode.errors.required">Pincode is required</div>
                        <div
                           *ngIf="f5.pincode.errors.pattern || f5.pincode.errors.maxlength || f5.pincode.errors.minlength">
                           Pincode must be atleast 6 numbers</div>
                     </div>
                  </div>
                  <div class="col-lg-6 form-group">
                     <p class="para">Address Line 1</p>
                     <input placeholder="Flat,House no,Building,Company,Apartment" type="text" class="addrinput" required formControlName="houseno" [ngClass]="{ 'is-invalid': submitted4 && f5.houseno.errors }"/>
                     <div *ngIf="submitted4 && f5.houseno.errors" class="invalid-feedback">
                        <div *ngIf="f5.houseno.errors.required">Flat,House no,Building,Company,Apartment is required</div>
                       
                     </div>
                  </div>
                  <div class="col-lg-6 form-group">
                     <p class="para">Colony, Street, Sector</p>
                     <input type="text" class="addrinput" required formControlName="street" [ngClass]="{ 'is-invalid': submitted4 && f5.street.errors }" />
                     <div *ngIf="submitted4 && f5.street.errors" class="invalid-feedback">
                        <div *ngIf="f5.street.errors.required">Colony, Street, Sector is required</div>
                       
                     </div>
                  </div>
                  <div class="col-lg-6 form-group">
                     <p class="para">Area, Village</p>
                     <input type="text" class="addrinput" formControlName="area" />
                  </div>
                  <div class="col-lg-6 col-sm-6 form-group">
                     <p class="para">Landmark e.g. near Apollo Hospital</p>
                     <input type="text" class="addrinput"  formControlName="landmark" />
                     
                  </div>  
                  <div class="col-lg-6  col-sm-6 form-group">
                     <p class="para">Town/City</p>
                     <!-- <input type="text" class="addrinput" required formControlName="city" [ngClass]="{ 'is-invalid': submitted4 && f5.city.errors }"/>
                     <div *ngIf="submitted4 && f5.city.errors" class="invalid-feedback">
                        <div *ngIf="f5.city.errors.required">Town/City is required</div>                       
                     </div> -->

                     <select class="addrinput" formControlName="city" (change)="onChangeCityEdit($event)">
                        <option value="" selected>Select City</option>
                        <option *ngFor="let s of objLocation" [value]="s.city_id">{{s.location}}</option>
                   </select>
                  </div>   
                  <div class="col-lg-6 col-sm-6 form-group">
                     <p class="para">Select State</p>
                     <select class="addrinput" formControlName="state" (change)="onChangeStateEdit($event)">
                        <option value="" selected>Select City</option>
                        <option *ngFor="let s of objState" [value]="s.state_id">{{s.state}}</option>
                   </select>
                  </div>                                                        
               </div>
               <div class="save-as-panel">
                  <p class="para mb10">Save as</p>
                  <div class="save_url">
                     <a [ngClass]="{'activeSave':addresstype1}" (click)="storeaddresstype('home')">Home</a>
                     <a  [ngClass]="{'activeSave':addresstype2}" (click)="storeaddresstype('work')">Office</a>
                     <a  [ngClass]="{'activeSave':addresstype3}" (click)="storeaddresstype('others')">Others</a>
                  </div>
                  <div class="updatepanel row ">
                  <div class="col-lg-6 col-sm-6 gonextpanel">
                     <button class="gonextbtn btn booknow popbtn fullwidth" (click)="updateAddress(editcustaddress.address_id)"><span>Update</span>
                       
                     </button>
                    
                  </div>
                  <div class="col-lg-6 col-sm-6  gonextpanel">
                     <button class="gonextbtn btn booknow popbtn deletebtn fullwidth" (click)="deleteAddress(editcustaddress.address_id)"><span>Delete</span>
                       
                     </button>
                    
                  </div>
               </div>

               </div>
            </form>
         </div>

      </div>
   </div>
</ng-template>