import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(private titleService:Title,private _location: Location) {
    this.titleService.setTitle("Parryware Bano | Terms and Conditions");
   }
  gotopage(){
    window.scrollTo(0, 0);
  }
  ngOnInit(): void {
  }
  NavigateBack()
  {
    this._location.back();
  }
}
