import { OrderStatusModel, cancelModel, productdetail } from './../order-model';
import { AddressModel } from './../address-model';
import { Component, OnInit } from '@angular/core';
import { CartService } from './../cart.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../api.service';
import { NgxSpinnerService } from "ngx-spinner"
import {Title} from "@angular/platform-browser";

export type customerorder = { customer_id: number, orderid: number }


@Component({
  selector: 'app-bookingconfirmed',
  templateUrl: './bookingconfirmed.component.html',
  styleUrls: ['./bookingconfirmed.component.css']
})
export class BookingconfirmedComponent implements OnInit {


  
  [x: string]: any;
  product: any = [];
  addon: any = [];
  totalamt: number = 0;
  selecteddate: any = {};
  selectedslot: any = {};
  selectday: string = "";
  dateselected: Date = new Date();
  datestring: string = "";
  addselected: AddressModel;
  orderDetail: OrderStatusModel;
  withqueryparams = false;
  cancelForm = new FormGroup({ cancelreason: new FormControl(''), txtReason: new FormControl('') });
  submitted = false;
  couponapply = false;
  DiscountAmt = 0;
    TaxAmt = 0;
  warrantytype:string="";
  private cancelModalRef: NgbModalRef;
  

  constructor(private titleService:Title,private spinner: NgxSpinnerService,private apiservice: ApiService, private modalService: NgbModal, private formBuilder: FormBuilder, private cart: CartService, private datepipe: DatePipe, private route: ActivatedRoute, private router: Router) { 
    this.titleService.setTitle("Parryware Bano | Booking Confirmed");
  }

  ngOnInit(): void {
    this.cancelForm = this.formBuilder.group({
      cancelreason: ['', [Validators.required]],
      txtReason: ['', [Validators.required]]
    });

    this.route.queryParams.subscribe(params => {
      console.log(params);
      if (params !== {}) {
        this.orderDetail = JSON.parse(params.orderDetail);
        this.withqueryparams = true;
      }
    });
    console.log(this.withqueryparams);
    if (this.withqueryparams) {
      window.sessionStorage.setItem("rescheduleflag", "false");
      this.product = this.orderDetail.product_details;
      this.addon = this.orderDetail.addon_details;
      this.cart.items = this.product;
      this.cart.addonitems = this.addon;
      window.sessionStorage.setItem("cart", JSON.stringify(this.cart));

      this.dateselected = new Date(this.orderDetail.appointment_date);
      this.selectedslot = this.orderDetail.appointment_slot;
      console.log(this.selectedslot);
      this.addselected = {
        customer_id: this.orderDetail.customer_id,
        cust_name: this.orderDetail.cust_name,
        housename_no: this.orderDetail.housename_no,
        street_name: this.orderDetail.street_name,
        area: this.orderDetail.area,
        landmark: this.orderDetail.landmark,
        city_id: this.orderDetail.city_id,
        state_id: this.orderDetail.state_id,
        pincode: this.orderDetail.pincode,
        tel_no: this.orderDetail.tel_no,
        email: this.orderDetail.email,
        address_type: ""
      }
      window.sessionStorage.setItem("addselected", JSON.stringify(this.addselected))
    }
    else {

      this.selecteddate = JSON.parse(window.sessionStorage.getItem("selecteddate"));
      this.selectedslot1 = JSON.parse(window.sessionStorage.getItem("selectedtime"));
      this.selectedslot=this.selectedslot1.slot;
console.log(this.selectedslot);
      this.selectday = this.selecteddate.day_of_week;
      this.dateselected = this.selecteddate.date;

      this.cart = JSON.parse(window.sessionStorage.getItem("cart"));
      this.product = this.cart.items;
      this.addon = this.cart.addonitems;

      this.addselected = JSON.parse(window.sessionStorage.getItem("addselected"));
      console.log(this.addselected);

    }

    this.datestring = this.datepipe.transform(this.dateselected, 'MMMM dd, yyyy')
    for (var i = 0; i < this.product.length; i++) {
      if(this.warrantytype==="IN")
      this.product[i].price=0;
      this.totalamt += this.product[i].price;
    }
    if (this.addon !== null) {
      for (var i = 0; i < this.addon.length; i++) {

        this.totalamt += this.addon[i].price;
      }

    }
    if (window.sessionStorage.getItem("couponcode") != null) {
      this.couponapply = true;
      this.DiscountAmt = parseInt(localStorage.getItem("DiscountAmount"));
      this.totalamt -= parseInt(localStorage.getItem("DiscountAmount"));
    }
   
    this.TaxAmt = ((this.totalamt) * 0.18);
      this.totalamt += this.TaxAmt;
      
    if (!this.withqueryparams)
      this.clearCart();
  }

  getCustomerName() {
    return (localStorage.getItem("CustomerName"));
  }


  

  gotopage() {
    window.scrollTo(0, 0);
  }
  Reschedule() {
    console.log(this.orderDetail);
    if (this.orderDetail !== undefined) {
      if (this.orderDetail.in_warranty)
        window.sessionStorage.setItem("warrantytype", "IN");
      else
        window.sessionStorage.setItem("warrantytype", "OUT");
    }
    else
    window.sessionStorage.setItem("warrantytype",this.warrantytype);
    window.sessionStorage.setItem("rescheduleflag", "true");
    if(this.warrantytype==="IN")
    {
      for(var i=0; i<this.product.length; i++)
      {
        this.product[i].price=0;
      }
    }
    this.cart.items=this.product;
    this.cart.addonitems=this.addon;
    window.sessionStorage.setItem("cart", JSON.stringify(this.cart));
    window.sessionStorage.setItem("addselected", JSON.stringify(this.addselected));
    this.router.navigate(["selectservicedate"]);
  }

  CancelSchedule(content: any) {
    window.sessionStorage.setItem("cancelflag", "true");
    this.cancelModalRef = this.modalService.open(content, { windowClass: 'parrymodal ' });
  }

  storeCancel() {
    let reason = '';
    if (this.cancelForm.get("cancelreason").value !== 'Others')
      reason = this.cancelForm.get("cancelreason").value;
    else
      reason = this.cancelForm.get("txtReason").value
    let cancelorder: cancelModel = {
      order_id: parseInt(window.sessionStorage.getItem("confirmedorderid")),
      customer_remarks: reason
    }
    this.apiservice.postcancelorder(cancelorder).subscribe((data: any) => {
     
      this.spinner.show();
     
      if (data.status == "success") {
        this.spinner.hide();
        this.cancelModalRef.close();
        this.router.navigate(["cancelbooking"]);
        this.clearCart();
       
      }
      else {
        this.spinner.hide();
        alert("Error Occured " + data.message);
      }
    })
   
  }
  get f() { return this.cancelForm.controls; }

  logout() {
    window.sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(["home"])
  }

  clearCart() {
    this.warrantytype=window.sessionStorage.getItem("warrantytype");
    this.cart.items = [];
    this.cart.addonitems = [];
    

    window.sessionStorage.removeItem("cart");
    window.sessionStorage.removeItem("warrantytype");
    window.sessionStorage.removeItem("selecteddate");
    window.sessionStorage.removeItem("selectedtime");
    window.sessionStorage.removeItem("addselected");
    localStorage.removeItem("step1");
    localStorage.removeItem("step2");
    localStorage.removeItem("step3");
    localStorage.removeItem("step4");
    localStorage.removeItem("step5");

    window.sessionStorage.removeItem("couponcode");
  }

}
