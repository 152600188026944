import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
import {Location} from '@angular/common';

@Component({
  selector: 'app-warrantypolicy',
  templateUrl: './warrantypolicy.component.html',
  styleUrls: ['./warrantypolicy.component.css']
})
export class WarrantypolicyComponent implements OnInit {

  constructor(private titleService:Title,private _location: Location) { 
    this.titleService.setTitle("Parryware Bano | Warranty Policy");
  }
  gotopage(){
    window.scrollTo(0, 0);
  }
  ngOnInit(): void {
  }
  NavigateBack()
  {
    this._location.back();
  }
}
