<div class="Top_spacing">
   <div class="topHeader" [hidden]="isShow">
      <div class="container">
         <p>
            Enjoy your first booking with us, New users will get a discount of Flat Rs.100 on service booking.
            <span>Promo Code <span
                  style="margin:0 0 0 5px;background: #fff;color: #13CCFF;padding: 4px 10px;border-radius: 30px;">Newuser</span></span>
         </p>
         <button type="button" aria-label="Close" (click)="closeheader()" class="close" id="closePromo"><span
               aria-hidden="true">×</span></button>
      </div>
   </div>
   <div class="headerTop">
      <div class="container">

         <div class="headerTopInner">
            <div class="mlist warrantylogo">
               <p>Register for</p>
               <a href='http://newcrm.parrywareccc.com/parryware-WarrantyPortal.aspx' target="_blank">
                  <img src="assets/images/home/warrantylogo.gif" class="img-fluid" />
                  <span>Warranty</span>
               </a>
            </div>
            <div class="mlist">
               <p>Parryware Bano</p>
               <a (click)="opensidebar()">
                  <img src="assets/images/safety/covidicon.svg" class="img-fluid" />
                  <span>Covid Measures</span>
               </a>
            </div>
            <div class="mlist LocationClick">
               <img src="assets/images/home/location.svg" class="img-fluid" />
               <p>Choose Your Location</p>
               <select class="sltlocation" [formControl]="selectlocation" (change)="calllocation()">
                  <option value="Chennai">Chennai</option>
                  <option value="Coimbatore">Coimbatore</option>
                  <option value="Bangalore">Bangalore</option>
                  <option value="Other Locations">Other Locations</option>
               </select>

            </div>
         </div>

      </div>
   </div>
   <header class="HeaderPanel">
      <div class="container" data-ng-init="checkloggedin()">
         <div class="HeaderInner">
            <div class="logo">
               <a class="nav-brand" routerLink="/home" routerLinkActive="active">
                  <img src="assets/images/home/Bano_logo_white.svg" class="img-fluid" />
               </a>
            </div>
            <div class="logolist">

            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#parrywareHeaderMenu"
               aria-controls="parrywareHeaderMenu" aria-expanded="false" aria-label="Toggle navigation">
               <span class="icon-bar top-bar"></span>
               <span class="icon-bar middle-bar"></span>
               <span class="icon-bar bottom-bar"></span>
               <span class="sr-only">Toggle navigation</span>
            </button>
            <div class="menuPanel" id="parrywareHeaderMenu">
               <div class="mlistpanel ">
                  <div class="mlist warrantylogo d-none d-lg-block d-sm-block">
                     <p>Register for</p>
                     <a href='http://newcrm.parrywareccc.com/parryware-WarrantyPortal.aspx' target="_blank">
                        <img src="assets/images/home/warrantylogo.gif" class="img-fluid" />
                        <span>Warranty</span>
                     </a>
                  </div>
                  <div class="headermenuline"></div>
                  <div class="mlist d-none d-lg-block d-sm-block">
                     <p>Parryware Bano</p>
                     <a (click)="opensidebar()">
                        <img src="assets/images/safety/covidicon.svg" class="img-fluid" />
                        <span>Covid Measures</span>
                     </a>
                  </div>
                  <div class="headermenuline"></div>
                  <div class="mlist d-none d-lg-block d-sm-block sltdroppanel">
                     <p>Choose Your Location</p>
                     <select class="sltlocation" [formControl]="selectlocation" (change)="calllocation()">
                        <option value="Chennai">Chennai</option>
                        <option value="Coimbatore">Coimbatore</option>
                        <option value="Bangalore">Bangalore</option>
                        <option value="Other Locations">Other Locations</option>
                     </select>
                  </div>
                  <div class="headermenuline"></div>
                  <div class="mlist dropdown">
                     <div class="dropdown-toggle" ngbDropdown #menu="ngbDropdown">
                        <p *ngIf="!loggedin">Login/Sign up</p>
                        <a (click)="triggerModal(modalData)" *ngIf="!loggedin" class="loginClass">
                           <img src="assets/images/home/login.svg" class="img-fluid" />
                           <span>My Activity</span>
                        </a>
                        <button class="btn btn-outline-primary mr-2" id="dropdownManual" ngbDropdownAnchor
                           (focus)="menu.open()" *ngIf="loggedin">
                           <p *ngIf="loggedin" class="cust-name">Welcome {{getCustomerName()}}</p>
                           <img src="assets/images/home/login.svg" class="img-fluid" />
                           <span>My Activity</span>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownManual">
                           <button ngbDropdownItem><span><img src="assets/images/home/drp-icon-2.png"
                                    class="img-fluid" /></span><a routerLink="/mydashboard" routerLinkActive="active">My
                                 Booking</a></button>
                           <button ngbDropdownItem (click)="logout()"><span><img src="assets/images/home/drp-icon-3.png"
                                    class="img-fluid" /></span>Logout</button>
                        </div>
                     </div>
                     <div class="dropdown-menu">
                        <a class="dropdown-item" href="#"></a>
                        <a class="dropdown-item" href="#"></a>
                     </div>
                  </div>
               </div>
               <div class="booknow">
                  <button class="btn parrybtn hr_booknow" (click)="openModal('outwarranty',true)"><span>Book
                        Now</span></button>
               </div>
            </div>
         </div>
      </div>

   </header>
</div>
<!--Safety Panel------------------>
<div class="safety-sidebar-panel" [hidden]="isShowcovid">
   <div class="safetyoverlay"></div>
   <div class="safety-sidebar">
      <div class="sf-info para fontrobotomedium">
         <a style="cursor: pointer;" (click)="closesidebar()"><img src="assets/images/dashboard/back-arrow.png"
               class="img-fluid" /> </a>
         <span>Parryware Bano Covid Measures</span>
      </div>
      <img src="assets/images/safety/covid-img.png" class="img-fluid" />
      <div class="cvcontext">
         <h2 class="cv-title fontrobotomedium mb15">
            The Parryware Bano Safety Standard
         </h2>
         <p class="para mb15" style="text-align: justify;">
            At Parryware Bano, we prioritize the safety of our customers and service professionals. In the wake of the
            COVID-19 pandemic, we have ramped up our commitment towards ensuring a hygienic and safe at- home service
            experience.
         </p>
         <p class="safetyText">Your Safety is our priority</p>
         <ul class="cvlist">
            <li>
               <p><img src="assets/images/safety/covid-icon-2.png" class="img-fluid" /></p>
               <span>Masks & gloves compulsory</span>
            </li>
            <li>
               <p><img src="assets/images/safety/covid-icon-1.png" class="img-fluid" /></p>
               <span>Mandatory temperature check</span>
            </li>
            <li>
               <p> <img src="assets/images/safety/covid-icon-3.png" class="img-fluid" /></p>
               <span>Work area and tools sanitization</span>
            </li>
         </ul>
      </div>
   </div>
</div>
<!-------------------------------Banner------------------>

<!-------------------------------Banner------------------>
<section class="homebannerpanel position-relative">
   <div class="bgs homebg"></div>
   <div class="homebgmobile">
      <div class="banText">
         <div class="container">
            <div class="banTxtInner homebannerInner">
               <h1 class="h1title fontrobotoblack clrwhite blueoverlay">
                  Bathroom Services <br /> On Demand<br>
               </h1>
            </div>
         </div>
      </div>
   </div>
   <div class="container">
      <div class="pickServices">
         <p class="clrblue paralarge mb20">
            <a pageScroll href="#howitworks">Pick from one of our services <span style="color:#fff;">(How it
                  works?)</span></a>
         </p>
         <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav nav-tabs picktabStyle" id="pickserviceTab">
            <li [ngbNavItem]="1">
               <span
                  [attr.title]="warrantytype==='OUT' ? 'You have already chosen Out Warranty Services.  Hence for In Warranty Services please book another service.':''"><a
                     class="nav-item nav-link" id="nav-repairs-tab" [ngClass]="warrantytype==='OUT' ? 'disabled' : null"
                     ngbNavLink>IN Warranty Services</a></span>
               <ng-template ngbNavContent>
                  <div class="row servicesrow no-gutters servicegridCarousel slickcarouelstyle">
                     <div class="col-auto servicegrid">
                        <div class="pick_panel">
                           <a (click)="openModal('inwarranty',true)">
                              <img src="assets/images/home/Sanitaryware.png" class="img-fluid" />
                              <p>
                                 Sanitaryware
                              </p>
                           </a>
                        </div>
                     </div>
                     <div class="col-auto servicegrid">
                        <div class="pick_panel">
                           <a (click)="openModal('inwarranty',true)">
                              <img src="assets/images/home/Flushing.png" class="img-fluid" />
                              <p>
                                 Flushing Systems
                              </p>
                           </a>
                        </div>
                     </div>
                     <div class="col-auto servicegrid">
                        <div class="pick_panel">
                           <a (click)="openModal('inwarranty',true)">
                              <img src="assets/images/home/taps.png" class="img-fluid" />
                              <p>
                                 Taps & Fittings
                              </p>
                           </a>
                        </div>
                     </div>
                     <div class="col-auto servicegrid">
                        <div class="pick_panel">
                           <a (click)="openModal('inwarranty',true)">
                              <img src="assets/images/home/Wellness.png" class="img-fluid" />
                              <p>
                                 Wellness
                              </p>
                           </a>
                        </div>
                     </div>
                     <div class="col-auto servicegrid">
                        <div class="pick_panel">
                           <a (click)="openModal('inwarranty',true)">
                              <img src="assets/images/home/Geycer.png" class="img-fluid" />
                              <p>
                                 Geyser
                              </p>
                           </a>
                        </div>
                     </div>
                  </div>
               </ng-template>
            </li>
            <li [ngbNavItem]="2">
               <span
                  [attr.title]="warrantytype==='IN' ? 'You have already chosen In Warranty Services.  Hence for Out Warranty Services please book another service.':''"><a
                     class="nav-item nav-link" id="nav-repairs-tab" [ngClass]="warrantytype==='IN' ? 'disabled' : null"
                     ngbNavLink>OUT Of Warranty Services</a></span>
               <ng-template ngbNavContent>
                  <div class="row servicesrow no-gutters servicegridCarousel slickcarouelstyle">
                     <div class="col-auto servicegrid">
                        <div class="pick_panel">
                           <a (click)="openModal('outwarranty',true)">
                              <img src="assets/images/home/Sanitaryware.png" class="img-fluid" />
                              <p>
                                 Sanitaryware
                              </p>
                           </a>
                        </div>
                     </div>
                     <div class="col-auto servicegrid">
                        <div class="pick_panel">
                           <a (click)="openModal('outwarranty',true)">
                              <img src="assets/images/home/Flushing.png" class="img-fluid" />
                              <p>
                                 Flushing Systems
                              </p>
                           </a>
                        </div>
                     </div>
                     <div class="col-auto servicegrid">
                        <div class="pick_panel">
                           <a (click)="openModal('outwarranty',true)">
                              <img src="assets/images/home/taps.png" class="img-fluid" />
                              <p>
                                 Taps & Fittings
                              </p>
                           </a>
                        </div>
                     </div>
                     <div class="col-auto servicegrid">
                        <div class="pick_panel">
                           <a (click)="openModal('outwarranty',true)">
                              <img src="assets/images/home/Wellness.png" class="img-fluid" />
                              <p>
                                 Wellness
                              </p>
                           </a>
                        </div>
                     </div>
                     <div class="col-auto servicegrid">
                        <div class="pick_panel">
                           <a (click)="openModal('outwarranty',true)">
                              <img src="assets/images/home/Geycer.png" class="img-fluid" />
                              <p>
                                 Geyser
                              </p>
                           </a>
                        </div>
                     </div>
                  </div>
               </ng-template>
            </li>
            <li [ngbNavItem]="3">
               <a ngbNavLink>Installation</a>
               <ng-template ngbNavContent>
                  <div class="row servicesrow no-gutters servicegridCarousel   slickcarouelstyle">
                     <div class="col-auto servicegrid">
                        <div class="pick_panel">
                           <a (click)="openModal('outwarranty',true)">
                              <img src="assets/images/home/Sanitaryware.png" class="img-fluid" />
                              <p>
                                 Sanitaryware
                              </p>
                           </a>
                        </div>
                     </div>
                     <div class="col-auto servicegrid">
                        <div class="pick_panel">
                           <a (click)="openModal('outwarranty',true)">
                              <img src="assets/images/home/Flushing.png" class="img-fluid" />
                              <p>
                                 Flushing Systems
                              </p>
                           </a>
                        </div>
                     </div>
                     <div class="col-auto servicegrid">
                        <div class="pick_panel">
                           <a (click)="openModal('outwarranty',true)">
                              <img src="assets/images/home/taps.png" class="img-fluid" />
                              <p>
                                 Taps & Fittings
                              </p>
                           </a>
                        </div>
                     </div>
                     <div class="col-auto servicegrid">
                        <div class="pick_panel">
                           <a (click)="openModal('outwarranty',true)">
                              <img src="assets/images/home/Wellness.png" class="img-fluid" />
                              <p>
                                 Wellness
                              </p>
                           </a>
                        </div>
                     </div>
                     <div class="col-auto servicegrid">
                        <div class="pick_panel">
                           <a (click)="openModal('outwarranty',true)">
                              <img src="assets/images/home/Geycer.png" class="img-fluid" />
                              <p>
                                 Geyser
                              </p>
                           </a>
                        </div>
                     </div>
                  </div>
               </ng-template>
            </li>
            <li [ngbNavItem]="4">
               <a class="nav-item nav-link" id="nav-instantbooking-tab" ngbNavLink>Diagnostics Service</a>
               <ng-template ngbNavContent>
                  <div class="row instarow instabookingrow no-gutters">
                     <div class="col-lg-8 instagrid instaleft">
                        <p class="paramedium fontrobotobold mb20">
                           Looking for Something else?
                        </p>
                        <p class="paralarge fontrobotolight">
                           Professionals will visit your home and diagnose the issue. Final cost to be
                           provided
                           after the check up.
                        </p>
                     </div>
                     <div class="col-lg-4 instagrid instaright">
                        <p>
                           Base charge of <span>&#8377;99</span>
                        </p>
                        <button class="btn parrybtn booknow onhoverwhite" (click)="addItem()">
                           <span>Book Now</span>
                        </button>
                     </div>
                  </div>
               </ng-template>
            </li>
            <!--value added services-->
            <li [ngbNavItem]="5">
               <a ngbNavLink>Value added services</a>
               <ng-template ngbNavContent>
                  <div class="row servicesrow no-gutters servicegridCarousel slickcarouelstyle ValueaddedPanel">
                     <div class="col-auto servicegrid">
                        <div class="pick_panel">
                           <a routerLink="/combodeals">
                              <img src="assets/images/home/newimages/combo.jpg" class="img-fluid" />
                              <p>
                                 Upgrade your bathroom
                              </p>
                           </a>
                        </div>
                     </div>
                     <div class="col-auto servicegrid">
                        <div class="pick_panel">
                           <a routerLink="/renovation">
                              <img src="assets/images/home/newimages/renovation.jpg" class="img-fluid" />
                              <p>
                                 Renovation
                              </p>
                           </a>
                        </div>
                     </div>
                     <div class="col-auto servicegrid" style="display: none;">
                        <div class="pick_panel">
                           <a routerLink="/wedesignyouexecute">
                              <img src="assets/images/home/newimages/we-design.jpg" class="img-fluid" />
                              <p>
                                 We design, you execute
                              </p>
                           </a>
                        </div>
                     </div>
                     <div class="col-auto servicegrid" style="display: none;">
                        <div class="pick_panel">
                           <a routerLink="/amc">
                              <img src="assets/images/home/newimages/amc-banner.jpg" class="img-fluid" />
                              <p>
                                 AMC
                              </p>
                           </a>
                        </div>
                     </div>
                     <div class="col-auto servicegrid">
                        <div class="pick_panel">
                           <!--  <a routerLink="/deepcleaningservice"> -->
                           <img src="assets/images/deepcleaning/Deep-Cleaning-banner.jpg" class="img-fluid" />
                           <p>
                              Deep Cleaning
                           </p>
                           <!--  </a> -->
                        </div>
                     </div>
                  </div>
               </ng-template>
            </li>

            <!-- <div class="col-auto servicegrid">
                            <div class="pick_panel">
                               <a routerLink="/deepcleaningservice">
                                  <img src="assets/images/home/Deep-Cleaning.jpeg" class="img-fluid" />
                                  <p>
                                     Deep Cleaning Services
                                  </p>
                               </a>
                            </div>
                         </div>-->

         </ul>
         <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
   </div>
</section>
<!-----------------------------homelist------------------->
<section class="homelistpanel">
   <div class="container">
      <div class="row hmrow">
         <div class="col-lg-3 hmgrid">
            <div class="hminn">
               <img src="assets/images/home/icon-1.svg" class="mb30  img-fluid" />
               <p class="paralarge clrwhite">100+ Million Happy Customers </p>
            </div>
         </div>
         <div class="col-lg-3 hmgrid">
            <div class="hminn">
               <img src="assets/images/home/icon-2.svg" class="mb30  img-fluid" />
               <p class="paralarge clrwhite">Certified Plumbing Experts </p>
            </div>
         </div>
         <div class="col-lg-3 hmgrid">
            <div class="hminn">
               <img src="assets/images/home/icon-3.svg" class="mb30  img-fluid" />
               <p class="paralarge clrwhite">90 Days Warranty <br> for Services </p>
            </div>
         </div>
         <div class="col-lg-3 hmgrid">
            <div class="hminn">
               <img src="assets/images/home/icon-4.svg" class="mb30  img-fluid" />
               <p class="paralarge clrwhite">Transparent Pricing</p>
            </div>
         </div>
      </div>
   </div>
</section>
<!--------------------------how it works------------------->
<section class="hwpanel" id="howitworks">
   <div class="container">
      <div class="row hwrow no-gutters">
         <div class="col-lg-6 hwleft">
            <p class="paralarge clrwhite fontrobotobold mb30">How it works</p>
            <ngx-slick-carousel class="howitworksCarousel slickcarouelstyle carousel" #slickModal="slick-carousel"
               [config]="hiwslideConfig">
               <div ngxSlickItem *ngFor="let hiwslide of hiwslides" class="slide">
                  <div>
                     <h2 class="h2subtitle fontrobotobold clrwhite mb30">
                        <span>{{hiwslide.count}}</span>
                        {{hiwslide.maintext}}
                     </h2>
                     <p class="paralarge clrgrey">
                        {{hiwslide.desc}}
                     </p>
                  </div>
               </div>
            </ngx-slick-carousel>
            <div class="booknow">
               <button class="btn parrybtn booknow onhoverwhite" (click)="openModal('outwarranty',true)"><span>Book
                     Now</span></button>
            </div>
         </div>
         <div class="col-lg-6 hwright">
            <img src="assets/images/home/pick-you-service.svg" class="img-fluid" />
         </div>
      </div>
   </div>
</section>
<!---------------------------Services Panel---------------->
<section class="ServicesPanel">
   <div class="container">
      <div class="ServicesInner">
         <h2 class="h2subtitle clrwhite fontrobotobold mb50">Our Most-chosen Services</h2>
         <ngx-slick-carousel class="ServiceSlickCarousel slickcarouelstyle  carousel" #slickModal="slick-carousel"
            [config]="slideConfig">
            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
               <div>
                  <div class="ss-blog">
                     <div class="blogimg">
                        <img src="{{ slide.img }}" class="img-fluid" />
                     </div>
                     <div class="blogcont">
                        <p>{{ slide.content }}</p>
                        <div class="gotoproduct">
                           <span>&#8377; {{ slide.price }}</span>
                           <div class="cartItemDetails ">
                              <button class="addproductbtn btnclick"
                                 (click)="addItemsToCart(slide.productid,slide.catid,slide.price,slide.pdtname,slide.content)"><span
                                    _ngcontent-ueh-c102="">Add</span></button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </ngx-slick-carousel>
      </div>
   </div>
</section>
<!--------------------------Guarantee Panel----------------->
<section class="gupanel position-relative">
   <div class="bgs gubg">
      <div class="gucontext pos-abs">
         <div class="container">
            <img src="assets/images/home/satisfaction.png" class="img-fluid guimg mb50" />
            <h2 class="h2title clrwhite fontrobotobold mb30">
               Your Happiness, Guaranteed
            </h2>
            <p class="paralarge clrgrey">
               Your happiness is our goal. If you’re not happy, we’ll work to make it right. Our friendly customer
               service agents are available from 8 am to 8 pm (Monday - Saturday). The Parryware Bano Happiness
               Guarantee only
               applies when you book and pay for a service directly through the Parryware Bano platform.
            </p>
         </div>
      </div>
   </div>
</section>
<!----------------------------FAQ---------------------------->
<section class="faqpanel" id="faq">
   <div class="container">
      <div class="faqInner">
         <h2 class="h2title text-center clrwhite fontrobotobold mb50">FAQs</h2>
         <ul ngbNav #navfaq="ngbNav" [(activeId)]="activetab" class="nav nav-tabs TabhomeStyle" id="faqTab">
            <li [ngbNavItem]="1">
               <a class="nav-item nav-link" id="nav-general-tab" ngbNavLink>General</a>
               <div class="tabline"></div>
               <ng-template ngbNavContent>
                  <ngb-accordion [closeOthers]="true" activeIds="static-1" class="faqaccordion accstyle">
                     <ngb-panel id="static-1" title="How many cities does Parryware Bano serve currently?">
                        <ng-template ngbPanelContent>
                           We currently serve Chennai, and have plans to expand to other cities soon.
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="static-2" title="Are the service providers qualified?">
                        <ng-template ngbPanelContent>
                           Parryware Bano ensures to employ technicians who are professionals and experts in the field.
                           The technicians mandatorily go through interviews, referral checks, and training before
                           coming on board. The plumbers undergo training by Parryware to work on Parryware products.
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="static-3"
                        title="What should I do if the Service Provider damages something while at work?">
                        <ng-template ngbPanelContent>
                           It is very unlikely, but you can email us or give us a call and we shall sort it
                           out.
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="static-4" title="Does someone need to be present at home during the service?">
                        <ng-template ngbPanelContent>
                           We advise you to be present when the service is rendered. We also request you to
                           take care of your belongings during the service. We do not take liability for any
                           damage or theft arising in case of failure to do so.
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="static-5" title="What happens if the Service Provider is not on time?">
                        <ng-template ngbPanelContent>
                           In case there is a delay, we will help you and assign another technician, or arrange
                           for a replacement at your convenience.
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="static-6"
                        title="What happens in case the requested job takes more time than expected?">
                        <ng-template ngbPanelContent>
                           We always place our customers first. Therefore, we do not want to waste your
                           precious time. However, we suggest having a half an hour cushion before and after
                           the service slot.
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="static-7" title="How do I reschedule a service booked?">
                        <ng-template ngbPanelContent>
                           You can reschedule the service easily through the app.
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="static-8"
                        title="Will the service provider bring his own supplies or do I need to provide?">
                        <ng-template ngbPanelContent>
                           The service provider would purchase the supplies needed for the service after having
                           a look at the issue. You will be provided with a bill for the purchases made to
                           complete the service.
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="static-9" title="Is it mandatory to register on the site?">
                        <ng-template ngbPanelContent>
                           We recommend you to register with us to enjoy best customer experience. If you are
                           concerned about receiving spam messages and emails from us, we are strictly against
                           that practice.
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="static-10" title="Can I rate the service providers?">
                        <ng-template ngbPanelContent>
                           Yes, after the service you will have access to rate the service and service provider
                           on various parameters relevant to the service. You can also rate the overall
                           satisfaction and add any specific points.
                        </ng-template>
                     </ngb-panel>
                  </ngb-accordion>
               </ng-template>
            </li>
            <li [ngbNavItem]="2">
               <a class="nav-item nav-link" id="nav-general-tab" ngbNavLink>Payment</a>
               <div class="tabline"></div>
               <ng-template ngbNavContent>
                  <ngb-accordion [closeOthers]="true" activeIds="Pay-1" class="faqaccordion accstyle">
                     <ngb-panel id="Pay-1" title="What are the modes of payment?">
                        <ng-template ngbPanelContent>
                           You can pay through credit card, debit card, UPI, or net banking.
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="Pay-2" title="Can I not pay by cash?">
                        <ng-template ngbPanelContent>
                           We recommend you to pay through credit card, debit card, UPI, or net banking.
                           However, you can pay the standard charges through the mentioned channels, and pay
                           any variable charges after the service in cash.
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="Pay-3" title="How safe is my card information with you?">
                        <ng-template ngbPanelContent>
                           Your information is encrypted and secure with us. We are associated with
                           experienced, reliable, and certified partners to manage personal and confidential
                           information of customers. Hence, you need not worry about the details you share with
                           us.
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="Pay-4" title="Can I pay online after a service?">
                        <ng-template ngbPanelContent>
                           Yes, you can pay online after a service.
                        </ng-template>
                     </ngb-panel>
                  </ngb-accordion>
               </ng-template>
            </li>
            <li [ngbNavItem]="3">
               <a class="nav-item nav-link" id="nav-booking-tab" ngbNavLink>Booking Related</a>
               <div class="tabline"></div>
               <ng-template ngbNavContent>
                  <ngb-accordion [closeOthers]="true" activeIds="booking-1" class="faqaccordion accstyle">
                     <ngb-panel id="booking-1" title="How do I book a service?">
                        <ng-template ngbPanelContent>
                           On the app, pick the specific service you need from the plethora of services we
                           offer.
                           <ul>
                              <li>Pick the product you need service with from our range of products.</li>
                              <li>Pick the date and time of service that is convenient to you.</li>
                              <li>Chill and relax, while our experts arrive at your place to provide you
                                 top-notch services!
                              </li>
                           </ul>
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="booking-2" title="How do I reschedule a job booked on Parryware Bano?">
                        <ng-template ngbPanelContent>
                           You can reschedule the service easily through the app.
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="booking-3" title="How do I cancel a job booked on Parryware Bano?">
                        <ng-template ngbPanelContent>
                           You can cancel the service easily through the app.
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="booking-4" title="How can I view all my orders?">
                        <ng-template ngbPanelContent>
                           You can view your orders on the ‘My orders’ screen in our app.
                        </ng-template>
                     </ngb-panel>
                  </ngb-accordion>
               </ng-template>
            </li>
            <li [ngbNavItem]="4">
               <a class="nav-item nav-link" id="nav-plumbing-tab" ngbNavLink>Plumbing</a>
               <div class="tabline"></div>
               <ng-template ngbNavContent>
                  <ngb-accordion [closeOthers]="true" activeIds="plumbing-2" class="faqaccordion accstyle">
                     <ngb-panel id="plumbing-2" title="Is it safe for me to allow your Plumber into my premises?">
                        <ng-template ngbPanelContent>
                           The technicians mandatorily go through interviews, referral checks, and training before
                           coming on board to serve the customers.
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="plumbing-3" title="Can I contact my plumber after booking is confirmed?">
                        <ng-template ngbPanelContent>
                           You will receive emails and messages once your booking has been confirmed. You will know the
                           plumber’s name and contact number. You can then contact your plumber regarding any questions
                           or concerns you might have.
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="plumbing-4" title="What are your work timings?">
                        <ng-template ngbPanelContent>
                           We work Monday to Saturday from 9 AM to 7 PM.
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="plumbing-5" title="Do you offer 'before 9 AM' and 'post 7 PM' services?">
                        <ng-template ngbPanelContent>
                           Yes, this would come under Parryware Bano Premium Services.
                        </ng-template>
                     </ngb-panel>
                  </ngb-accordion>
               </ng-template>
            </li>
            <li [ngbNavItem]="5">
               <a class="nav-item nav-link" id="nav-app-tab" ngbNavLink>APP</a>

               <ng-template ngbNavContent>
                  <ngb-accordion [closeOthers]="true" activeIds="App-1" class="faqaccordion accstyle">
                     <ngb-panel id="App-1" title="How do I book a service on the App?">
                        <ng-template ngbPanelContent>
                           On the app, pick the specific service you need from the plethora of services we
                           offer.
                           <ul>
                              <li>Pick the product you need service with from our range of products.</li>
                              <li>Pick the date and time of service that is convenient to you.</li>
                              <li>Chill and relax, while our experts arrive at your place to provide you
                                 top-notch services!
                              </li>
                           </ul>
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="App-2" title="How can I download the App?">
                        <ng-template ngbPanelContent>
                           You can download the app from Android and iOS store.
                        </ng-template>
                     </ngb-panel>
                     <ngb-panel id="App-3" title="What are the benefits of using the app?">
                        <ng-template ngbPanelContent>
                           The app is extremely user-friendly and has great features for the best customer
                           experience. We also provide offers and discounts regularly for our customers who use
                           the app.
                        </ng-template>
                     </ngb-panel>

                  </ngb-accordion>
               </ng-template>
            </li>
         </ul>
         <div [ngbNavOutlet]="navfaq" class="mt-2"></div>
      </div>
   </div>
</section>
<!----------------------------Booking Professionals----------->
<section class="bookingPanel">
   <div class="container">
      <div class="row booking_inner no-gutters">
         <div class="col-lg-6 bkgrid">
            <img src="assets/images/home/mobile-new.png" class="img-fluid" />
         </div>
         <div class="col-lg-6 bkformgrid">
            <h2 class="h3title clrblak fontrobotobold mb20">
               Book Professionals from Your Phone
            </h2>
            <p class="paralarge clrblack mb30">
               Download the Parryware BANO app now!
            </p>
            <!--<div class="form-booking position-relative mb30">
                   <input type="text" class="txtstyle txtbx" />
                   <button type="submit" class="btn submitbtn"><span>Submit</span></button>
                </div>-->
            <div class="access">
               <a href="https://play.google.com/store/apps/details?id=com.parrywarebano.android" target="_blank"><img
                     src="assets/images/home/google.png" class="img-fluid" /></a>
               <a href="https://apps.apple.com/in/app/parryware-bano/id1610479184" target="_blank"><img
                     src="assets/images/home/apple.png" class="img-fluid" /></a>
            </div>
         </div>
      </div>
   </div>
</section>
<!-------------------------------Footer Panel------------------>
<section class="Footerpanel">
   <div class="footerTop">
      <div class="container">
         <div class="fTopInner text-center">
            <a routerLink="/home" routerLinkActive="active"><img src="assets/images/home/logo.png"
                  class="img-fluid" /></a>
            <p class="para">
               Parryware India is a leading manufacturer of bathroom products originated from EID Parry, one of the
               country's oldest and most reputed business entities. In 2006, it entered into a joint venture with
               Roca Corporation, Spain, to become a Roca Group subsidiary in 2008, and turned into a wholly-owned
               subsidiary of the Roca Group in 2011.
            </p>
         </div>
      </div>
   </div>
   <div class="footerBtm ">
      <div class="container">
         <div class="row fbtmRow no-gutters">
            <div class="col-lg-8    fbtmenus">
               <ul class="nav menu_footer">
                  <li><a routerLink="/aboutus" routerLinkActive="active" (click)="gotopage()">About us</a></li>
                  <li class="line"></li>
                  <li><a routerLink="/contact" routerLinkActive="active" (click)="gotopage()">Contact us</a></li>
                  <li class="line"></li>
                  <li><a pageScroll href="#faq" (click)="gotopage()">FAQ</a></li>
                  <li class="line"></li>
                  <li><a routerLink="/warrantypolicy" routerLinkActive="active" (click)="gotopage()">Warranty Policy</a>
                  </li>
                  <li class="line"></li>
                  <li><a routerLink="/privacypolicy" routerLinkActive="active" (click)="gotopage()">Privacy Policy</a>
                  </li>
                  <li class="line"></li>
                  <li><a routerLink="/terms" routerLinkActive="active" (click)="gotopage()">Terms of Use</a></li>
               </ul>
            </div>
            <div class="col-lg-4  social-grid ">
               <p class="parasmall">Follow us on:</p>
               <ul class="social-menu">
                  <li><a href="https://www.facebook.com/parrywarebano/" target="_blank"><img
                           src="assets/images/facebook.svg" class="img-fluid" /></a></li>
                  <li><a><img src="assets/images/instagram.svg" class="img-fluid" /></a></li>
               </ul>
            </div>
         </div>
         <!--coprights-->
         <div class="copyright">
            <p class="parasmall clrwhite text-center">© 2021 Parryware | All Rights Reserved</p>
         </div>
      </div>
   </div>

</section>


<!-----------------------------Login Popup------------------------>
<ng-template #modalData let-modal>
   <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="popupInner">
         <h2 class="popuptitle clrblack fontrobotobold mb30">Login</h2>
         <img src="assets/images/home/Login-popup.svg" class="mb30 img-fluid" />
         <form [formGroup]="registerForm" (ngSubmit)="getotp(modalOtp)">
            <p class="popupsubtitle clrblack fontrobotobold mb30">Enter your mobile number</p>
            <div class="loginpanel">
               <div class="form-group mb30">
                  <input class="txtbx pop_txtbx " (keypress)="keyPress($event)" required type="text"
                     placeholder="Enter your mobile number" formControlName="phonenumber"
                     [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }">
                  <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                     <div *ngIf="f.phonenumber.errors.required">Phone number is required</div>
                     <div
                        *ngIf="f.phonenumber.errors.pattern || f.phonenumber.errors.maxlength || f.phonenumber.errors.minlength">
                        Phone number must be at least 10 numbers</div>
                  </div>
               </div>
               <input type="submit" class="btn booknow popbtn fullwidth" text="Continue" value="Continue" />
            </div>
         </form>
      </div>
   </div>

</ng-template>
<!--------------------------OTP Screen------------------->
<ng-template #modalOtp let-modal>
   <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="popupInner">
         <h2 class="popuptitle clrblack fontrobotobold mb50">Enter verification code</h2>
         <form [formGroup]="otpForm" (ngSubmit)="validateOtp()">
            <div class="otppanel">
               <p class="parasmall clrblack mb30">We have sent a 4 digit OTP to your mobile number</p>
               <div class="otpformgroup mb30">
                  <div class="otpformInner">
                     <input id="otp1" type="text" class="txtbx pop_txtbx otptxt" appMoveNextByMaxLength required
                        (keypress)="keyPress($event)" minlength="1" maxlength="1" formControlName="first"
                        [ngClass]="{ 'is-invalid': submitted1 && f1.first.errors }" />
                     <input id="otp2" type="text" class="txtbx pop_txtbx otptxt" appMoveNextByMaxLength
                        (keypress)="keyPress($event)" required minlength="1" maxlength="1" formControlName="second"
                        [ngClass]="{ 'is-invalid': submitted1 && f1.second.errors }" />
                     <input id="opt3" type="text" class="txtbx pop_txtbx otptxt" appMoveNextByMaxLength
                        (keypress)="keyPress($event)" required minlength="1" maxlength="1" formControlName="third"
                        [ngClass]="{ 'is-invalid': submitted1 && f1.third.errors }" />
                     <input id="otp4" type="text" class="txtbx pop_txtbx otptxt" (keypress)="keyPress($event)" required
                        minlength="1" maxlength="1" formControlName="fourth"
                        [ngClass]="{ 'is-invalid': submitted1 && f1.fourth.errors }" />
                  </div>
                  <div *ngIf="submitted1 && f1.first.errors" class="invalid-feedback">
                     <div *ngIf="f1.first.errors.required">
                        Otp is required</div>
                     <div *ngIf="f1.first.errors.pattern || f1.first.errors.maxlength || f1.first.errors.minlength">
                        Otp is not valid</div>
                     <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->

                  </div>
                  <div *ngIf="submitted1 && f1.second.errors" class="invalid-feedback">
                     <div *ngIf="f1.second.errors.required">
                        Otp is required</div>
                     <div *ngIf="f1.second.errors.pattern || f1.second.errors.maxlength || f1.second.errors.minlength">
                        Otp is not valid</div>
                     <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->

                  </div>
                  <div *ngIf="submitted1 && f1.third.errors" class="invalid-feedback">
                     <div *ngIf="f1.third.errors.required">
                        Otp is required</div>
                     <div *ngIf="f1.third.errors.pattern || f1.third.errors.maxlength || f1.third.errors.minlength">
                        Otp is not valid</div>
                     <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->

                  </div>
                  <div *ngIf="submitted1 && f1.fourth.errors" class="invalid-feedback">
                     <div *ngIf="f1.fourth.errors.required">
                        Otp is required</div>
                     <div *ngIf="f1.fourth.errors.pattern || f1.fourth.errors.maxlength || f1.fourth.errors.minlength">
                        Otp is not valid</div>
                     <!-- <span id="countdowntimer" class="timerstyle">00:30 </span>  -->

                  </div>
               </div>
               <input type="submit" class="btn booknow popbtn fullwidth" text="Continue" value="Continue" />
            </div>
         </form>
      </div>
   </div>
</ng-template>
<!--------------------------End of OTP Screen------------------->


<!--------------------------Name and Email ID------------------->
<ng-template #contactData let-modal>
   <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="popupInner">
         <h2 class="popuptitle clrblack fontrobotobold mb30">Login</h2>
         <img src="assets/images/home/Login-popup.svg" class="mb30 img-fluid" />
         <form [formGroup]="contactForm" (ngSubmit)="storeContact()">
            <p class="popupsubtitle clrblack fontrobotobold mb30">Enter your Name</p>
            <div class="loginpanel">
               <div class="form-group mb30">
                  <input class="txtbx pop_txtbx " required type="text" placeholder="Enter Your Name"
                     formControlName="customername" [ngClass]="{ 'is-invalid': submitted2 && f2.customername.errors }">
                  <div *ngIf="submitted2 && f2.customername.errors" class="invalid-feedback">
                     <div *ngIf="f2.customername.errors.required">Customer Name is required</div>
                     <div *ngIf="f2.customername.errors.pattern ">
                        Enter valid name
                     </div>
                  </div>
               </div>
               <div class="form-group mb30">
                  <input class="txtbx pop_txtbx " required type="text" placeholder="Enter Your Email"
                     formControlName="customeremailid"
                     [ngClass]="{ 'is-invalid': submitted2 && f2.customeremailid.errors }">
                  <div *ngIf="submitted2 && f2.customeremailid.errors" class="invalid-feedback">
                     <div *ngIf="f2.customeremailid.errors.required">Customer Email ID is required</div>
                     <div *ngIf="f2.customeremailid.errors.pattern ">
                        Enter valid mail id
                     </div>
                  </div>
               </div>
               <input type="submit" class="btn booknow popbtn fullwidth" text="Continue" value="Continue" />
            </div>
         </form>
      </div>
   </div>

</ng-template>
<!-----------------------------End of contact details------------------------>
<!--------------------------------Brand Popup------------------------------->
<ng-template #brandpopup let-modal>
   <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="popupInner">
         <h2 class="popuptitle clrblack fontrobotobold mb30">Select Brand</h2>
         <div class="selectbrand">
            <p class="paralarge"> Select Brand&nbsp;&nbsp;</p>
            <label class="confirmbx">
               <input type="checkbox" [formControl]="chkotherbrand" (click)="checkselection($event)" />
               <span><img src="assets/images/home/roca.png" /></span>
            </label>
            <label class="confirmbx">

               <input type="checkbox" [formControl]="chkparrybrand" (click)="checkselection($event)" />
               <span><img src="assets/images/home/parryware.png" /></span>
            </label>

            <label class="confirmbx">
               <input type="checkbox" [formControl]="chkjhonson" (click)="checkselection($event)" />
               <span><img src="assets/images/home/johnson.png" /></span>
            </label>
         </div>
         <div *ngIf="pdtsubmitted">
            Please Select a brand to Continue
         </div>

         <div class="form-group uploadpanel" *ngIf="warrantytype==='inwarranty'">
            <label>Please select month & year of purchase</label>
            <select [formControl]="month" placeholder='Select Month'>
               <option value="January">January</option>
               <option value="February">February</option>
               <option value="March">March</option>
               <option value="April">April</option>
               <option value="May">May</option>
               <option value="June">June</option>
               <option value="July">July</option>
               <option value="August">August</option>
               <option value="September">September</option>
               <option value="October">October</option>
               <option value="November">November</option>
               <option value="December">Decmeber</option>
            </select>
            <select [formControl]="year" placeholder='Select Year'>
               <option *ngFor="let year1 of years" [value]="year1">
                  {{year1}}
               </option>
            </select>
            <input type="file" accept=".pdf,.jpg,.gif,.png,.PDF,.JPG,.GIF,.PNG" id="file"
               (change)="handleFileInput($event)" />
            <button class="btn uploadbtn" (click)="uploadFile()">Upload Purchase document / invoice</button>

            <p class="disclaimertext">
               Disclaimer : During our visit, our technician would verify the purchase details. If the Purchase does not
               qualify for warranty services (as per company policy), the service would be treated as out of warranty
               service. Incase of Out of warranty, visit charges / standard labour charges would apply.
            </p>
         </div>
         <button class="btn" style="color:white; border-color: white;" (click)="NavigatetoServices()">Submit</button>
      </div>
   </div>
</ng-template>