import { ServicesComponent } from './services/services.component';
import { HomeComponent } from './home/home.component';
import { AddonComponent } from './addon/addon.component';
import { SelectaddressComponent } from './selectaddress/selectaddress.component';
import { SelectservicedateComponent } from './selectservicedate/selectservicedate.component';
import { ConfirmbookingComponent } from './confirmbooking/confirmbooking.component';
import { BookingconfirmedComponent } from './bookingconfirmed/bookingconfirmed.component';
import { MydashboardComponent } from './mydashboard/mydashboard.component';
import { ServicecompletedComponent } from './servicecompleted/servicecompleted.component';
import { CancelBookingComponent } from './cancel-booking/cancel-booking.component';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TermsComponent } from './terms/terms.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactComponent } from './contact/contact.component';
import { WarrantypolicyComponent } from './warrantypolicy/warrantypolicy.component';
import { CombodealsComponent } from './combodeals/combodeals.component';
import { WedesignyouexecuteComponent } from './wedesignyouexecute/wedesignyouexecute.component';
import { AmcComponent } from './amc/amc.component';
import { DeepcleaningserviceComponent } from './deepcleaningservice/deepcleaningservice.component';
import { RenovationComponent } from './renovation/renovation.component';

import { PaymentComponent } from './payment/payment.component';
import { SuccesspaymentComponent } from './successpayment/successpayment.component';
import { FailurepaymentComponent } from './failurepayment/failurepayment.component';
import { CancelledpaymentComponent } from './cancelledpayment/cancelledpayment.component';
import { CombothankyouComponent } from './combothankyou/combothankyou.component';
import { RenovationthankyouComponent } from './renovationthankyou/renovationthankyou.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: '/home', pathMatch: 'full'   
  },
  {
  path: 'home',
  component: HomeComponent 
    },
    {
        path: 'payment',
        component: PaymentComponent 
      },
    {
        path: 'successpayment',
        component: SuccesspaymentComponent 
    },
    {
        path: 'failurepayment',
        component: FailurepaymentComponent 
    },
    {
        path: 'cancelledpayment',
        component: CancelledpaymentComponent 
      },
{
  path: 'services',
  component: ServicesComponent
},
{
  path: 'addon',
  component: AddonComponent
},
{
  path: 'selectaddress',
  component: SelectaddressComponent
},
{
  path: 'selectservicedate',
  component: SelectservicedateComponent
},
{
  path: 'confirmbooking',
  component: ConfirmbookingComponent
},
{
  path: 'bookingconfirmed',
  component: BookingconfirmedComponent
},
{
  path: 'mydashboard',
  component: MydashboardComponent
}
,
{
  path: 'servicecompleted',
  component: ServicecompletedComponent
},
{
  path: 'cancelbooking',
  component: CancelBookingComponent
},
{
  path: 'privacypolicy',
  component: PrivacypolicyComponent
},
{
  path : 'terms',
  component:TermsComponent
},
{
  path:'aboutus',
  component:AboutusComponent
},
{
  path:'contact',
  component:ContactComponent
},
{
  path:'warrantypolicy',
  component:WarrantypolicyComponent
},
{
  path:'combodeals',
  component:CombodealsComponent
},
{
 path:'wedesignyouexecute',
 component:WedesignyouexecuteComponent
},
{
  path:'amc',
  component:AmcComponent
 },
 {
  path:'deepcleaningservice',
  component:DeepcleaningserviceComponent
 },
 {
  path:'renovation',
  component:RenovationComponent
 },
 {
  path:'combothankyou',
  component:CombothankyouComponent
 },
 {
  path:'renovationthankyou',
  component:RenovationthankyouComponent
 },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
