import { cancelModel, OrderModel, rescheduleModel, plumberreviewModel, VASModel } from './order-model';
import { AddressModel } from './address-model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';



@Injectable({ providedIn: 'root' })


export class ApiService {

 // baseURL: string = "https://demoapi.parrywarebano.in/api/";

 baseURL: string = "https://api.parrywarebano.in/api/";

  constructor(private http: HttpClient) {

  }


  
  getOTP(mobile: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify({ 'MobileNumber': mobile });
    return this.http.post(this.baseURL + 'Login/GenerateOTP', body, { 'headers': headers }).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError('Something went wrong!');
      })
    )
  }

  validateOTP(mobile:string,otp:number): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify({ 'MobileNumber': mobile, "OTP":otp });
    return this.http.post(this.baseURL + 'Login/Validate', body, { 'headers': headers }).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError('Something went wrong!');
      })
    )
  }

  getProducts(categoryid: number): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify({ 'category_id': categoryid, 'product_id': null });
    return this.http.post(this.baseURL + 'product/get', body, { 'headers': headers }).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError('Something went wrong!');
      })
    )
  }

  getLocation() : Observable<any>{
    const opts = { params: new HttpParams({}) }
    return this.http.get(this.baseURL + 'Admin/Location',opts ).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError('Something went wrong!');
      })
    )
  }

  getState() : Observable<any>{
    const opts = { params: new HttpParams({}) }
    return this.http.get(this.baseURL + 'Admin/State',opts ).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError('Something went wrong!');
      })
    )
  }

  getAddons(catids:string) : Observable<any>{
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify({ 'category_id': 0, 'category_ids' : catids, 'AddOn_id': null });
    return this.http.post(this.baseURL + 'product/AddOn', body, { 'headers': headers }).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError('Something went wrong!');
      })
    )
  }

  getAddressDetails():Observable<any>{
    let custid=localStorage.getItem("CustomerID");
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify({ 'customer_id': custid, 'address_id': null });
    return this.http.post(this.baseURL + 'address/get', body, { 'headers': headers }).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError('Something went wrong!');
      })
    )
  }

  postAddressDetails(addmodel:AddressModel):Observable<any>{
    
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(addmodel);
    console.log(body);
    return this.http.post(this.baseURL + 'address/add', body, { 'headers': headers }).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError('Something went wrong!');
      })
    )
  }

  postupdateAddressDetails(addmodel:any):Observable<any>{
    
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(addmodel);
    console.log(body);
    return this.http.post(this.baseURL + 'address/edit', body, { 'headers': headers }).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError('Something went wrong!');
      })
    )
  }


  postdeleteAddressDetails(addmodel:any):Observable<any>{
    
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(addmodel);
    console.log(body);
    return this.http.post(this.baseURL + 'address/delete', body, { 'headers': headers }).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError('Something went wrong!');
      })
    )
  }

  postOrderDetails(ordermodel:OrderModel):Observable<any>{
    
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(ordermodel);
    console.log(body);
    return this.http.post(this.baseURL + 'order/add', body, { 'headers': headers }).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        console.log(error);
        return throwError('Something went wrong!');
      })
    )
  }


  getDates(pincode1:string):Observable<any>{
    
    const opts = { params: new HttpParams({fromString: "pincode=" + pincode1}) }
    return this.http.get(this.baseURL + 'Plumber/GetAvailableDate',opts).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError('Something went wrong!');
      })
    )
  }

  getTimeSlots(date1:any,pincode1:string):Observable<any>{
    
    const opts = { params: new HttpParams({fromString: "date=" + date1 + "&pincode=" + pincode1}) }
    return this.http.get(this.baseURL + 'Plumber/GetAvailableSlot',opts ).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError('Something went wrong!');
      })
    )
  }

  postCustomerDetail(customermodel:any):Observable<any>{
    
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(customermodel);
    console.log(body);
    return this.http.post(this.baseURL + 'Login/Update', body, { 'headers': headers }).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError('Something went wrong!');
      })
    )
  }

  getOrderDetails(custmodel:any):Observable<any>{
    
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(custmodel);
    console.log(body);
    return this.http.post(this.baseURL + '/order/get', body, { 'headers': headers }).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        return throwError('Something went wrong!');
      })
    )
  }

postreschduledorder(reschedulemodel:rescheduleModel):Observable<any>{
  const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(reschedulemodel);
    console.log(body);
    return this.http.post(this.baseURL + 'order/reschedule', body, { 'headers': headers }).pipe(
      map((data) => {
        console.log(data);
        return data;
      }), catchError(error => {
        return throwError('Something went wrong!');
      })
    )
}

postcancelorder(cancelmodel:cancelModel):Observable<any>{
  const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(cancelmodel);
    console.log(body);
    return this.http.post(this.baseURL + 'order/cancel', body, { 'headers': headers }).pipe(
      map((data) => {
        console.log(data);
        return data;
      }), catchError(error => {
        return throwError('Something went wrong!');
      })
    )
}

VASRenovation(vasmodel:VASModel): Observable<any> {
  const headers = { 'content-type': 'application/json' };
  const body = JSON.stringify(vasmodel);
    console.log(body);
    return this.http.post(this.baseURL + 'VAS/Renovation', body, { 'headers': headers }).pipe(
      map((data) => {
        console.log(data);
        return data;
      }), catchError(error => {
        return throwError('Something went wrong!');
      })
    )
}

postplumberreview(reviewmodel:plumberreviewModel):Observable<any>{
  const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(reviewmodel);
    console.log(body);
    return this.http.post(this.baseURL + 'plumber/review', body, { 'headers': headers }).pipe(
      map((data) => {
        console.log(data);
        return data;
      }), catchError(error => {
        return throwError('Something went wrong!');
      })
    )
}

postFile(fileToUpload: File) {
  const headers = { 'content-type': 'multipart/form-data' };
  const formData: FormData = new FormData();
  formData.append('fileKey', fileToUpload, fileToUpload.name);
  return this.http.post(this.baseURL + 'order/UploadFiles', formData);
}

postMessage(msgpayload:any)
{
  const headers = { 'content-type': 'application/json' };
  const body = JSON.stringify(msgpayload);
  console.log(body);
  return this.http.post(this.baseURL + 'contactus/add', body, { 'headers': headers }).pipe(
    map((data) => {
      console.log(data);
      return data;
    }), catchError(error => {
      return throwError('Something went wrong!');
    })
  )
}

validPin(pincode:any)
{
  const opts = { params: new HttpParams({fromString: "pincode=" + pincode}) }
  return this.http.get(this.baseURL + 'Order/PincodeCheck',opts ).pipe(
    map((data) => {
      console.log(data);
    return data;
    }), catchError((error:any) => {
      
      return error;
    })
  )
}

validCity(city:any)
{
  const opts = { params: new HttpParams({fromString: "pincode=" + city}) }
  return this.http.get(this.baseURL + 'Admin/CityCheck',opts ).pipe(
    map((data) => {
      console.log(data);
    return data;
    }), catchError((error:any) => {
      
      return error;
    })
  )
}

validState(state:any)
{
  const opts = { params: new HttpParams({fromString: "pincode=" + state}) }
  return this.http.get(this.baseURL + 'Admin/StateCheck',opts ).pipe(
    map((data) => {
      console.log(data);
    return data;
    }), catchError((error:any) => {
      
      return error;
    })
  )
}

validatecoupon(coupondetail:any)
{
  const headers = { 'content-type': 'application/json' };
  const body = JSON.stringify(coupondetail);
  console.log(body);
  return this.http.post(this.baseURL + 'coupon/validate', body, { 'headers': headers }).pipe(
    map((data) => {
      console.log(data);
      return data;
    }), catchError(error => {
      return null;
    })
  )
}

}
