<div class="DashboardInner" data-ng-init="checkloggedin()">
  <div class="container">
    <header class="dashboardHeader">
      <div class="row dsRow no-gutters">
        <div class="col-lg-4 col-sm-4 dsgrid dsleftpanel">
          <p class="paraxl fontrobotoblack clrwhite">
            <a routerLink="/home" routerLinkActive="active"
              ><img src="assets/images/dashboard/back.png" class="img-fluid" />
              <span>Back</span></a
            >
          </p>
        </div>
        <div class="col-lg-8 col-sm-8 dsgrid dsrightpanel">
          <div class="dsheaderlist">
            <div class="mlist dropdown">
              <div class="dropdown-toggle" ngbDropdown #menu="ngbDropdown">
                <p>Login/Sign up</p>
                <a class="loginClass">
                  <img src="assets/images/home/login.svg" class="img-fluid" />
                  <span>My Activity</span>
                </a>
                <button
                  class="btn btn-outline-primary mr-2"
                  id="dropdownManual"
                  ngbDropdownAnchor
                  (focus)="menu.open()"
                  *ngIf="loggedin"
                >
                  <p *ngIf="loggedin">Welcome {{ getCustomerName() }}</p>
                  <img src="assets/images/home/login.svg" class="img-fluid" />

                  <span>My Activity</span>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownManual">
                  <button ngbDropdownItem>
                    <span
                      ><img
                        src="assets/images/home/drp-icon-2.png"
                        class="img-fluid" /></span
                    ><a routerLink="/mydashboard" routerLinkActive="active"
                      >My Booking</a
                    >
                  </button>
                  <button ngbDropdownItem (click)="logout()">
                    <span
                      ><img
                        src="assets/images/home/drp-icon-3.png"
                        class="img-fluid" /></span
                    >Logout
                  </button>
                </div>
              </div>

              <div class="dropdown-menu">
                <a class="dropdown-item" href="#"></a>
                <a class="dropdown-item" href="#"></a>
              </div>
            </div>
            <div class="dslogo">
              <a class="nav-brand" routerLink="/home" routerLinkActive="active">
                <img
                  src="assets/images/home/Bano_logo_white.svg"
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</div>

<!-------------------------------Banner------------------>
<section class="homebannerpanel ContactPanel position-relative">
  <div class="bgs deepcleaningbg"></div>
</section>
<!-------------------Banner Text----------->
<div class="banText remove-pos">
  <div class="container">
    <div class="banTxtInner">
      <h1 class="h1title fontrobotoblack clrlack">Deep Cleaning Services</h1>
    </div>
    <div>
      <ul class="breadcrumb-theme">
        <li><a routerLink="/home" routerLinkActive="active">Home </a></li>
        <li>/</li>
        <li><a> Deep Cleaning Services</a></li>
      </ul>
    </div>
  </div>
</div>

<!-----------------------------------Contact form-------------->
<section class="contactFormPanel">
  <div class="container">
    <div class="row no-gutters cfp_row">
      <div class="col-lg-6 cfp_grid cfp_info">
        <p class="paralarge mb20 fontrobotomedium">
          Why Bano Deep clean services?
        </p>
        <ul class="deeplist deeplistbg">
          <li>Best in class Professionals</li>
          <li>Our Technicians are BG Verified</li>
          <li>Trained and certified by Parryware</li>
        </ul>

        <p class="paralarge mb20 fontrobotomedium">Our Services</p>
        <p class="paramedium mb20 clrblue fontrobotobold">Bathroom cleaning</p>
        <ul class="deeplist deeplist-inn">
          <li>
            <a (click)="gotostandardcleaning()" >
            <img src="assets/images/deepcleaning/title-icons/bathroon-cleaning-icon-1.png"/>Standard Cleaning Rs.499
          </a>
        </li>
          <li>
            <a  (click)="gotodeepcleaning()">
            <img src="assets/images/deepcleaning/title-icons/bathroon-cleaning-icon-2.png"/>Deep Cleaning Rs.599
            </a>

          </li>
          <li>
            <a  (click)="gotomoveinbathroom()">
            <img src="assets/images/deepcleaning/title-icons/bathroon-cleaning-icon-3.png"/>Move-in Bathroom cleaning Rs. 699
              </a>
          </li>
        </ul>
        <p class="paramedium mb20 clrblue fontrobotobold">Kitchen cleaning</p>
        <ul class="deeplist deeplist-inn">
          <li>
            <a  (click)="gotomoveinkitchen()">
            <img src="assets/images/deepcleaning/title-icons/kitchen-cleaning-icon-1.png"/>
            Move-in Kitchen cleaning/ Empty Kitchen Cleaning, Starts at Rs.999
            </a>
          </li>
          <li>
            <a (click)="gotodeepkitchencleaning()">
            <img src="assets/images/deepcleaning/title-icons/kitchen-cleaning-icon-2.png"/>
          
            Complete Kitchen cleaning, Starts at Rs.1399
          </a>
          </li>
        </ul>
        <!--<h3 class="clrblue mb30 fontrobotobold">FAQ
                </h3>-->
        <ngb-accordion
          [closeOthers]="true"
          activeIds="static-1"
          class="faqaccordion accstyle mb50 deepcleaningacc"
          style="display: none"
        >
          <ngb-panel
            id="static-1"
            title="How Much Does It Cost to Remodel My Bathroom?"
          >
            <ng-template ngbPanelContent> </ng-template>
          </ngb-panel>
          <ngb-panel
            id="static-2"
            title="How Long Will It Take to Remodel My Bathroom?"
          >
            <ng-template ngbPanelContent> </ng-template>
          </ngb-panel>
          <ngb-panel
            id="static-3"
            title="Do You Have Someone That Can Help Us with the Design?"
          >
            <ng-template ngbPanelContent> </ng-template>
          </ngb-panel>
          <ngb-panel
            id="static-4"
            title="Do You Have a Showroom with Material That I Can Look At?"
          >
            <ng-template ngbPanelContent> </ng-template>
          </ngb-panel>
          <ngb-panel id="static-5" title="What’s Your Process?">
            <ng-template ngbPanelContent> </ng-template>
          </ngb-panel>
          <ngb-panel id="static-6" title="What’s Your Process?">
            <ng-template ngbPanelContent> </ng-template>
          </ngb-panel>
          <ngb-panel id="static-7" title="Do You Have a Warranty?">
            <ng-template ngbPanelContent> </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
      <div class="col-lg-6 cfp_grid cfp_form">
        <div class="cfp-img">
          <img
            src="assets/images/deepcleaning/deep-cleaning-right.jpg"
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
</section>
<!----------------------------Process Steps-------------->
<section class="hwpanel clean-process" id="process-step-sec">
  <div class="container">
    <div class="cleaning-steps">
      <h2 class="fontrobotobold h2subtitle mb-5 text-center clrwhite">
        <img
              src="assets/images/deepcleaning/icons/bathroom-cleaning.png"
              class="img-fluid"
            />
        Bathroom Cleaning
      </h2>
      <div class="clean-panel" id="standardCleaning">
        <p class="paralarge mb-3 clrblue fontrobotobold">
          Standard Bathroom Cleaning- Rs.499
        </p>
        <div class="cl-panel-grid">
          <div class="thumbimg">
            <img
              src="assets/images/deepcleaning/Standard-Cleaning.jpg"
              class="img-fluid"
            />
          </div>
          <div class="cl-text">
            <p class="clrwhite mb-2 fontrobotobold paramedium">Includes-</p>
            <ul class="deeplist clrwhite">
              <li>
                Thorough cleaning of bathroom surfaces.
              </li>
              <li>
                Dirt, dust cleaning, and stubborn stain removal from corners.
              </li>
              <li>
                Drying the bathroom and spraying a pleasant fragrance.
              </li>
            </ul>
          </div>
          <div class="booknow-deep" (click)="gotoform()">
            <button class="deep-btntheme">Book now</button>
          </div>
        </div>
      </div>
      <div class="clean-panel">
        <p class="paralarge mb-3 clrblue fontrobotobold">WHAT TO EXPECT?</p>
          <h3 class=" mb-1 paramedium fontrobotobold">
            <img
              src="assets/images/deepcleaning/icons/stain.png"
              class="img-fluid"
            />
            <span class="fontrobotobold">Stain Removal</span>-
          </h3>
          <p class="clrwhite clean-text mb-2 paramedium">
            Dirt, Dust, Grime,
            and black deposits in the corners of the bathroom will be removed.
            Mirrors and other fittings will also be cleaned spic and span!
         </p>
      
          <h3 class=" mb-1 paramedium fontrobotobold">
            <img
              src="assets/images/deepcleaning/icons/Professional-Cleaning-accessories.png"
              class="img-fluid"
            />
            <span class="fontrobotobold">Professional Cleaning accessories -</span>
          </h3>
          <p class="clrwhite clean-text mb-2 paramedium">
         Our Experts will carry Cleaning Chemicals and Accessories that are
          best in quality to relieve you from the stress of damaged or unclean
          bathrooms!
        </p>
        <p class="clrwhite clean-text mb-2 paramedium">
          Kindly note that ladder, if needed for cleaning needs to be provided
          by you at the time of service.
        </p>
      </div>
      <div class="clean-panel" id="deepcleaning">
        <p class="paralarge mb-3 clrblue fontrobotobold">
          Bathroom Deep Cleaning- Rs.599
        </p>
        <div class="cl-panel-grid">
          <div class="thumbimg">
            <img
              src="assets/images/deepcleaning/Deep-Cleaning-banner.jpg"
              class="img-fluid"
            />
          </div>
          <div class="cl-text">
            <p class="clrwhite mb-2 fontrobotobold paramedium">Includes-</p>
            <ul class="deeplist clrwhite">
              <li>
                Objects in the bathroom will be removed before cleaning begins
              </li>
              <li>Stubborn & Difficult stains will be removed</li>
              <li>Entire Bathroom area & surfaces will be cleaned</li>
              <li>
                We will clean up even the spots that are most difficult to reach
                with regular cleaning.
              </li>
              <li>
                Placing the objects back at the spots, drying the room and
                spraying a pleasant fragrance.
              </li>
            </ul>
          </div>
          <div class="booknow-deep" (click)="gotoform()">
            <button class="deep-btntheme">Book now</button>
          </div>
        </div>
      </div>
      <div class="clean-panel">
        <p class="paralarge mb-3 clrblue fontrobotobold">WHAT TO EXPECT?</p>
        
          <h3 class=" mb-1 paramedium fontrobotobold">
            <img
            src="assets/images/deepcleaning/icons/looks-like-New.png"
            class="img-fluid"
          />
            <span class="fontrobotobold">Bathroom that looks like New</span>
          </h3>
          <p class="clrwhite clean-text mb-2 paramedium">
          The most stubborn and difficult stains such as Lime scale, Grout,
          Grime, Hard water stains will be cleaned by our experts. We ensure
          deep cleaning of Toilet Pots, Tiles, Floor, Basin, Exhaust and other
          fittings in the bathroom.
        </p>
       
          <h3 class="mb-1 paramedium fontrobotobold">
            <img
            src="assets/images/deepcleaning/icons/Glow-and-Shine.png"
            class="img-fluid"
          />
            <span class="fontrobotobold">Glow and Shine-</span>
          </h3>
          <p class="clrwhite clean-text mb-2 paramedium">
          Our Experts will ensure they leave your bathroom clean, sparkling and
          shining after the cleaning magic!
        </p>
       
          <h3 class="mb-1 paramedium fontrobotobold">
            <img
            src="assets/images/deepcleaning/icons/Profeesional-Cleaning-accessories.png"
            class="img-fluid"
          />
            <span class="fontrobotobold">Professional Cleaning accessories-</span>
          </h3>
          <p class="clrwhite clean-text mb-2 paramedium">
          Our Experts will carry Cleaning Chemicals and Accessories that are
          best in quality to relieve you from the stress of damaged or unclean
          bathrooms!
        </p>
        <p class="clrwhite clean-text mb-2 paramedium">
          Kindly note that ladder, if needed for cleaning needs to be provided
          by you at the time of service.
        </p>
      </div>
      <div class="clean-panel" id="movein-bathroom">
        <p class="paralarge mb-3 clrblue fontrobotobold">
          Move-in Bathroom Cleaning- Rs.699
        </p>
        <div class="cl-panel-grid">
          <div class="thumbimg">
            <img
              src="assets/images/deepcleaning/Move-in-Bathroom-banner.jpg"
              class="img-fluid"
            />
          </div>
          <div class="cl-text">
            <p class="clrwhite mb-2 fontrobotobold paramedium">Includes-</p>
            <ul class="deeplist clrwhite">
              <li>
                Objects in the bathroom will be removed before cleaning begins
              </li>
              <li>Stubborn & Difficult stains will be removed</li>
              <li>Entire Bathroom area & surfaces will be cleaned</li>
              <li>
                We will clean up even the spots that are most difficult to reach
                with regular cleaning.
              </li>
              <li>
                Placing the objects back at the spots, drying the room and
                spraying a pleasant fragrance.
              </li>
            </ul>
          </div>
        </div>
        <div class="booknow-deep" (click)="gotoform()">
          <button class="deep-btntheme">Book now</button>
        </div>
      </div>
      <div class="clean-panel">
        <p class="paralarge mb-3 clrblue fontrobotobold">WHAT TO EXPECT?</p>
       
          <h3 class="mb-1 paramedium fontrobotobold">
            <img
            src="assets/images/deepcleaning/icons/looks-like-New.png"
            class="img-fluid"
          />
            <span class="fontrobotobold">Bano approved clean bathroom</span>
          </h3>
         <p class="clrwhite clean-text mb-2 paramedium">
          This service is ideal if you are looking to relocate or move in to a
          new place. Our rigorous cleaning will make your bathroom shine like
          new, and tidy to move in!
        </p>
        
          <h3 class="mb-1 paramedium fontrobotobold">
            <img
            src="assets/images/deepcleaning/icons/Professional-Cleaning-accessories.png"
            class="img-fluid"
          />
            <span class="fontrobotobold">Professional Cleaning accessories</span>
          </h3>
          <p class="clrwhite  clean-text mb-2 paramedium">
          Our Experts will ensure they leave your bathroom clean, sparkling and
          shining after the cleaning magic!
        </p>
        
          <h3 class="mb-1 paramedium fontrobotobold">
            <img
            src="assets/images/deepcleaning/icons/Professional-Cleaning-accessories.png"
            class="img-fluid"
          />
            <span class="fontrobotobold">Professional Cleaning accessories-</span>
          </h3>
          <p class="clrwhite clean-text mb-2 paramedium">
          Our Experts will carry Cleaning Chemicals and Accessories that are
          best in quality to relieve you from the stress of damaged or unclean
          bathrooms!
        </p>
        <p class="clrwhite clean-text mb-2 paramedium">
          Kindly note that ladder, if needed for cleaning needs to be provided
          by you at the time of service.
        </p>
      </div>
      <h2 class="fontrobotobold h2subtitle mb-5 text-center clrwhite">
        <img
              src="assets/images/deepcleaning/icons/kitchen-cleaning.png"
              class="img-fluid"
            />
        Kitchen Cleaning
      </h2>
      <div class="clean-panel" id="movein-kitchen">
        <p class="paralarge mb-3 clrblue fontrobotobold">
          Move-in Kitchen cleaning/ Empty Kitchen Cleaning-Starting at Rs. 999
        </p>
        <div class="cl-panel-grid">
          <div class="thumbimg">
            <img
              src="assets/images/deepcleaning/Deep-Kitchen-Cleaning-banner.jpg"
              class="img-fluid"
            />
          </div>
          <div class="cl-text">
            <p class="clrwhite mb-2 fontrobotobold paramedium">Includes-</p>
            <ul class="deeplist clrwhite">
              <li>
                Oil, grease and Stain removal from Stove, hob, walls, cabinet,
                tiles, windows countertops and other fittings.
              </li>
              <li>
                Inside of the cabinets, modular drawers, cupboards will also be
                cleaned.
              </li>
              <li>Drying the cleaned surfaces.</li>
              <li>
                Our Experts will carry Cleaning Chemicals and Accessories that
                are best in quality.
              </li>
            </ul>
            <p class="clrwhite mb-2 fontrobotobold paramedium">
              Does not Include-
            </p>
            <ul class="deeplist clrwhite">
              <li>Removal and placing back of utensils</li>
              <li>Chimney cleaning will include extra charges</li>
              <li>
                Ladder, if needed for cleaning needs to be provided by you at
                the time of service.
              </li>
            </ul>
          </div>
          <div class="booknow-deep" (click)="gotoform()">
            <button class="deep-btntheme">Book now</button>
          </div>
        </div>
      </div>
      <div class="clean-panel" id="deep-kitchen-cleaning">
        <p class="paralarge mb-3 clrblue fontrobotobold">
          Deep Kitchen Cleaning- Starting at Rs. 1399
        </p>
        <div class="cl-panel-grid">
          <div class="thumbimg">
            <img
              src="assets/images/deepcleaning/empty-cleaning.jpg"
              class="img-fluid"
            />
          </div>
          <div class="cl-text">
            <p class="clrwhite mb-2 fontrobotobold paramedium">Includes-</p>
            <ul class="deeplist clrwhite">
              <li>Removing and placing back utensils after cleaning</li>
              <li>
                Oil, grease and Stain removal from Stove, hob, walls, cabinet,
                tiles, windows countertops and other fittings.
              </li>
              <li>
                Intense cleaning of stubborn stains and accumulated dirt at
                unreachable spots.
              </li>
              <li>
                Inside of the cabinets, modular drawers, cupboards will also be
                cleaned.
              </li>
              <li>Drying the cleaned surfaces.</li>
              <li>
                Our Experts will carry Cleaning Chemicals and Accessories that
                are best in quality.
              </li>
            </ul>
            <p class="clrwhite mb-2 fontrobotobold paramedium">
              Does not Include-
            </p>
            <ul class="deeplist clrwhite">
              <li>
                Chimney cleaning will include extra charges depending upon your
                selection
              </li>
              <li>
                Ladder, if needed for cleaning needs to be provided by you at
                the time of service.
              </li>
            </ul>
          </div>
          <div class="booknow-deep" (click)="gotoform()">
            <button class="deep-btntheme">Book now</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="valueformsec deepcleaninform" id="deepcleanform"> 

 <div class="valu-form">
     <div class="form-group mb15">
          <input class="ctformstyle" placeholder="Name*"/>
     </div>
     <div class="form-group mb15">
          <input class="ctformstyle" placeholder="Email ID*"/>
     </div>
      <div class="form-group mb15">
          <input class="ctformstyle" placeholder="Mobile Number*"/>
     </div>
     <div class="form-group mb15">
      <input class="ctformstyle" placeholder="Pincode*"/>
 </div>
 <div class="form-group mb15">
 <select class="ctformstyle">
  <option>Select your product</option>
  <option>Standard Cleaning</option>
  <option>Deep Cleaning</option>
  <option>Move-in Bathroom cleaning</option>
  <option>Move-in Kitchen cleaning</option>
  <option>Complete Kitchen cleaning</option>
 </select>
</div>
      <div class="form-group mb30">
          <input class="ctformstyle" placeholder="Remarks"/>
     </div>  
     <div class="form-group d-flex  justify-content-center">
      <a class="gonextbtn btn booknow ctsubmitbtn" >Submit 
          <img src="assets/images/dashboard/continue.png" class="img-fluid"/>
      </a>
     </div>                                                                             
 </div>
</div>
<!-------------------------------Footer Panel------------------>
<section class="Footerpanel">
  <div class="footerTop">
    <div class="container">
      <div class="fTopInner text-center">
        <a routerLink="/home" routerLinkActive="active"
          ><img src="assets/images/home/logo.png" class="img-fluid"
        /></a>
        <p class="para">
          Parryware India is a leading manufacturer of bathroom products
          originated from EID Parry, one of the country's oldest and most
          reputed business entities. In 2006, it entered into a joint venture
          with Roca Corporation, Spain, to become a Roca Group subsidiary in
          2008, and turned into a wholly-owned subsidiary of the Roca Group in
          2011.
        </p>
      </div>
    </div>
  </div>
  <div class="footerBtm">
    <div class="container">
      <div class="row fbtmRow no-gutters">
        <div class="col-lg-8 fbtmenus">
          <ul class="nav menu_footer">
            <li>
              <a
                routerLink="/aboutus"
                routerLinkActive="active"
                (click)="gotopage()"
                >About us</a
              >
            </li>
            <li class="line"></li>
            <li>
              <a
                routerLink="/contact"
                routerLinkActive="active"
                (click)="gotopage()"
                >Contact us</a
              >
            </li>
            <li class="line"></li>
            <li><a pageScroll href="#faq" (click)="gotopage()">FAQ</a></li>
            <li class="line"></li>
            <li>
              <a
                routerLink="/warrantypolicy"
                routerLinkActive="active"
                (click)="gotopage()"
                >Warranty Policy</a
              >
            </li>
            <li class="line"></li>
            <li>
              <a
                routerLink="/privacypolicy"
                routerLinkActive="active"
                (click)="gotopage()"
                >Privacy Policy</a
              >
            </li>
            <li class="line"></li>
            <li>
              <a
                routerLink="/terms"
                routerLinkActive="active"
                (click)="gotopage()"
                >Terms of Use</a
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-4 social-grid">
          <p class="parasmall">Follow us on:</p>
          <ul class="social-menu">
            <li>
              <a href="https://www.facebook.com/parrywarebano/" target="_blank"
                ><img src="assets/images/facebook.svg" class="img-fluid"
              /></a>
            </li>
            <li>
              <a><img src="assets/images/instagram.svg" class="img-fluid" /></a>
            </li>
          </ul>
        </div>
      </div>
      <!--coprights-->
      <div class="copyright">
        <p class="parasmall clrwhite text-center">
          © 2021 Parryware | All Rights Reserved
        </p>
      </div>
    </div>
  </div>
</section>
