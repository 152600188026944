
import { OrderModel, productdetail, addondetail, rescheduleModel, ProductType, couponcodes } from './../order-model';
import { CartService } from './../cart.service';
import { Component, Injectable, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from './../api.service';
import { OrderStatusModel } from './../order-model';
import { AddressModel } from '../address-model';
import {  FormControl } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner"
import {Title} from "@angular/platform-browser";

export type customerorder = { customer_id: number, orderid: number }





@Component({
  selector: 'app-confirmbooking',
  templateUrl: './confirmbooking.component.html',
  styleUrls: ['./confirmbooking.component.css'],
  providers: []
})

export class ConfirmbookingComponent implements OnInit {

  @ViewChild('paymentForm') payform :any;

    encRequest = new FormControl();
    order_id=new FormControl();
 access_code=new FormControl();
  chkparry = new FormControl();
  chkmulti = new FormControl();
  chkwarranty = new FormControl();
  chkaccept=new FormControl();
  selecteddate: any = {};
  selectedslot: any = {};
  starttime: string = "";
  selectday: string = "";
  dateselected: Date = new Date();
  datestring: string = "";
  product: any = [];
  addon: any = [];
  totalamt: number = 0;
  pdtdetail: productdetail[] = [];
  addons: addondetail[] = [];
  errormsg: string = "";
  orderDetail: OrderStatusModel;
  addselected: AddressModel;
  
  couponcode = new FormControl();
  pdttype: ProductType = {
    date_of_purchase: "",
    parryware_product: false,
    multibrand_product: false,
  };
  couponapply: boolean = true;
  validcouponcode = true;
  couponcodevalidate=true;
  applied = true;
  Discountamount = 0;
  Taxamount = 0;

  step1=false;
  step2=false;
  step3=false;
  step4=false;
  step5=false;
 years:any=[];

  constructor( private titleService:Title,private spinner: NgxSpinnerService,private datepipe: DatePipe, private cart: CartService, private router: Router, private apiservice: ApiService) { }


  ngOnInit(): void {

this.pdttype=JSON.parse(window.sessionStorage.getItem("producttype"));
    localStorage.setItem("step5","true");

    this.step1=JSON.parse(localStorage.getItem("step1"));
this.step2=JSON.parse(localStorage.getItem("step2"));
this.step3=JSON.parse(localStorage.getItem("step3"));
this.step4=JSON.parse(localStorage.getItem("step4"));
this.step5=JSON.parse(localStorage.getItem("step5"));

    console.log(window.sessionStorage.getItem("rescheduleflag"));
    if (window.sessionStorage.getItem("rescheduleflag") == null || window.sessionStorage.getItem("rescheduleflag") === "false") {
      this.cart = JSON.parse(window.sessionStorage.getItem("cart"));
      console.log(this.cart);
      this.product = this.cart.items;
      this.addon = this.cart.addonitems;
      this.selecteddate = JSON.parse(window.sessionStorage.getItem("selecteddate"));
      this.selectedslot = JSON.parse(window.sessionStorage.getItem("selectedtime"));
      console.log(this.selectedslot);
      let time1 = this.selectedslot.slot;
      console.log(time1);
      let timeslot = time1.split("-");
      this.starttime = timeslot[0];
      this.selectday = this.selecteddate.day_of_week;
      this.dateselected = this.selecteddate.date;
      this.datestring = this.datepipe.transform(this.dateselected, 'MMMM dd, yyyy')
      this.calculateTotal();
    }
    else {
      let custorder: customerorder = {
        customer_id: parseInt(localStorage.getItem("CustomerID")),
        orderid: parseInt(window.sessionStorage.getItem("confirmedorderid"))
      }
      console.log(custorder);
      this.apiservice.getOrderDetails(custorder).subscribe((data: any) => {
        console.log(data);
        let index1 = data.orders.findIndex((ob: { order_id: number; }) => ob.order_id === parseInt(window.sessionStorage.getItem("confirmedorderid")));
        this.orderDetail = data.orders[index1];
        console.log(this.orderDetail);
        this.product = this.orderDetail.product_details;
        console.log(this.product);
        this.addon = this.orderDetail.addon_details;
        this.addselected = {
          customer_id: this.orderDetail.customer_id,
          cust_name: this.orderDetail.cust_name,
          housename_no: this.orderDetail.housename_no,
          street_name: this.orderDetail.street_name,
          area: this.orderDetail.area,
          landmark: this.orderDetail.landmark,
          city_id: this.orderDetail.city_id,
          state_id: this.orderDetail.state_id,
          pincode: this.orderDetail.pincode,
          tel_no: this.orderDetail.tel_no,
          email: this.orderDetail.email,
          address_type: ""
        }
        this.cart.items = this.product;
        this.cart.addonitems = this.addon;
        window.sessionStorage.setItem("cart", JSON.stringify(this.cart));
        window.sessionStorage.setItem("addselected", JSON.stringify(this.addselected));
        this.selecteddate = JSON.parse(window.sessionStorage.getItem("selecteddate"));
        this.selectedslot = JSON.parse(window.sessionStorage.getItem("selectedtime"));
        console.log(this.selectedslot);
        let time1 = this.selectedslot.slot;
        console.log(time1);
        let timeslot = time1.split("-");
        this.starttime = timeslot[0];
        this.selectday = this.selecteddate.day_of_week;
        this.dateselected = this.selecteddate.date;
        this.datestring = this.datepipe.transform(this.dateselected, 'MMMM dd, yyyy')
        this.calculateTotal();
       
      })
    }



  }

  calculateTotal() {
  this.totalamt=0;
      this.pdtdetail=[];
      this.addons=[];
    for (var i = 0; i < this.product.length; i++) {
      let pdt = new productdetail(this.product[i].product_id,this.product[i].quantity)
      this.pdtdetail.push(pdt);
      this.totalamt += (this.product[i].price * this.product[i].quantity);
    }
    if(this.addon!=null)
    {
    for (var i = 0; i < this.addon.length; i++) {
      let addon = new addondetail(this.addon[i].addon_id,1);
      this.addons.push(addon);
      this.totalamt += this.addon[i].price;
    }
    }
      this.Taxamount = ((this.totalamt) * 0.18);
      
      this.totalamt += this.Taxamount;

      this.totalamt -= this.Discountamount;
  }


  gotopage() {
    window.scrollTo(0, 0);
  }

acceptclick(e:any)
{
  
  if(e.target.checked)
    this.errormsg="";
}

  saveOrderDetails() {
console.log(this.chkaccept.value);
    if(this.chkaccept.value===false  || this.chkaccept.value===null)
    {
      this.errormsg="Please accept the terms and conditions";
      return;
    }

    let coupon1: couponcodes = {
      coupon_code: ''
    }

    if (this.couponcode.value != "" && this.couponcode.value != undefined) {
      localStorage.setItem("CouponCode",this.couponcode.value.toString());
      //window.sessionStorage.setItem("couponcode", this.couponcode.value);
      coupon1 = {
        coupon_code: this.couponcode.value
      }
    }

    localStorage.setItem("DiscountAmount",this.Discountamount.toString());
    localStorage.setItem("TaxAmount",this.Taxamount.toString());
    
    
    let addressdetails = JSON.parse(window.sessionStorage.getItem("addselected"));

    if(!this.couponapply || !this.validcouponcode)
    {
      console.log(this.couponapply);
      console.log(this.validcouponcode);
      console.log(this.couponcode.value);
      console.log(coupon1);

    if (this.couponcode.value != "" && this.couponcode.value != undefined) {
      this.validatecouponcode();

      console.log(this.couponapply);
      console.log(this.validcouponcode);
      console.log(this.couponcode.value);
      console.log(coupon1);

      //if (this.couponcode.value != "" && this.couponapply) {
        if (this.couponcode.value != "") {
        localStorage.setItem("CouponCode",this.couponcode.value.toString());
        //window.sessionStorage.setItem("couponcode", this.couponcode.value);
        coupon1 = {
          coupon_code: this.couponcode.value
        }
      }

      console.log(this.couponapply);
      console.log(this.validcouponcode);
      console.log(this.couponcode.value);
      console.log(coupon1);

    }
  }
  let inwarranty=false;
  if(window.sessionStorage.getItem("warrantytype")==="IN")
  inwarranty=true;
    let reschedule = window.sessionStorage.getItem("rescheduleflag");
    if (reschedule === null || reschedule === "")
      reschedule = "false";
    if (reschedule === "false") {

      let orderdetail: OrderModel = {
        customer_id: parseInt(window.localStorage.getItem("CustomerID")),
        cust_name: this.getCustomerName(),
        housename_no: addressdetails.housename_no,
        street_name: addressdetails.street_name,
        area: addressdetails.area,
        landmark: addressdetails.landmark,
        city_id: addressdetails.city_id,
        state_id: addressdetails.state_id,
        pincode: addressdetails.pincode,
        tel_no: addressdetails.tel_no,
        email: addressdetails.email,
        appointment_date: this.dateselected.toString(),
        appointment_slot: this.selectedslot.slot,
        product_details: this.pdtdetail,
        addon_details: this.addons,
        product_type: this.pdttype,
        supporting_document: window.sessionStorage.getItem('filename'),
      
        coupon: coupon1,
        in_warranty:inwarranty,
        brand:window.sessionStorage.getItem('brand')
      }
        //coupon: coupon1,

      console.log(orderdetail);
      this.spinner.show();
      this.apiservice.postOrderDetails(orderdetail).subscribe((data: any) => {
        if (data.status == "success") {
         this.spinner.hide();
          window.sessionStorage.setItem("confirmedorderid", data.order_id.toString());
          this.router.navigate(['/bookingconfirmed'])
        }
        else {
          this.spinner.hide();
          this.errormsg = data.message;
        }
      })
    }
    else {

      let rescheduleDetail: rescheduleModel = {
        order_id: parseInt(window.sessionStorage.getItem("confirmedorderid")),
        appointment_date: this.dateselected.toString(),
        appointment_slot: this.selectedslot.slot
      }
      console.log(rescheduleDetail);
      this.apiservice.postreschduledorder(rescheduleDetail).subscribe((data: any) => {
        if (data.status == "success") {
         
        window.sessionStorage.removeItem("rescheduleflag");
          this.router.navigate(['/bookingconfirmed'])
        
        }
        else {
          this.errormsg = data.message;
        }
      })

    }
   
   
  }

  saveOrderDetailsPay() {
    console.log(this.chkaccept.value);
        if(this.chkaccept.value===false  || this.chkaccept.value===null)
        {
          this.errormsg="Please accept the terms and conditions";
          return;
        }
        localStorage.setItem("DiscountAmount",this.Discountamount.toString());
      localStorage.setItem("TaxAmount", this.Taxamount.toString());
      
        let coupon1: couponcodes = {
          coupon_code: ''
        }
        let addressdetails = JSON.parse(window.sessionStorage.getItem("addselected"));
        if(!this.couponapply || !this.validcouponcode)
        {
        if (this.couponcode.value != "" && this.couponcode.value != undefined) {
          this.validatecouponcode();
          if (this.couponcode.value != "" && this.couponapply) {
            window.sessionStorage.setItem("couponcode", this.couponcode.value);
            coupon1 = {
              coupon_code: this.couponcode.value
            }
          }
        }
      }
      let inwarranty=false;
      if(window.sessionStorage.getItem("warrantytype")==="IN")
      inwarranty=true;
        let reschedule = window.sessionStorage.getItem("rescheduleflag");
        if (reschedule === null || reschedule === "")
          reschedule = "false";
        if (reschedule === "false") {
    
          let orderdetail: OrderModel = {
            customer_id: parseInt(window.localStorage.getItem("CustomerID")),
            cust_name: this.getCustomerName(),
            housename_no: addressdetails.housename_no,
            street_name: addressdetails.street_name,
            area: addressdetails.area,
            landmark: addressdetails.landmark,
            city_id: addressdetails.city_id,
            state_id: addressdetails.state_id,
            pincode: addressdetails.pincode,
            tel_no: addressdetails.tel_no,
            email: addressdetails.email,
            appointment_date: this.dateselected.toString(),
            appointment_slot: this.selectedslot.slot,
            product_details: this.pdtdetail,
            addon_details: this.addons,
            product_type: this.pdttype,
            supporting_document: window.sessionStorage.getItem('filename'),
            coupon: coupon1,
            in_warranty:inwarranty,
            brand:window.sessionStorage.getItem('brand')
          }
          console.log(orderdetail);
            this.spinner.show();
            
          this.apiservice.postOrderDetails(orderdetail).subscribe((data: any) => {
            console.log(data);
            console.log(data.status);

            if (data.status == "success") {            
              window.sessionStorage.setItem("confirmedorderid", data.order_id.toString());
              this.encRequest.setValue(data.EncryptionRequest);
                this.access_code.setValue(data.AccessCode);         
                this.order_id.setValue(data.order_id);         
              this.payform.nativeElement.action=data.CheckoutUrl; // + "&encRequest="+ data.EncryptionRequest + "&access_code="+ data.AccessCode;
              console.log(this.encRequest.value);
              console.log(this.access_code.value);
              this.spinner.hide();
              this.payform.nativeElement.submit();
              //this.router.navigate(['/bookingconfirmed'])              
            }
            else {
              this.spinner.hide();
              this.errormsg = data.message;
            }
          })
        }
        else {
    
          let rescheduleDetail: rescheduleModel = {
            order_id: parseInt(window.sessionStorage.getItem("confirmedorderid")),
            appointment_date: this.dateselected.toString(),
            appointment_slot: this.selectedslot.slot
          }
          console.log(rescheduleDetail);
          this.apiservice.postreschduledorder(rescheduleDetail).subscribe((data: any) => {
            if (data.status == "success") {
             
            window.sessionStorage.removeItem("rescheduleflag");
              this.router.navigate(['/bookingconfirmed'])
            
            }
            else {
              this.errormsg = data.message;
            }
          })
    
        }
       
       
      }


  getCustomerName() {
    return (localStorage.getItem("CustomerName"));
    this.totalamt += this.Discountamount;
    this.Discountamount=0;
  }

  logout() {
    window.sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(["home"])
  }
 

  

  removeItem(pdtid: number) {
    let index = this.cart.items.findIndex(ob => ob.product_id === pdtid);
    if (index >= 0) {
      let qty = this.cart.items[index].quantity;
      let price = this.cart.items[index].price;
      if (qty > 1) {
        this.cart.items[index].quantity -= 1;
      }
      else {
        this.cart.items.splice(index, 1);
      }

      if (this.cart.items.length === 0) {
        window.sessionStorage.removeItem("cart");
        this.router.navigate(["services"]);
      }
      else {
        window.sessionStorage.setItem("cart", JSON.stringify(this.cart));
      }
      
    }
  }

  displaytotal()
  {
    this.product = this.cart.items;
    this.addon = this.cart.addonitems;
    this.calculateTotal();  
    return this.totalamt;
  }

  removeAddon(addonid: number) {
    let index = this.cart.addonitems.findIndex(ob => ob.addon_id === addonid);
    if (index >= 0) {
      this.cart.addonitems.splice(index, 1);
    }
  }

  clearCart() {
    this.cart.items=[];
    this.cart.addonitems=[];
   this.cart=null;
   window.sessionStorage.setItem("cart",JSON.stringify(this.cart));
    window.sessionStorage.removeItem("cart");
    window.sessionStorage.removeItem("warrantytype");
    window.sessionStorage.removeItem("selecteddate");
    window.sessionStorage.removeItem("selectedtime");
    window.sessionStorage.removeItem("addselected");
    window.sessionStorage.removeItem("confirmedorderid");
    window.sessionStorage.removeItem("rescheduleflag");
    localStorage.removeItem("step1");
    localStorage.removeItem("step2");
    localStorage.removeItem("step3");
    localStorage.removeItem("step4");
    localStorage.removeItem("step5");
   
    this.router.navigate(["home"]);
  }

  removecouponcode() {
    this.applied=true;

    this.couponcode.setValue('');
    this.totalamt +=this.Discountamount;
    this.Discountamount=0;
  }

  validatecouponcode() {
    //this.couponapply = false;
    //this.validcouponcode = false;
    this.applied = false;
    if (this.couponcode.value == undefined && this.couponcode.value != "") {
      this.couponcodevalidate=false;
    }
    else if (this.couponcode.value == "NEWUSER") {
      let custorder: customerorder = {
        customer_id: parseInt(localStorage.getItem("CustomerID")),
        orderid: null
      }
      this.apiservice.getOrderDetails(custorder).subscribe((data: any) => {
        let orders = data.orders;
        if (orders != null) {
          if (orders.length > 0) {
            this.couponapply = false;
            this.couponcode.setValue('');
          }
        }
        else {
          this.couponapply = true;
          this.Discountamount = 100;
        }
      });
    }
    else {
      let coupondetail = {
        "coupon_code": this.couponcode.value
      }

      this.apiservice.validatecoupon(coupondetail).subscribe((data: any) => {
        console.log(data);
        if (data.status === "success") {
          this.couponapply = true;
          this.validcouponcode = true;

          console.log(data.coupon_details.coupon_type);
          console.log(data.coupon_details.discount_value);

          console.log(this.Discountamount);
          console.log(this.totalamt);

          if (data.coupon_details.coupon_type === "value")
            this.Discountamount = data.coupon_details.discount_value;
          else {
            this.Discountamount = (this.totalamt * data.coupon_details.discount_value / 100);
          }

          console.log(this.couponapply);
          console.log(this.Discountamount);
          console.log(this.totalamt);

          if (this.couponapply) {
            console.log(this.Discountamount);
                console.log(this.totalamt);
            this.totalamt -= this.Discountamount;
          }

          console.log(this.totalamt);
        }
        else
        {
          this.validcouponcode=false;
        }
      });
    }
    //if (this.couponapply) {
    //  this.totalamt -= this.Discountamount;
    //}    
  }

  getServiceType()
  {
    if(window.sessionStorage.getItem("warrantytype")==="IN")
    return false;
    else
    return true;
  }
}
