<div class="DashboardInner">
    <div class="container">
       <div class="innercontainer">
     <!-------------------------------Header------------------>
     <header class="dashboardHeader">
       <div class="row dsRow no-gutters">
          <div class="col-lg-4 col-sm-4 dsgrid dsleftpanel">
             <p class="paraxl fontrobotoblack clrwhite">
                <a routerLink="/selectservicedate" routerLinkActive="active"><img src="assets/images/dashboard/back.png" class="img-fluid"/></a>
                <span class="d-none d-lg-block d-sm-block">Back</span>
                <span class="d-block d-lg-none d-sm-none">Confirm Booking</span>
             </p>
          </div>
          <div class="col-lg-8 col-sm-8 dsgrid dsrightpanel">
          <div class="dsheaderlist">
               <div class="mlist dropdown">
               <div class="dropdown-toggle" ngbDropdown #menu="ngbDropdown">
                  <p>{{getCustomerName()}}</p>

                  <button class="btn btn-outline-primary mr-2" id="dropdownManual" ngbDropdownAnchor
                     (focus)="menu.open()"> <img src="assets/images/home/login.svg"
                     class="img-fluid" />
                     <span>My Activity</span></button>
                  <div ngbDropdownMenu aria-labelledby="dropdownManual">
                     <button ngbDropdownItem><span><img src="assets/images/home/drp-icon-2.png"
                              class="img-fluid" /></span><a routerLink="/mydashboard"
                           routerLinkActive="active">My Booking</a></button>
                     <button ngbDropdownItem (click)="logout()"><span><img src="assets/images/home/drp-icon-3.png"
                              class="img-fluid" /></span>Logout</button>

                  </div>
               </div>
            </div>
            <div class="dslogo">
             <a class="nav-brand" routerLink="/home" routerLinkActive="active">
               <img src="assets/images/home/Bano_logo_white.svg" class="img-fluid" />
             </a>
         </div>
         </div>
          </div>
       </div>
     </header>
     <!---------------------------------steps-------------------->
     <section class="dsSteps">
      <div class="Stlist">
         <ul class="nav">
             <li class="st-active"><a routerLink="/services" routerLinkActive="active" [ngClass]="{'adisabled':!step1}">01</a>
                 <span>Select Services</span>
             </li>
             <div class="stline"></div>
             <li  class="st-active"><a routerLink="/addon" routerLinkActive="active" [ngClass]="{'adisabled':!step2}">02</a>
                 <span>Select Add-on</span>
             </li>
             <div class="stline"></div>
             <li  class="st-active"><a routerLink="/selectaddress" routerLinkActive="active" [ngClass]="{'adisabled':!step3}">03</a>
                 <span>Address Information</span>
             </li>
             <div class="stline"></div>
             <li class="st-active"><a routerLink="/selectservicedate" routerLinkActive="active" [ngClass]="{'adisabled':!step4}">04</a>
                 <span>Select Date / Time</span>
             </li>
             <div class="stline"></div>
             <li class="st-active"><a routerLink="/confirmbooking" routerLinkActive="active" [ngClass]="{'adisabled':!step5}">05</a>
                 <span>Confirmation</span>
             </li>
         </ul>
     </div>
        <!------------------Inner section--------->
        <div class="dsInnerpanel ">
        <div class="dsbx dsbookingpanel">
           <div class="dsInnerTitle">
              <a  routerLink="/selectservicedate" routerLinkActive="active"> 
                <img src="assets/images/dashboard/back-arrow.png" class="img-fluid"/> 
                Back</a>
              <p class="paraxl fontrobotobold clrblack d-lg-block d-none">Confirm Booking</p>
           </div>
           <div class="row no-gutters bookingrow">
              <div class="col-lg-7 bookinggrid bkleft">
                 <p class="paramedium fontrobotobold clrblack ">
                   A Plumber will be assigned <span style="color:#E66499;">one hour</span> before the scheduled time
                 </p>
               
                 <div class="bk-details border-top-bottom">
                  <p class="parasmall fontrobotobold">Plumber will arrive by <span class="clrpink">{{starttime}}</span>  on <span class="clrpink">{{selectday}}, {{datestring}}</span></p> 
                 </div>

                 <div class="nhpanel">
                    <p class="paralarge fontrobotobold">
                      Need Help
                    </p>
                    <div class="nhcontact">
                       <a href="tel:+1800 274 6060">
                          <img src="assets/images/dashboard/call-1.svg"/>
                          <span>1800 274 6060</span>
                       </a>
                       <a href="tel:+7301274274 ">
                         <img src="assets/images/dashboard/whatsapp.png" style="max-width:20px;"/>
                         <span>7301 274 274</span>
                      </a>
                    </div>
                   </div>

                    <p class="parasmall">Get in touch with us if you face any issue in our service</p>
              </div>
              <div class="col-lg-5 bookinggrid bkpayment">
                <p class="paramedium  text-center fontrobotobold clrblack bookinTitle">
                   Order Summary
                </p>
                <div class="costrow">
                   <div class="cs_inn" *ngFor="let objpdt of product">
                      <p>{{objpdt.category_name}} - {{objpdt.product_name}}</p>
                      <a class="deletecart" (click)="removeItem(objpdt.product_id)">
                        <img src="assets/images/home/delete.png"/>
                      </a>
                      <p class="priceamt"><span>&#8377;</span> {{objpdt.price * objpdt.quantity}}

                     </p>
                     
                   </div>
                   <div class="cs_inn" *ngFor="let objaddon of addon">
                      <p>{{objaddon.addon_name}}</p>
                      <a class="deletecart" (click)="removeAddon(objaddon.addon_id)">
                        <img src="assets/images/home/delete.png"/>
                      </a>
                      <p  class="priceamt">
                         <span>&#8377;</span> {{objaddon.price}}
                     </p>
                    
                   </div>
                </div>
                

                   <div class="costrow" *ngIf="Taxamount>0">
                    <div class="cs_inn" >
                       <p>Tax Amount (18%)</p>
                       <p  class="priceamt">
                          <span>&#8377;</span> {{Taxamount}}
                      </p>
                    </div>
                     </div>
                     <div class="costrow" *ngIf="Discountamount>0">
                        <div class="cs_inn" >
                           <p>Discount</p>
                           <p  class="priceamt">
                              <span>&#8377;</span> {{Discountamount}}
                          </p>
                        </div>
                         </div>
                <div class="total totalconfirmbk">
                   <div class="cs_inn">
                      <p class="paramedium fontrobotobold clrblack">Total</p>
                      <p class="paramedium priceamt fontrobotobold clrblack"><span>&#8377;</span>{{displaytotal() }}</p>
                   </div>
                </div>

                <div class="couponcode mb30 form-group uploadpanel" *ngIf="getServiceType()">
                  <input type="text" placeholder="Enter Coupon Code" [formControl]="couponcode"/>
                  <span *ngIf="applied"><a (click)="validatecouponcode()" style="cursor: pointer;">Apply</a></span>
                  <span *ngIf="!applied"><a (click)="removecouponcode()" style="cursor: pointer;">Remove</a></span>
                  <div *ngIf="!couponapply"><b style="color :red;">You are not first time user.</b></div>
                  <div *ngIf="!validcouponcode"><b style="color :red;">Invalid Coupon Code</b></div>
                  <div *ngIf="!couponcodevalidate"><b style="color :red;">Please enter the Coupon Code</b></div>
                 </div>
             
         
         
                <div class="gonextpanel nomargin">
                  <ngx-spinner
                  bdColor="rgba(51,51,51,0.8)"
                  size="medium"
                  color="#fff"
                  type="ball-scale-multiple"
                >
                  <p style="font-size: 20px; color: white">Updating the Details....</p>
                </ngx-spinner>
                   <span class="mb15 d-block fontrobotobold text-uppercase clrpink">Cash on delivery</span>
                   <div class="form-group">
                      <label class="confirmbx">
                         <input type="checkbox" [formControl]="chkaccept" (click)="acceptclick($event)">
                         <span>I agree to the  <a routerLink="/terms" routerLinkActive="active" (click)="gotopage()">Terms and conditions</a> and <a routerLink="/privacypolicy" routerLinkActive="active" (click)="gotopage()">Privacy policy</a></span>
                      </label>
                   </div>
                   <div class="d-flex btnoptions mb15">
                   <a  (click)="saveOrderDetails()" class="mb15 gonextbtn btn booknow popbtn fullwidth">Confirm Booking
                   </a >
                   <!-- <a  (click)="saveOrderDetailsPay()" class="mb15 gonextbtn btn booknow popbtn fullwidth">Confirm & Pay
                  </a > -->
                   <a   class="gonextbtn btn booknow popbtn fullwidth cartclear" (click)="clearCart()">Clear Cart
                  </a >
               </div>
                </div>
                <b style="color :red;" *ngIf="errormsg.length>0">{{errormsg}}</b>
              </div> 
           </div>


        </div>
       </div>
       <form name="paymentForm"  method="POST" enctype="application/x-www-form-urlencoded" #paymentForm ngNoForm>
         <input type="hidden" [formControl]="encRequest" id="encRequest" name="encRequest"/>
         <input type="hidden" [formControl]="access_code"  id="access_code" name="access_code" />
         <input type="hidden" [formControl]="order_id"  id="order_id" name="order_id" />
        </form>
     </section>
    </div>
     </div>
    </div>
    <!-------------------------------Footer Panel------------------>
    <section class="Footerpanel">
       <div class="footerTop">
          <div class="container">
             <div class="fTopInner text-center">
                <a routerLink="/home" routerLinkActive="active"><img src="assets/images/home/logo.png" class="img-fluid"/></a>
                <p class="para">
                   Parryware India is a leading manufacturer of bathroom products originated from EID Parry, one of the country's oldest and most reputed business entities. In 2006, it entered into a joint venture with Roca Corporation, Spain, to become a Roca Group subsidiary in 2008, and turned into a wholly-owned subsidiary of the Roca Group in 2011.
                </p>
             </div>
          </div>
       </div>
       <div class="footerBtm bggrey">
          <div class="container">
             <div class="row fbtmRow no-gutters">
                <div class="col-lg-4  fbtcopyrights">
                   <p class="parasmall">© 2021 Parryware | All Rights Reserved</p>
                </div>
                <div class="col-lg-8  fbtmenus">
                  <ul class="nav menu_footer">
                     <li><a routerLink="/aboutus" routerLinkActive="active" (click)="gotopage()">About us</a></li>
                     <li class="line"></li>
                     <li><a routerLink="/contact" routerLinkActive="active" (click)="gotopage()">Contact us</a></li>
                     <li class="line"></li>
                     <li><a pageScroll routerLink="/home" href="#faq" (click)="gotopage()">FAQ</a></li>
                     <li class="line"></li>
                     <li><a routerLink="/warrantypolicy" routerLinkActive="active"  (click)="gotopage()">Warranty Policy</a></li>
                     <li class="line"></li>
                     <li><a routerLink="/privacypolicy" routerLinkActive="active" (click)="gotopage()">Privacy Policy</a></li>
                     <li class="line"></li>
                     <li><a routerLink="/terms" routerLinkActive="active" (click)="gotopage()">Terms of Use</a></li>
                 </ul>
              </div>
             </div>
          </div>
       </div>
    </section>
