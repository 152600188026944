import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import * as $ from "jquery";
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  // CheckoutUrl: any;
  // EncryptionRequest: any;
  // AccessCode: any;
    @ViewChild('paymentForm') payform: any;
    order_id = new FormControl();
    tracking_id=new FormControl();
    bank_ref_no = new FormControl();
    order_status = new FormControl();
    currency=new FormControl();
    amount = new FormControl();
    
  constructor(private route: ActivatedRoute,private router: Router,) { }
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      console.log(params.order_id);
      console.log(params.tracking_id);
        console.log(params.bank_ref_no);
        console.log(params.order_status);
        console.log(params.currency);
        console.log(params.amount);
        
        this.order_id.setValue(params.order_id);
        this.tracking_id.setValue(params.tracking_id);     
        this.bank_ref_no.setValue(params.bank_ref_no);   
        this.order_status.setValue(params.order_status);   
        this.currency.setValue(params.currency);   
        this.amount.setValue(params.amount);   

        //this.payform.nativeElement.action="http://localhost:4200/successpayment"; // + "&encRequest="+ data.EncryptionRequest + "&access_code="+ data.AccessCode;
        console.log(this.order_id.value);
        console.log(this.tracking_id.value);
        console.log(this.bank_ref_no.value);
        console.log(this.order_status.value);
        console.log(this.currency.value);
        console.log(this.amount.value);

        //this.payform.nativeElement.submit();

        //this.router.navigate(['/successpayment'], { state: "result" })   
        	
        if (this.order_status.value == "Success") {
            this.router.navigateByUrl('/successpayment', {
                state: {
                    order_id: this.order_id.value, tracking_id: this.tracking_id.value, amount: this.amount.value,
                    bank_ref_no: this.bank_ref_no.value, order_status: this.order_status.value, currency: this.currency.value
                }
            });
        }
        else if (this.order_status.value == "Aborted") {
            this.router.navigateByUrl('/cancelledpayment', {
                state: {
                    order_id: this.order_id.value, tracking_id: this.tracking_id.value, amount: this.amount.value,
                    bank_ref_no: this.bank_ref_no.value, order_status: this.order_status.value, currency: this.currency.value
                }
            });
        }
        else
        {
            this.router.navigateByUrl('/failurepayment', {
                state: {
                    order_id: this.order_id.value, tracking_id: this.tracking_id.value, amount: this.amount.value,
                    bank_ref_no: this.bank_ref_no.value, order_status: this.order_status.value, currency: this.currency.value
                }
            });
            }
    });
  }

}
