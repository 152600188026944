<!-------------------------------Header------------------>
<div class="DashboardInner" data-ng-init="checkloggedin()">
    <div class="container">
      <header class="dashboardHeader">
        <div class="row dsRow no-gutters">
          <div class="col-lg-4 col-sm-4 dsgrid dsleftpanel">
            <p class="paraxl fontrobotoblack clrwhite">
              <a routerLink="/home" routerLinkActive="active"
                ><img src="assets/images/dashboard/back.png" class="img-fluid" />
                <span>Back</span></a
              >
            </p>
          </div>
          <div class="col-lg-8 col-sm-8 dsgrid dsrightpanel">
            <div class="dsheaderlist">
              <div class="mlist dropdown">
                <div class="dropdown-toggle" ngbDropdown #menu="ngbDropdown">
                  <p>Login/Sign up</p>
                  <a class="loginClass">
                    <img src="assets/images/home/login.svg" class="img-fluid" />
                    <span>My Activity</span>
                  </a>
                  <button
                    class="btn btn-outline-primary mr-2"
                    id="dropdownManual"
                    ngbDropdownAnchor
                    (focus)="menu.open()"
                    *ngIf="loggedin"
                  >
                    <p *ngIf="loggedin">Welcome {{ getCustomerName() }}</p>
                    <img src="assets/images/home/login.svg" class="img-fluid" />
  
                    <span>My Activity</span>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownManual">
                    <button ngbDropdownItem>
                      <span
                        ><img
                          src="assets/images/home/drp-icon-2.png"
                          class="img-fluid" /></span
                      ><a routerLink="/mydashboard" routerLinkActive="active"
                        >My Booking</a
                      >
                    </button>
                    <button ngbDropdownItem (click)="logout()">
                      <span
                        ><img
                          src="assets/images/home/drp-icon-3.png"
                          class="img-fluid" /></span
                      >Logout
                    </button>
                  </div>
                </div>
  
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#"></a>
                  <a class="dropdown-item" href="#"></a>
                </div>
              </div>
              <div class="dslogo">
                <a class="nav-brand" routerLink="/home" routerLinkActive="active">
                  <img
                    src="assets/images/home/Bano_logo_white.svg"
                    class="img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  </div>
  <!-------------------------------Banner------------------>
  <section class="homebannerpanel ContactPanel position-relative">
    <div class="bgs renovationbg"></div>
    <div class="renovationbgmobile"></div>
  </section>
  <!-------------------Banner Text----------->
  <div class="banText remove-pos">
    <div class="container">
      <div class="banTxtInner">
        <h1 class="h2title fontrobotoblack clrlack">Renovation</h1>
      </div>
      <div>
        <ul class="breadcrumb-theme">
          <li><a routerLink="/home" routerLinkActive="active">Home </a></li>
          <li>/</li>
          <li><a> Renovations</a></li>
        </ul>
      </div>
    </div>
  </div>
  <!-----------------------------------Contact form-------------->
  <section class="contactFormPanel">
    <div class="container">
      <div class="row no-gutters cfp_row">
        <div class="col-lg-6 cfp_grid cfp_info">
          <p class="paramedium mb15">
            Renovating your bathroom can’t get any easier. With our bathroom
            experts and pro designers, you are just 3 steps away from the bathroom
            of your dreams!
          </p>
          <p class="paramedium mb-5">
            You also get a one- year cleaning kit complementary, another reason to
            grab this steal deal!
          </p>
  <!----------------------------Process Steps-------------->
  <section class="hwpanel renovation-process" id="process-step-sec">
      <ngx-slick-carousel
      class="howitworksCarousel processcarousel slickcarouelstyle carousel"
      #slickModal="slick-carousel"
      [config]="hiwslideConfig"
    >
      <div ngxSlickItem *ngFor="let hiwslide of hiwslides" class="slide">
        <div>
          <h2 class="h2subtitle fontrobotobold clrwhite mb30">
            <span>{{ hiwslide.count }}</span>
            {{ hiwslide.maintext }}
          </h2>
        </div>
      </div>
    </ngx-slick-carousel>
  </section>
          <h3 class="clrblue mb30 fontrobotobold">What it includes</h3>
          <p class="paramedium mb15 fontrobotobold">Site visit</p>
          <p class="paramedium mb30">
            Our Expert team will be visiting your place to get an idea of your
            expectations and see what can be done best.
          </p>
          <p class="paramedium mb15 fontrobotobold">Design</p>
          <p class="paramedium mb15">
            Our experts will help you with the design process, product selection,
            and everything else needed to put together the best output.
          </p>
          <p class="paramedium mb15 fontrobotobold">Execution</p>
          <p class="paramedium mb15">
            Now that you are all set, we will take over and give you the best
            possible Bathroom make-over experience. Trust us when we say your
            bathroom will look like new! You also get a one- year cleaning kit
            complementary, another reason to grab this steal deal!
          </p>
        </div>
        <div class="col-lg-6 cfp_grid cfp_form">
          <div class="process-right-text">
            <p class="paramedium  mb15">
              We have simplified the process for you, and now with our Bathroom
              experts and Pro designers, you are just 4 steps away from the
              bathroom of your dreams!
            </p>
            <p class="paramedium  mb15">
              Payment will be split into three parts where 50% is the advance, 25%
              to be paid while work is half done, and the rest 25% on completion.
            </p>
            <p class="paramedium  mb15">
              Rs. 2000 will be charged for designing if you choose to execute the
              renovation yourself. If you choose to execute the design with us,
              designing charges would be waived off!
            </p>
          </div>
          <div class="valueformsec">
                   
            <div class="valu-form">
             <h3 class="paraxl clrblue mb10 fontrobotobold">
                You have successfully scheduled Free consultation!
             </h3>
             <p class="mb20 paramedium clrblue fontrobotobold">
                Please download the Product details below while our experts reach out to you.
             </p>
             <a href="assets/pdf/new-renovation.pdf" download class="download-btn" target="_blank">Download Pdf</a>
            </div>
         </div>
        </div>
      </div>
    </div>
  </section>
  
  
  
  <!-------------------------------Footer Panel------------------>
  <section class="Footerpanel">
    <div class="footerTop">
      <div class="container">
        <div class="fTopInner text-center">
          <a routerLink="/home" routerLinkActive="active"
            ><img src="assets/images/home/logo.png" class="img-fluid"
          /></a>
          <p class="para">
            Parryware India is a leading manufacturer of bathroom products
            originated from EID Parry, one of the country's oldest and most
            reputed business entities. In 2006, it entered into a joint venture
            with Roca Corporation, Spain, to become a Roca Group subsidiary in
            2008, and turned into a wholly-owned subsidiary of the Roca Group in
            2011.
          </p>
        </div>
      </div>
    </div>
    <div class="footerBtm">
      <div class="container">
        <div class="row fbtmRow no-gutters">
          <div class="col-lg-8 fbtmenus">
            <ul class="nav menu_footer">
              <li>
                <a
                  routerLink="/aboutus"
                  routerLinkActive="active"
                  (click)="gotopage()"
                  >About us</a
                >
              </li>
              <li class="line"></li>
              <li>
                <a
                  routerLink="/contact"
                  routerLinkActive="active"
                  (click)="gotopage()"
                  >Contact us</a
                >
              </li>
              <li class="line"></li>
              <li><a pageScroll href="#faq" (click)="gotopage()">FAQ</a></li>
              <li class="line"></li>
              <li>
                <a
                  routerLink="/warrantypolicy"
                  routerLinkActive="active"
                  (click)="gotopage()"
                  >Warranty Policy</a
                >
              </li>
              <li class="line"></li>
              <li>
                <a
                  routerLink="/privacypolicy"
                  routerLinkActive="active"
                  (click)="gotopage()"
                  >Privacy Policy</a
                >
              </li>
              <li class="line"></li>
              <li>
                <a
                  routerLink="/terms"
                  routerLinkActive="active"
                  (click)="gotopage()"
                  >Terms of Use</a
                >
              </li>
            </ul>
          </div>
          <div class="col-lg-4 social-grid">
            <p class="parasmall">Follow us on:</p>
            <ul class="social-menu">
              <li>
                <a href="https://www.facebook.com/parrywarebano/" target="_blank"
                  ><img src="assets/images/facebook.svg" class="img-fluid"
                /></a>
              </li>
              <li>
                <a><img src="assets/images/instagram.svg" class="img-fluid" /></a>
              </li>
            </ul>
          </div>
        </div>
        <!--coprights-->
        <div class="copyright">
          <p class="parasmall clrwhite text-center">
            © 2021 Parryware | All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  </section>
  