import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http'
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common'
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxCaptchaModule } from 'ngx-captcha';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { AddonComponent } from './addon/addon.component';
import { SelectaddressComponent } from './selectaddress/selectaddress.component';
import { SelectservicedateComponent } from './selectservicedate/selectservicedate.component';
import { ConfirmbookingComponent } from './confirmbooking/confirmbooking.component';
import { BookingconfirmedComponent } from './bookingconfirmed/bookingconfirmed.component';
import { MydashboardComponent } from './mydashboard/mydashboard.component';
import { ServicecompletedComponent } from './servicecompleted/servicecompleted.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { CancelBookingComponent } from './cancel-booking/cancel-booking.component';
import { TermsComponent } from './terms/terms.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactComponent } from './contact/contact.component';
import { MoveNextByMaxLengthDirective } from './move-next-by-max-length.directive';
import { WarrantypolicyComponent } from './warrantypolicy/warrantypolicy.component';

import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaymentComponent } from './payment/payment.component';
import { CombodealsComponent } from './combodeals/combodeals.component';
import { WedesignyouexecuteComponent } from './wedesignyouexecute/wedesignyouexecute.component';
import { AmcComponent } from './amc/amc.component';
import { DeepcleaningserviceComponent } from './deepcleaningservice/deepcleaningservice.component';
import { RenovationComponent } from './renovation/renovation.component';
import { SuccesspaymentComponent } from './successpayment/successpayment.component';
import { FailurepaymentComponent } from './failurepayment/failurepayment.component';
import { CancelledpaymentComponent } from './cancelledpayment/cancelledpayment.component';
import { CombothankyouComponent } from './combothankyou/combothankyou.component';
import { RenovationthankyouComponent } from './renovationthankyou/renovationthankyou.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ServicesComponent,
    AddonComponent,
    SelectaddressComponent,
    SelectservicedateComponent,
    ConfirmbookingComponent,
    BookingconfirmedComponent,
    MydashboardComponent,
    ServicecompletedComponent,
    PrivacypolicyComponent,
    CancelBookingComponent,
    TermsComponent,
    AboutusComponent,
    ContactComponent,
    MoveNextByMaxLengthDirective,
    WarrantypolicyComponent,
        PaymentComponent,
    CombodealsComponent,
    WedesignyouexecuteComponent,
    AmcComponent,
    DeepcleaningserviceComponent,
    RenovationComponent,
    SuccesspaymentComponent,
    FailurepaymentComponent,
    CancelledpaymentComponent,
    CombothankyouComponent,
    RenovationthankyouComponent  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,NgbModule,
    HttpClientModule,FormsModule,
    ReactiveFormsModule, NgbDatepickerModule,NgxPageScrollCoreModule,NgxPageScrollModule,
    NgxCaptchaModule,NgxSpinnerModule,BrowserAnimationsModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
