<div class="DashboardInner">
    <div class="container">
       <div class="innercontainer">
     <!-------------------------------Header------------------>
     <header class="dashboardHeader">
       <div class="row dsRow no-gutters">
          <div class="col-lg-4 col-sm-4 dsgrid dsleftpanel">
             <p class="paraxl fontrobotoblack clrwhite">
                <a routerLink="/services" routerLinkActive="active"><img src="assets/images/dashboard/back.png" class="img-fluid"/>
                <span class="d-none d-lg-block d-sm-block">Book Your Service</span></a>
                <span class="d-block d-lg-none d-sm-none">Order Cancelled</span>
             </p>
          </div>
          <div class="col-lg-8 col-sm-8 dsgrid dsrightpanel">
          <div class="dsheaderlist">
            <div class="mlist dropdown">
               <div class="dropdown-toggle" ngbDropdown #menu="ngbDropdown">
                  <p>{{getCustomerName()}}</p>

                  <button class="btn btn-outline-primary mr-2" id="dropdownManual" ngbDropdownAnchor
                     (focus)="menu.open()"> <img src="assets/images/home/login.svg"
                     class="img-fluid" />
                     <span>My Activity</span></button>
                  <div ngbDropdownMenu aria-labelledby="dropdownManual">
                     <button ngbDropdownItem><span><img src="assets/images/home/drp-icon-2.png"
                              class="img-fluid" /></span><a routerLink="/mydashboard"
                           routerLinkActive="active">My Booking</a></button>
                     <button ngbDropdownItem (click)="logout()"><span><img src="assets/images/home/drp-icon-3.png"
                              class="img-fluid" /></span>Logout</button>

                  </div>
               </div>
            </div>
            <div class="dslogo">
             <a class="nav-brand" href="home.html">
               <img src="assets/images/home/Bano_logo_white.svg" class="img-fluid" />
             </a>
         </div>
         </div>
          </div>
       </div>
     </header>
     <section class="dsInnerpanel">
        <!------------------Inner section--------->
        <div class="dsbx">
          
          <div class=" orderpanel">
             <img src="assets/images/dashboard/cancelled.svg" class="img-fluid mb30"/>
             <p class="paraxl fontrobotobold clrblack mb15">
                Your order has been cancelled
             </p>
             <!-- <p class="parasmall clrblack mb50">
                Your money will be credited to your account in 7 business days
             </p> -->
             <div class="gonextpanel nomargin">
                <button class="gonextbtn btn booknow popbtn fullwidth" (click)="NavigateToServices()">Book a service
                </button>
             </div>
          </div>
        </div>
     </section>
    </div>
     </div>
    </div>
    <!-------------------------------Footer Panel------------------>
    <section class="Footerpanel">
       <div class="footerTop">
          <div class="container">
             <div class="fTopInner text-center">
                <a routerLink="/home" routerLinkActive="active"><img src="assets/images/home/logo.png" class="img-fluid"/></a>
                <p class="para">
                   Parryware India is a leading manufacturer of bathroom products originated from EID Parry, one of the country's oldest and most reputed business entities. In 2006, it entered into a joint venture with Roca Corporation, Spain, to become a Roca Group subsidiary in 2008, and turned into a wholly-owned subsidiary of the Roca Group in 2011.
                </p>
             </div>
          </div>
       </div>
       <div class="footerBtm bggrey">
          <div class="container">
             <div class="row fbtmRow no-gutters">
                <div class="col-lg-6  fbtcopyrights">
                   <p class="parasmall">© 2021 Parryware | All Rights Reserved</p>
                </div>
                <div class="col-lg-6  fbtmenus">
                  <ul class="nav menu_footer">
                     <li><a routerLink="/aboutus" routerLinkActive="active" (click)="gotopage()">About us</a></li>
                     <li class="line"></li>
                     <li><a routerLink="/contact" routerLinkActive="active" (click)="gotopage()">Contact us</a></li>
                     <li class="line"></li>
                     <li><a pageScroll routerLink="/home" href="#faq" (click)="gotopage()">FAQ</a></li>
                     <li class="line"></li>
                     <li><a routerLink="/warrantypolicy" routerLinkActive="active"  (click)="gotopage()">Warranty Policy</a></li>
                     <li class="line"></li>
                     <li><a routerLink="/privacypolicy" routerLinkActive="active" (click)="gotopage()">Privacy Policy</a></li>
                     <li class="line"></li>
                     <li><a routerLink="/terms" routerLinkActive="active" (click)="gotopage()">Terms of Use</a></li>
                 </ul>
                </div>
             </div>
          </div>
       </div>
    </section>
